import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linkconverted' })
export class LinkConverted implements PipeTransform {
  transform(link: string) {
    let new_link = '';
    if (link !== undefined && link !== '' && link !== '-' && link !== null) {
      new_link = `https://open.spotify.com/embed/album/${
        link.split('/album/')[1]
      }`;
      if (!link.split('/album/')[1]) {
        new_link = `https://open.spotify.com/embed/track/${
          link.split('/track/')[1]
        }`;
      }
      return new_link;
    }
    return 'not found';
  }
}
