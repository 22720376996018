import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Rx';
import { concatMap } from 'rxjs/operators';

import { from } from 'rxjs';
import { VAR_CONFIG } from '../services/vars';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  selectedUser: User;

  users: User[];

  readonly URL_API = VAR_CONFIG.url_api_user;

  constructor(private http?: HttpClient) {
    // do nothing.
  }

  getUsers() {
    return this.http.get(this.URL_API);
  }

  getByName(name: string) {
    return this.http.post(`${this.URL_API}/get/by/name`, { name })
      .map((res) => res);
  }

  searchFavorite(sub: string) {
    return this.http.post(`${this.URL_API}/get/fav`, { sub })
      .map((res) => res);
  }

  ifExist(sub: string) {
    return this.http.post(`${this.URL_API}/exist`, { sub })
      .map((res) => res);
  }

  infoUser(nickname: string, picture: string) {
    return this.http.post(`${this.URL_API}/get/v1/info`, { nickname, picture })
      .map((res) => res);
  }

  infoUser2(sub: string) {
    return this.http.post(`${this.URL_API}/get/v2/info`, { sub })
      .map((res) => res);
  }

  infoUserByNick(nickname: string, type: string) {
    return this.http.post(`${this.URL_API}/get/v3/info`, { nickname, type })
      .map((res) => res);
  }

  updQualifi(sub: string, option: string, id_song: string) {
    return this.http.post(`${this.URL_API}/upd/qualifi`, { sub, option, id_song })
      .map((res) => res);
  }

  showQualifi(id: string) {
    return this.http.post(`${this.URL_API}/get/qualifi`, { id })
      .map((res) => res);
  }

  infoUserById(id: string) {
    return this.http.post(`${this.URL_API}/get/v4/info`, { id })
      .map((res) => res);
  }

  postUser(user: User) {
    return this.http.post(this.URL_API, user);
  }

  putUser(user: User) {
    return this.http.put(`${this.URL_API}/${user._id}`, user);
  }

  getUsersFromAnAPI(ids: any[]): any {
    return from(ids).pipe(
      concatMap((sub) => <Observable<any>> this.http.post(`${this.URL_API}/exist`, { sub })),
    );
  }
}
