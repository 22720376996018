import { Pipe, PipeTransform } from '@angular/core';
// import * as moment from 'moment';

@Pipe({
  name: 'datetime',
})
export class DateTimePipe implements PipeTransform {
  transform(value: Date, dateFormat: string): any {
    if (dateFormat !== 'dt') {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      const datetime = new Intl.DateTimeFormat(
        Intl.DateTimeFormat().resolvedOptions().locale,
        options,
      ).format(new Date(value));
      return datetime;
    }
    const dt = new Date(value);
    const yyyymmdd = new Date(value).toLocaleDateString('fr-CA');
    const dt_h = `0${dt.getHours().toString()}`;
    const dt_m = `0${dt.getMinutes().toString()}`;
    const dt_s = `0${dt.getSeconds().toString()}`;
    // const full_dt = `${dt.toISOString().slice(0, 10)
    const full_dt = `${yyyymmdd.slice(0, 10)
    } ${
      dt_h.slice(-2)
    }:${
      dt_m.slice(-2)
    }:${
      dt_s.slice(-2)}`;
    return full_dt;
  }
}
