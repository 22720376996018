import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MusicService } from '../../services/music.service';
import { AuthService } from '../../services/auth/auth.service';
import { SpotifyService } from '../../services/spotify.service';
import { MarkerService } from '../../services/marker.service';
import { UserService } from '../../services/user.service';
import { LANGUAGES } from '../../../assets/languages';
import { VAR_CONFIG } from '../../services/vars';

declare let $: any;
declare const google: any;

@Component({
  selector: 'app-profilemusic',
  templateUrl: './profilemusic.component.html',
  styleUrls: ['./profilemusic.component.css'],
  providers: [MarkerService],
})
export class ProfilemusicComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  state_form: boolean = true;

  music: any;

  text_lyric: string;

  text_justification: string;

  other_places: any;

  id_link_spotify: string;

  img_music: string;

  pageId: string;

  @ViewChild('search_m') public searchElement_m: ElementRef;

  lat: number = Number(-39.8142204);

  lng: number = Number(-73.2458878);

  zoom: number = 9;

  private geoCoder;

  address: string;

  click_lat: number = 0;

  click_long: number = 0;

  map_: any;

  id: string;

  profile: any;

  currentuser: any;

  lat_country: number;

  lng_country: number;

  name_country: string;

  embed_link_yt: string = '';

  embed_link: string = '';

  show_embed: boolean = false;

  lang_disqus: string = localStorage.getItem('lang') || 'es';

  all_language: any = LANGUAGES.all_language;

  dropdownList_lang = [];

  selectedItems_lang = [];

  dropdownSettings_lang = {};

  lang_actual: any = [];

  lang_modific: any = [];

  trans23: any;

  trans03: any;

  trans04: any;

  trans05: any;

  trans07: any;

  trans25: any;

  trans26: any;

  trans27: any;

  nickname_user: any;

  prevJustification: any;

  issuperuser: any;

  currentreason: any;

  currentplace: any;

  currentcountry: any;

  stateNoti: boolean = false;

  contributions: any[];

  res: any;

  num_contributions: any;

  dateCurrentJusti: any;

  dropdownList_reason = [];

  selectedItems_reason = [];

  dropdownSettings = {};

  existQualification: boolean = false;

  contOptions: any = [
    { _0: 0 },
    { _1: 0 },
    { _2: 0 },
    { _3: 0 },
    { _4: 0 },
    { _5: 0 },
    { _6: 0 },
  ];

  sumResponses: number = 0;

  constructor(
    private musicService: MusicService,
    private auth: AuthService,
    private spotifyService: SpotifyService,
    private markerService: MarkerService,
    private zone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private user: UserService,
    private http: HttpClient,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.lang_disqus = localStorage.getItem('lang') || 'es';
  }

  esLang() {
    if (this.translate.currentLang === 'es') {
      this.translate.get('Español es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'es';
      localStorage.setItem('lang', 'es');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      );
      this.translate.use('es');
      this.translateTitles();
      $('#esL').css('font-weight', 'bold');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
      this.zone.runOutsideAngular(() => {
        location.reload();
      });
    }
  }

  ptLang() {
    if (this.translate.currentLang === 'pt') {
      this.translate.get('Portugués es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'pt';
      localStorage.setItem('lang', 'pt');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Portuguese-Brasil.json',
      );
      this.translate.use('pt');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', 'bold');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
      this.zone.runOutsideAngular(() => {
        location.reload();
      });
    }
  }

  enLang() {
    if (this.translate.currentLang === 'en') {
      this.translate.get('Inglés es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'en';
      localStorage.setItem('lang', 'en');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/English.json',
      );
      this.translate.use('en');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', 'bold');
      this.translate.get('Traducción aplicada').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
      this.zone.runOutsideAngular(() => {
        location.reload();
      });
    }
  }

  openForm() {
    if (this.state_form) {
      $('#bodyupdform').css('display', 'block');
      this.state_form = false;
      this.changeDetectorRef.detectChanges();
      $('#collapheadform').removeClass(() => 'active');
      $('#collapotherplaces').collapsible({ accordion: false });
      $('#collapform').collapsible({ accordion: true });
      $('.collapsible').collapsible({ accordion: false });
    } else {
      $('#bodyupdform').css('display', 'none');
      this.state_form = true;
    }
  }

  saveUser() {
    let data_new_user = [];
    data_new_user = [
      {
        sub: this.profile.sub,
        given_name: this.profile.given_name,
        family_name: this.profile.family_name,
        nickname: this.profile.nickname,
        name: this.profile.name,
        picture: this.profile.picture,
        contributions: { data: '' },
        notifications: [],
        favorite: [],
        date: new Date().toString(),
        qualification: [],
      },
    ];

    this.user.ifExist(this.profile.sub).subscribe((res) => {
      const r: any = res;
      $('select').material_select();
      if (r === null || r.length === 0) {
        this.user.postUser(data_new_user[0]).subscribe((res) => {
          $('.favorite_song').css('background', 'black');
          if (this.profile) {
            this.user.searchFavorite(this.profile.sub).subscribe((res) => {
              const fav: any = res;
              let cont = 0;
              for (let index = 0; index < fav.length; index++) {
                const element = fav[index].id;
                if (element === this.music._id) {
                  cont++;
                }
              }
              if (cont !== 0) {
                $('.favorite_song').css('background', 'red');
              } else {
                $('.favorite_song').css('background', 'black');
              }
            });
          }
        });
      } else if (this.profile) {
        this.user.searchFavorite(this.profile.sub).subscribe((res) => {
          this.translateTitles();
          const fav: any = res;
          let cont = 0;
          for (let index = 0; index < fav.length; index++) {
            const element = fav[index].id;
            if (element === this.music._id) {
              cont++;
            }
          }
          if (cont !== 0) {
            $('.favorite_song').css('background', 'red');
          } else {
            $('.favorite_song').css('background', 'black');
          }
        });
      }
    });
  }

  loadJquery() {
    if (this.profile !== undefined) {
      $('.favorite_song').css('background', 'black');
    }
    $('.button-collapse').sideNav({ edge: 'left' });
    $('.modal').modal({ opacity: 0.8 });
    $('#modal_youtube').modal({
      opacity: 0.9,
    });
    $('#moda_player_window_spot').modal({
      opacity: 0.9,
    });
    $('#moda_player_window_soundcloud').modal({
      opacity: 0.9,
    });
    $('.collapsible').collapsible();
    $('.modal').scrollTop(0);
    $('.tooltipped').tooltip();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  activecCollap() {
    $('.collapsible').collapsible();
  }

  ngOnInit() {
    $('#modal_complete_data').modal('close');
    this.http.get(VAR_CONFIG.url_t_s).subscribe(async (data) => {
      const var_body: any = data;
      await localStorage.setItem('token_s', var_body.body.access_token);
    });
    this.openForm();
    localStorage.removeItem('musicMarker');
    setTimeout(() => {
      this.loadJquery();
      if (localStorage.getItem('lang') === 'es') {
        this.lang_disqus = 'es';
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      }
      if (localStorage.getItem('lang') === 'en') {
        this.lang_disqus = 'en';
        this.translate.use('en');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', 'bold');
      }
      if (localStorage.getItem('lang') === 'pt') {
        this.lang_disqus = 'pt';
        this.translate.use('pt');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', 'bold');
        $('#engL').css('font-weight', '200');
      }
      if (
        localStorage.getItem('lang') === undefined
        || localStorage.getItem('lang') === null
      ) {
        this.lang_disqus = 'es';
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      }
      this.openForm();
    }, 1500);

    setTimeout(() => {
      this.issuperuser = this.auth.superuser;
      if (this.profile !== undefined) {
        this.user.ifExist(this.profile.sub).subscribe((res) => {
          const res_: any = res;
          this.currentuser = res_;
          if (this.currentuser.qualification.length > 0) {
            for (
              let index = 0;
              index < this.currentuser.qualification.length;
              index++
            ) {
              const element = this.currentuser.qualification[index].id_song;
              if (element === this.music._id) {
                this.existQualification = true;
                $('#qualifi').val(this.currentuser.qualification[index].option);
                $('select').material_select();
              } else {
                $('#qualifi').val('0');
                $('select').material_select();
              }
            }
          }
        });
        this.issuperuser = this.auth.superuser;
        this.saveUser();

        this.mapsAPILoader.load().then(() => {
          this.geoCoder = new google.maps.Geocoder();
          const autocomplete = new google.maps.places.Autocomplete(
            this.searchElement_m.nativeElement,
            { types: [] },
          );

          autocomplete.addListener('place_changed', () => {
            this.zone.run(() => {
              const place: google.maps.places.PlaceResult = autocomplete.getPlace();
              if (place.geometry === undefined || place.geometry === null) {

              } else {
                this.lat = place.geometry.location.lat();
                this.lng = place.geometry.location.lng();
                this.click_lat = place.geometry.location.lat();
                this.click_long = place.geometry.location.lng();
                this.getAddress(this.lat, this.lng);
                $('#lat_new').val(place.geometry.location.lat());
                $('#lng_new').val(place.geometry.location.lng());
                $('#lbl_lat_new').addClass('active');
                $('#lbl_lng_new').addClass('active');
              }
            });
          });
        });
      }
      $('select').material_select();
    }, 3000);

    this.route.params
      .map((params) => params.id)
      .subscribe((id) => {
        this.pageId = `/${id}`;
        this.musicService.getMusicById(id).subscribe((m) => {
          if (m !== 'err' && m !== null) {
            let isValid: any = { state: false };
            isValid = m;
            if (!isValid.state) {
              window.open('/not_found/place', '_self');
            } else {
              this.music = m;
              window.document.title = `MusycGo - ${this.music.name} - ${this.music.place}`;
              this.cargarIdioma();
              localStorage.setItem('musicMarker', JSON.stringify(m));
              this.user.ifExist(this.music.id_user).subscribe((res) => {
                const res_: any = res;
                if (res_ !== null) {
                  $('#by_user').html(res_.nickname);
                } else {
                  this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
                    $('#by_user').html(res_anon);
                  });
                }
              });
              if (this.music.link_spotify !== null) {
                this.getIdLinkSpotify();
                let id_link_spotify = this.music.link_spotify.split('/album/')[1];
                this.embed_link = `https://open.spotify.com/embed/album/${
                  this.music.link_spotify.split('/album/')[1]
                  }`;
                if (this.music.link_spotify.split('/album/').length <= 1) {
                  id_link_spotify = this.music.link_spotify.split('/track/')[1];
                  this.embed_link = `https://open.spotify.com/embed/track/${
                    this.music.link_spotify.split('/track/')[1]
                    }`;
                }
              }
              if (this.music.link_youtube !== undefined) {
                // String regExp = "/.*(?:youtu.be\\/|v\\/|u/\\w/|embed\\/|watch\\?.*&?v=)";
                let id = '';
                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                const match = this.music.link_youtube.match(regExp);
                if (match && match[7].length === 11) {
                  id = match[7];
                }
                this.embed_link_yt = `https://www.youtube.com/embed/${id}`;
              }

              this.click_lat = this.music.lat;
              this.click_long = this.music.long;
              this.lat = this.music.lat;
              this.lng = this.music.long;
              $('#input_new_place').val('Lugar actualmente');
              this.setMusicSelected();
              this.updateLooks(m, 'look');
              this.getOtherPlaces();
            }
          } else {
            window.open('/not_found/place', '_self');
          }
          this.reCountQualifications(id);
        });
      });
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            this.saveUser();
            break;
          case 'error':
            break;
        }
      }
    });

    let buscar = 'Buscar';
    let noDate = 'No hay datos disponibles';
    let desAll = 'Deseleccionar todos los resultados';
    let selAll = 'Seleccionar todos los resultados';
    let desAll2 = 'Deseleccionar todo';
    let selAll2 = 'Seleccionar todo';
    let sel = 'Seleccione';
    this.translate.get(buscar).subscribe((res) => {
      buscar = res;
      this.translate.get(noDate).subscribe((res) => {
        noDate = res;
        this.translate.get(desAll).subscribe((res) => {
          desAll = res;
          this.translate.get(selAll).subscribe((res) => {
            selAll = res;
            this.translate.get(desAll2).subscribe((res) => {
              desAll2 = res;
              this.translate.get(selAll2).subscribe((res) => {
                selAll2 = res;
                this.translate.get(sel).subscribe((res) => {
                  sel = res;
                  this.dropdownSettings = {
                    singleSelection: false,
                    text: sel,
                    selectAllText: selAll2,
                    unSelectAllText: desAll2,
                    enableSearchFilter: true,
                    enableCheckAll: true,
                    filterSelectAllText: selAll,
                    filterUnSelectAllText: desAll,
                    badgeShowLimit: 13,
                    searchPlaceholderText: buscar,
                    labelKey: 'itemName',
                    primaryKey: 'id',
                    noDataLabel: noDate,
                    classes: 'change-color-dd',
                  };
                });
              });
            });
          });
        });
      });
    });

    this.translate.get('Mención en el título').subscribe((res1) => {
      this.dropdownList_reason.push({ id: '1', itemName: res1 });
      this.translate.get('Mención en la letra').subscribe((res2) => {
        this.dropdownList_reason.push({ id: '2', itemName: res2 });
        this.translate
          .get('Referencia a personalidad(es)/personaje(s) locales')
          .subscribe((res3) => {
            this.dropdownList_reason.push({ id: '3', itemName: res3 });
            this.translate
              .get('Referencia a acento/dialecto local')
              .subscribe((res4) => {
                this.dropdownList_reason.push({ id: '4', itemName: res4 });
                this.translate
                  .get('Referencia a comida local')
                  .subscribe((res5) => {
                    this.dropdownList_reason.push({ id: '5', itemName: res5 });
                    this.translate
                      .get('Referencia a costumbre local')
                      .subscribe((res6) => {
                        this.dropdownList_reason.push({
                          id: '6',
                          itemName: res6,
                        });
                        this.translate
                          .get('Referencia a vestimenta local')
                          .subscribe((res7) => {
                            this.dropdownList_reason.push({
                              id: '7',
                              itemName: res7,
                            });
                            this.translate
                              .get('Referencia a deporte local')
                              .subscribe((res8) => {
                                this.dropdownList_reason.push({
                                  id: '8',
                                  itemName: res8,
                                });
                                this.translate
                                  .get('Referencia a naturaleza local')
                                  .subscribe((res9) => {
                                    this.dropdownList_reason.push({
                                      id: '9',
                                      itemName: res9,
                                    });
                                    this.translate
                                      .get('Aparición en videoclip')
                                      .subscribe((res10) => {
                                        this.dropdownList_reason.push({
                                          id: '10',
                                          itemName: res10,
                                        });
                                        this.translate
                                          .get('Himno club local')
                                          .subscribe((res11) => {
                                            this.dropdownList_reason.push({
                                              id: '11',
                                              itemName: res11,
                                            });
                                            this.translate
                                              .get(
                                                'Himno político/administrativo (ciudad/estado/región/país)',
                                              )
                                              .subscribe((res11) => {
                                                this.dropdownList_reason.push({
                                                  id: '12',
                                                  itemName: res11,
                                                });
                                                this.translate
                                                  .get(
                                                    'Otra razón. (Explicar en la caja de justificación).',
                                                  )
                                                  .subscribe((res11) => {
                                                    this.dropdownList_reason.push(
                                                      {
                                                        id: '13',
                                                        itemName: res11,
                                                      },
                                                    );
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });
    });
  }

  getOtherPlaces() {
    this.musicService
      .getAllByNameArtist(this.music.name, this.music.artist)
      .subscribe((res) => {
        this.other_places = res;
        setTimeout(() => {
          for (
            let index = 0;
            index < this.other_places.length;
            index++
          ) {
            const element = this.other_places[index];
            if (
              element.justification[
                element.justification.length - 1
              ].justification.trim() === ''
            ) {
              $(`#div_content_just_${index}`).html('Sin justificación');
            } else {
              $(`#div_content_just_${index}`).html(
                element.justification[element.justification.length - 1]
                  .justification,
              );
            }
            this.user
              .ifExist(
                element.justification[element.justification.length - 1]
                  .id_user,
              )
              .subscribe((res) => {
                const res_: any = res;
                if (res_ !== null) {
                  $(`#div_content_by_${index}`).html(res_.nickname);
                } else {
                  this.translate
                    .get('Usuario Anónimo')
                    .subscribe((res_anon) => {
                      $(`#div_content_by_${index}`).html(res_anon);
                    });
                }
              });
          }
        }, 1500);
      });
  }

  reCountQualifications(id: string) {
    id = id.replace('/', '');
    this.contOptions = [
      { _0: 0 },
      { _1: 0 },
      { _2: 0 },
      { _3: 0 },
      { _4: 0 },
      { _5: 0 },
      { _6: 0 },
    ];
    this.user.showQualifi(id).subscribe((res) => {
      const r: any = res;
      this.sumResponses = 0;
      for (let i = 0; i < r.length; i++) {
        const forUsers = r[i]._id;
        for (let j = 0; j < forUsers.length; j++) {
          const forQualifi = forUsers[j];
          if (forQualifi.id_song === id) {
            if (forQualifi.option.toString() === '0') {
              this.contOptions[0]._0 = this.contOptions[0]._0 + 1;
            }
            if (forQualifi.option.toString() === '1') {
              this.contOptions[1]._1 = this.contOptions[1]._1 + 1;
              this.sumResponses = this.sumResponses + 1;
            }
            if (forQualifi.option.toString() === '2') {
              this.contOptions[2]._2 = this.contOptions[2]._2 + 1;
              this.sumResponses = this.sumResponses + 1;
            }
            if (forQualifi.option.toString() === '3') {
              this.contOptions[3]._3 = this.contOptions[3]._3 + 1;
              this.sumResponses = this.sumResponses + 1;
            }
            if (forQualifi.option.toString() === '4') {
              this.contOptions[4]._4 = this.contOptions[4]._4 + 1;
              this.sumResponses = this.sumResponses + 1;
            }
            if (forQualifi.option.toString() === '5') {
              this.contOptions[5]._5 = this.contOptions[5]._5 + 1;
              this.sumResponses = this.sumResponses + 1;
            }
            if (forQualifi.option.toString() === '6') {
              this.contOptions[6]._6 = this.contOptions[6]._6 + 1;
              this.sumResponses = this.sumResponses + 1;
            }
          }
        }
      }
    });
  }

  saveQualification() {
    const option = $('#qualifi').val();
    let userCurrentOption = '';
    if (this.currentuser.qualification.length > 0) {
      for (
        let index = 0;
        index < this.currentuser.qualification.length;
        index++
      ) {
        const element = this.currentuser.qualification[index].id_song;
        if (element === this.music._id) {
          this.existQualification = true;
          userCurrentOption = this.currentuser.qualification[index].option;
        }
      }
    }
    if (option !== '0' && option !== userCurrentOption) {
      if (this.existQualification) {
        this.user
          .updQualifi(this.profile.sub, option, this.music._id)
          .subscribe((res) => {
            this.user.ifExist(this.profile.sub).subscribe((res) => {
              const r: any = res;
              this.currentuser = r;
              this.translate
                .get('Opción actualizada exitosamente')
                .subscribe((res) => {
                  swal('', res, 'success', { buttons: [false], timer: 1500 });
                  this.reCountQualifications(this.pageId);
                });
            });
          });
      } else {
        this.user.ifExist(this.profile.sub).subscribe((res) => {
          const r: any = res;
          const qual: any = r.qualification;
          qual.push({ id_song: this.music._id, option });
          if (r !== null || r.length !== 0) {
            const upd_qualifi = [
              {
                _id: r._id,
                sub: r.sub,
                picture: r.picture,
                nickname: r.nickname,
                name: r.name,
                given_name: r.given_name,
                favorite: r.favorite,
                family_name: r.family_name,
                contributions: r.contributions,
                date: r.date,
                notifications: r.notifications,
                qualification: qual,
              },
            ];
            this.user.putUser(upd_qualifi[0]).subscribe((res) => {
              this.currentuser = upd_qualifi[0];
              this.existQualification = true;
              this.translate
                .get('Calificación realizada exitosamente')
                .subscribe((res) => {
                  swal('', res, 'success', { buttons: [false], timer: 1500 });
                  this.reCountQualifications(this.pageId);
                });
            });
          }
        });
      }
    } else if (option === userCurrentOption) {
      this.translate
        .get('Sin cambios. La opción ya fue registrada anteriormente')
        .subscribe((res) => {
          swal('', res, 'info', { buttons: [false], timer: 1500 });
        });
    } else {
      this.translate.get('Seleccione una opción válida').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    }
  }

  removeSong(item) {
    let txt = '¿Aceptas la eliminación permanente?';
    let btn_1 = 'Cancelar';
    let btn_2 = 'Eliminar';
    this.translate.get(txt).subscribe((res) => {
      txt = res;
    });
    this.translate.get(btn_1).subscribe((res) => {
      btn_1 = res;
    });
    this.translate.get(btn_2).subscribe((res) => {
      btn_2 = res;
    });
    swal({
      text: txt,
      icon: 'warning',
      buttons: [btn_1, btn_2],
    }).then((value) => {
      if (value) {
        const update_state = [
          {
            _id: item._id,
            name: item.name,
            artist: item.artist,
            gender: item.gender,
            language: item.language,
            lyric: item.lyric,
            reason: item.reason,
            justification: item.justification,
            look: item.look,
            hear: item.hear,
            favorite: item.favorite,
            lat: item.lat,
            long: item.long,
            place: item.place,
            country: item.country,
            state: false,
            info: item.info,
            id_user: item.id_user,
            link_spotify: item.link_spotify,
            link_youtube: item.link_youtube,
            link_soundcloud: item.link_soundcloud,
            date: item.date,
          },
        ];
        this.musicService.putMusic(update_state[0]).subscribe((res) => {
          this.translate
            .get('Operación realizada correctamente!')
            .subscribe((res) => {
              this.translate.get('Listo!').subscribe((title) => {
                swal(title, res, 'success');
              });
            });
          localStorage.removeItem('markers');
          this.markerService.init();
          window.open('/', '_self');
        });
      } else {
        this.translate.get('Operación cancelada!').subscribe((res) => {
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'error');
          });
        });
      }
    });
  }

  actionFavorite() {
    if (this.profile === undefined) {
      let btn_1 = 'Cancelar';
      let btn_2 = 'Iniciar Sesión';
      this.translate.get(btn_1).subscribe((res) => {
        btn_1 = res;
      });
      this.translate.get(btn_2).subscribe((res) => {
        btn_2 = res;
      });
      this.translate
        .get('Debe iniciar sesión para guardar en favoritos')
        .subscribe((res) => {
          swal({
            text: res,
            icon: 'warning',
            buttons: [btn_1, btn_2],
          }).then((value) => {
            if (value) {
              localStorage.setItem('last_url', window.location.href);
              this.auth.auth0.authorize();
            }
          });
          // swal("", res, "warning", { buttons: [false], timer: 1500 });
        });
    } else {
      $('.favorite_song').attr('disabled', true);
      $('#trans25').css('color', 'red');
      setTimeout(() => {
        $('.favorite_song').attr('disabled', false);
        $('#trans25').css('color', 'white');
      }, 2500);
      this.user.ifExist(this.profile.sub).subscribe((res) => {
        const res_: any = res;
        this.currentuser = res_;
        let other_places: any;
        const other_places_id = [];
        const new_array_fav: any = this.currentuser.favorite;
        this.musicService
          .getAllByNameArtist(this.music.name, this.music.artist)
          .subscribe((res) => {
            other_places = res;
            for (let index = 0; index < other_places.length; index++) {
              other_places_id.push(other_places[index]._id);
            }
            let index_find;
            for (let index = 0; index < other_places_id.length; index++) {
              const element = other_places_id[index];
              index_find = new_array_fav.findIndex((x) => x.id === element);
              if (index_find !== -1) {
                // quitar
                $('.favorite_song').css('background', 'black');
                this.updateLooks(other_places[index], 'favorite_remove');
                new_array_fav.splice(index_find, 1);
              } else {
                // agregar
                $('.favorite_song').css('background', 'red');
                this.updateLooks(other_places[index], 'favorite_add');
                new_array_fav.push({
                  id: element,
                  date_in: new Date().toString(),
                });
              }
              if (other_places_id.length === index + 1) {
                // setTimeout(() => {
                const upd_fav = [
                  {
                    _id: this.currentuser._id,
                    sub: this.currentuser.sub,
                    picture: this.currentuser.picture,
                    nickname: this.currentuser.nickname,
                    name: this.currentuser.name,
                    given_name: this.currentuser.given_name,
                    favorite: new_array_fav,
                    family_name: this.currentuser.family_name,
                    contributions: this.currentuser.contributions,
                    date: this.currentuser.date,
                    notifications: this.currentuser.notifications,
                    qualification: this.currentuser.qualification,
                  },
                ];
                this.user.putUser(upd_fav[0]).subscribe((res) => {
                  this.currentuser = upd_fav[0];
                });
                // }, 1000);
              }
            }
          });
      });
    }
  }

  cargarIdioma() {
    this.dropdownList_lang = [];
    this.lang_actual = [];
    this.lang_modific = [];
    this.selectedItems_lang = [];

    let index_lang = 0;
    for (const language in this.all_language) {
      if (this.lang_disqus === 'es') {
        if (this.all_language[language].lang_es !== undefined) {
          this.dropdownList_lang.push({
            item_id: index_lang,
            item_text: this.all_language[language].lang_es,
          });
          for (let index = 0; index < this.music.language.length; index++) {
            const element = this.music.language[index];
            if (
              element === this.all_language[language].lang_es
              || element === this.all_language[language].lang_en
              || element === this.all_language[language].lang_pt
            ) {
              this.selectedItems_lang.push({
                item_id: index_lang,
                item_text: this.all_language[language].lang_es,
              });
              this.lang_actual.push({
                item_id: index_lang,
                item_text: element,
              });
              this.lang_modific.push({
                item_id: index_lang,
                item_text: element,
              });
            }
          }
        }
      }
      if (this.lang_disqus === 'en') {
        if (this.all_language[language].lang_en !== undefined) {
          this.dropdownList_lang.push({
            item_id: index_lang,
            item_text: this.all_language[language].lang_en,
          });
          for (let index = 0; index < this.music.language.length; index++) {
            const element = this.music.language[index];
            if (
              element === this.all_language[language].lang_es
              || element === this.all_language[language].lang_en
              || element === this.all_language[language].lang_pt
            ) {
              this.selectedItems_lang.push({
                item_id: index_lang,
                item_text: this.all_language[language].lang_en,
              });
              this.lang_actual.push({
                item_id: index_lang,
                item_text: element,
              });
              this.lang_modific.push({
                item_id: index_lang,
                item_text: element,
              });
            }
          }
        }
      }
      if (this.lang_disqus === 'pt') {
        if (this.all_language[language].lang_pt !== undefined) {
          this.dropdownList_lang.push({
            item_id: index_lang,
            item_text: this.all_language[language].lang_pt,
          });
          for (let index = 0; index < this.music.language.length; index++) {
            const element = this.music.language[index];
            if (
              element === this.all_language[language].lang_es
              || element === this.all_language[language].lang_en
              || element === this.all_language[language].lang_pt
            ) {
              this.selectedItems_lang.push({
                item_id: index_lang,
                item_text: this.all_language[language].lang_pt,
              });
              this.lang_actual.push({
                item_id: index_lang,
                item_text: element,
              });
              this.lang_modific.push({
                item_id: index_lang,
                item_text: element,
              });
            }
          }
        }
      }
      index_lang++;
    }
    let txt = 'Buscar';
    this.translate.get(txt).subscribe((res) => {
      txt = res;
    });
    this.dropdownSettings_lang = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: false,
      clearSearchFilter: true,
      maxHeight: 300,
      limitSelection: 5,
      defaultOpen: true,
      searchPlaceholderText: txt,
    };
  }

  onItemSelect(item: any) {
    this.lang_modific = [];
    if (this.selectedItems_lang.length > 0) {
      for (let index = 0; index < this.selectedItems_lang.length; index++) {
        const element = this.selectedItems_lang[index];
        this.lang_modific.push({
          item_id: element.item_id,
          item_text: element.item_text,
        });
      }
    }
  }

  onItemDeSelect(item: any) {
    this.lang_modific = [];
    if (this.selectedItems_lang.length > 0) {
      for (let index = 0; index < this.selectedItems_lang.length; index++) {
        const element = this.selectedItems_lang[index];
        this.lang_modific.push({
          item_id: element.item_id,
          item_text: element.item_text,
        });
      }
    }
  }

  updateLooks(music, action) {
    let num_look = music.look;
    let num_hear = music.hear;
    let num_favorite = music.favorite;
    if (action === 'look') {
      num_look = music.look + 1;
    } else {
      num_look = music.look;
    }
    if (action === 'hear') {
      num_hear = music.hear + 1;
    } else {
      num_hear = music.hear;
    }

    if (action === 'favorite_add') {
      if (music.favorite === null) {
        num_favorite = 1;
      } else {
        num_favorite = music.favorite + 1;
      }
    }

    if (action === 'favorite_remove') {
      if (music.favorite === null) {
        num_favorite = 0;
      } else {
        num_favorite = music.favorite - 1;
      }
    }

    const look_for_update = [
      {
        _id: music._id,
        name: music.name,
        artist: music.artist,
        gender: music.gender,
        language: music.language,
        lyric: music.lyric,
        reason: music.reason,
        justification: music.justification,
        look: num_look,
        hear: num_hear,
        favorite: num_favorite,
        lat: music.lat,
        long: music.long,
        place: music.place,
        country: music.country,
        state: music.state,
        info: music.info,
        id_user: music.id_user,
        link_spotify: music.link_spotify,
        link_youtube: music.link_youtube,
        link_soundcloud: music.link_soundcloud,
        date: music.date,
      },
    ];
    this.musicService.putMusic(look_for_update[0]).subscribe((res) => {
      if (look_for_update[0]._id === this.music._id) {
        localStorage.removeItem('musicMarker');
        localStorage.setItem('musicMarker', JSON.stringify(look_for_update[0]));
        this.setMusicSelected();
        window.scrollTo(0, 0);
        localStorage.removeItem('markers');
        this.markerService.init();
      }
    });
  }

  getAddress(latitude, longitude) {
    this.lat_country = 0;
    this.lng_country = 0;
    this.name_country = '';
    this.geoCoder.geocode(
      {
        location: { lat: latitude, lng: longitude },
        language: 'es',
      },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.lat_country = results[
              results.length - 1
            ].geometry.location.lat();
            this.lng_country = results[
              results.length - 1
            ].geometry.location.lng();
            this.name_country = results[results.length - 2].address_components[0].short_name;
            this.address = results[0].formatted_address;
            $('#input_new_place').val(this.address);
          }
        }
      },
    );
  }

  mapDoneCoord(event: any) {
    this.map_ = event;
    this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
      document.getElementById('buscador_new'),
    );
  }

  changeCoords(coord) {
    this.lat = coord.lat; // posicion mapa
    this.lng = coord.long;
    this.click_lat = coord.lat; // posicion marcador
    this.click_long = coord.long;
  }

  mapClicked($event: any, infoWindow) {
    this.click_lat = $event.coords.lat;
    this.click_long = $event.coords.lng;
    infoWindow._openInfoWindow();
    $('#lat_new').val(this.click_lat);
    $('#lng_new').val(this.click_long);
    this.getAddress(this.click_lat, this.click_long);
    $('#lbl_lat_new').addClass('active');
    $('#lbl_lng_new').addClass('active');
  }

  setMusicSelected() {
    if (JSON.parse(localStorage.getItem('musicMarker')) !== null) {
      this.music = JSON.parse(localStorage.getItem('musicMarker'));
      this.dateCurrentJusti = this.music.justification[
        this.music.justification.length - 1
      ].date;
      if (this.music.link_spotify !== null) {
        this.getIdLinkSpotify();
        let id_link_spotify = this.music.link_spotify.split('/album/')[1];
        this.embed_link = `https://open.spotify.com/embed/album/${
          this.music.link_spotify.split('/album/')[1]
          }`;
        if (this.music.link_spotify.split('/album/').length <= 1) {
          id_link_spotify = this.music.link_spotify.split('/track/')[1];
          this.embed_link = `https://open.spotify.com/embed/track/${
            this.music.link_spotify.split('/track/')[1]
            }`;
        }
      }
      if (this.music.link_youtube !== undefined) {
        // String regExp = "/.*(?:youtu.be\\/|v\\/|u/\\w/|embed\\/|watch\\?.*&?v=)";
        let id = '';
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        const match = this.music.link_youtube.match(regExp);
        if (match && match[7].length === 11) {
          id = match[7];
        }
        this.embed_link_yt = `https://www.youtube.com/embed/${id}`;
      }
      this.click_lat = this.music.lat;
      this.click_long = this.music.long;
      this.lat = this.music.lat;
      this.lng = this.music.long;
      $('#lyric_document').html(this.music.lyric);
      $('#lyric_document *').css('color', '#24303e');
      if (
        this.music.justification !== ''
        && this.music.justification[this.music.justification.length - 1]
          .justification !== ''
      ) {
        $('#justification_document').html(
          this.music.justification[this.music.justification.length - 1]
            .justification,
        );
      }
      this.text_lyric = this.music.lyric;
      this.text_justification = this.music.justification[
        this.music.justification.length - 1
      ].justification;
      $('#input_new_place').val('Lugar actualmente');
      if (this.music.reason) {
        this.currentreason = this.music.reason;
      } else {
        this.currentreason = [];
      }
      this.selectedItems_reason = [];
      if (this.currentreason.length > 0) {
        for (let i = 0; i < this.currentreason.length; i++) {
          const elem1 = this.currentreason[i];
          for (let j = 0; j < this.dropdownList_reason.length; j++) {
            const elem2 = this.dropdownList_reason[j].id;
            if (elem1 === elem2) {
              this.selectedItems_reason.push({
                id: elem1,
                itemName: this.dropdownList_reason[j].itemName,
              });
            }
          }
        }
      }
      this.currentplace = this.music.place;
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.prevJustification = [];
        if (this.music.justification.length > 0) {
          this.prevJustification = this.music.justification;
          for (
            let index = 0;
            index < this.prevJustification.length - 1;
            index++
          ) {
            const element = this.prevJustification[index];
            $(`#justification_document_${index}`).html(element.justification);
            this.user
              .ifExist(this.prevJustification[index].id_user)
              .subscribe((res) => {
                const res_: any = res;
                if (res_ !== null) {
                  $(`#user_just_${index}`).html(res_.nickname);
                } else {
                  this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
                    $(`#user_just_${index}`).html(res_anon);
                  });
                }
              });
          }
        }
        this.user
          .ifExist(
            this.music.justification[this.music.justification.length - 1]
              .id_user,
          )
          .subscribe((res) => {
            const res_: any = res;
            if (res_ !== null) {
              this.nickname_user = res_.nickname;
            } else {
              this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
                this.nickname_user = res_anon;
              });
            }
          });
        this.music.country.name;

        if (this.music.country[0] === undefined) {
          this.currentcountry = this.music.country.name;
        } else {
          this.currentcountry = this.music.country[0].name;
        }
      }, 1000);
    } else {
      window.open('/', '_self');
    }
  }

  addNewGeorref() {
    localStorage.setItem('link_song_spot', this.music.link_spotify);
  }

  setMusicProfile(music: any) {
    this.musicService.setMusicSelected(music);
  }

  getIdLinkSpotify() {
    this.id_link_spotify = this.music.link_spotify.split('/album/')[1];
    if (this.music.link_spotify.split('/album/').length <= 1) {
      this.id_link_spotify = this.music.link_spotify.split('/track/')[1];
    }
    if (this.id_link_spotify !== null) {
      this.spotifyService.getInfoTrackById(this.id_link_spotify).subscribe(
        (res) => {
          $('#imgmusic').css('border', 'solid 3px white');
          this.img_music = res.album.images[0].url;
        },
        (error) => {
          $('#imgmusic').css('border', 'none');
          this.img_music = 'https://www.musycgo.com/icon/marker.png';

          this.http.get(VAR_CONFIG.url_n_t_s).subscribe(async (data) => {
            const var_body: any = data;
            await localStorage.setItem('token_s', var_body.body.access_token);
          });
        },
      );
    }
  }

  cleanForm() {
    $('#new_name').val(null);
    $('#new_artist').val(null);
    $('#new_lyric').val(null);
    $('#new_justification').val(null);
    $('#new_spotify').val(null);
    $('#new_youtube').val(null);
    $('#new_soundcloud').val(null);
    $('#new_lang').val(null);
    $('#lat_new').val(null);
    $('#lng_new').val(null);
    $('#input_new_place').val(null);

    $('#new_name').removeClass('valid invalide');
    $('#new_artist').removeClass('valid invalide');
    $('#new_lyric').removeClass('valid invalide');
    $('#new_justification').removeClass('valid invalide');
    $('#new_spotify').removeClass('valid invalide');
    $('#new_lang').removeClass('valid invalide');
    $('#new_youtube').removeClass('valid invalide');
    $('#new_soundcloud').removeClass('valid invalide');
    $('#lat_new').removeClass('valid invalide');
    $('#lng_new').removeClass('valid invalide');

    $('label').removeClass('active');
    $('.collapsible-body').css('display', 'none');
    $('#bodyupdform').css('display', 'none');
    $('li').removeClass('active');
  }

  updateSong(music) {
    let onlyThisPlace = true;
    let borrar = false;
    const array_lang_actual = [];
    let array_lang_modific = [];
    let array_lang_merge = [];
    let array_lang_unique = [];

    const array_reason_modific = [];
    let array_reason_merge = [];
    let array_reason_unique = [];
    for (let index = 0; index < this.lang_actual.length; index++) {
      const element = this.lang_actual[index].item_text;
      array_lang_actual.push(element);
    }
    for (let index = 0; index < this.lang_modific.length; index++) {
      const element = this.lang_modific[index].item_text;
      array_lang_modific.push(element);
    }

    if (this.lang_disqus !== 'es') {
      const new_array = [];
      const array_length = array_lang_modific.length;
      for (let index = 0; index < array_lang_modific.length; index++) {
        for (const language in this.all_language) {
          const element = array_lang_modific[index];
          if (
            element === this.all_language[language].lang_es
            || element === this.all_language[language].lang_en
            || element === this.all_language[language].lang_pt
          ) {
            new_array.push(this.all_language[language].lang_es);
          }
        }
        if (index === array_length - 1) {
          array_lang_modific = new_array;
        }
      }
    }

    const listNumReason = [];
    for (let index = 0; index < this.selectedItems_reason.length; index++) {
      const element = this.selectedItems_reason[index].id;
      listNumReason.push(element);
    }

    const upd_form_data = {
      // name: $("#new_name").val(),
      // artist: $("#new_artist").val(),
      // lyric: $("#new_lyric").val(),
      lyric: this.text_lyric,
      justification: this.text_justification,
      // spotify: $("#new_spotify").val(),
      language: array_lang_modific,
      // language: langs,
      reason: listNumReason,
      youtube: $('#new_youtube').val(),
      soundcloud: $('#new_soundcloud').val(),
      lat: $('#lat_new').val(),
      long: $('#lng_new').val(),
      place: $('#input_new_place').val(),
    };

    const music_for_update = [
      {
        _id: music._id,
        name: music.name,
        artist: music.artist,
        gender: music.gender,
        language: music.language,
        lyric: music.lyric,
        reason: music.reason,
        justification: music.justification,
        look: music.look,
        hear: music.hear,
        favorite: music.favorite,
        lat: music.lat,
        long: music.long,
        place: music.place,
        country: music.country,
        state: music.state,
        info: music.info,
        id_user: music.id_user,
        link_spotify: music.link_spotify,
        link_youtube: music.link_youtube,
        link_soundcloud: music.link_soundcloud,
        date: music.date,
      },
    ];

    const unique = (value, index, self) => self.indexOf(value) === index;

    array_reason_merge = this.currentreason.concat(listNumReason);
    array_reason_unique = array_reason_merge.filter(unique);
    if (
      this.currentreason.length !== array_reason_unique.length
      || listNumReason.length !== array_reason_unique.length
    ) {
      borrar = true;
      music_for_update[0].reason = upd_form_data.reason;
    } else {
      delete upd_form_data.reason;
    }

    array_lang_merge = array_lang_actual.concat(array_lang_modific);
    array_lang_unique = array_lang_merge.filter(unique);
    if (
      array_lang_actual.length !== array_lang_unique.length
      || array_lang_modific.length !== array_lang_unique.length
    ) {
      borrar = true;
      onlyThisPlace = false;
      music_for_update[0].language = upd_form_data.language;
    } else {
      delete upd_form_data.language;
    }
    if (upd_form_data.lyric) {
      if ($.trim(String(upd_form_data.lyric)) === this.music.lyric.trim()) {
        delete upd_form_data.lyric;
      } else {
        borrar = true;
        onlyThisPlace = false;
        music_for_update[0].lyric = upd_form_data.lyric;
      }
    }
    if (upd_form_data.justification) {
      if (
        $.trim(String(upd_form_data.justification))
        === this.music.justification[
          this.music.justification.length - 1
        ].justification.trim()
      ) {
        delete upd_form_data.justification;
      } else {
        borrar = true;
        let dateserver: any = new Date().toString();
        this.http.get(VAR_CONFIG.url_datetime_server).subscribe(async (data) => {
          const date: any = data;
          dateserver = date.date;
          await music.justification.push({
            justification: upd_form_data.justification,
            id_user: this.profile.sub,
            date: dateserver,
          });
        });

        music_for_update[0].justification = music.justification;
      }
    }
    if (upd_form_data.youtube) {
      if ($.trim(String(upd_form_data.youtube)) === '') {
        delete upd_form_data.youtube;
      } else {
        borrar = true;
        onlyThisPlace = false;
        music_for_update[0].link_youtube = upd_form_data.youtube;
      }
    }

    if (upd_form_data.soundcloud) {
      if ($.trim(String(upd_form_data.soundcloud)) === '') {
        delete upd_form_data.soundcloud;
      } else {
        borrar = true;
        onlyThisPlace = false;
        music_for_update[0].link_soundcloud = upd_form_data.soundcloud;
      }
    }
    let pais = '';
    if (upd_form_data.lat) {
      if ($.trim(String(upd_form_data.lat)) === '') {
        delete upd_form_data.lat;
        delete upd_form_data.long;
      } else {
        borrar = true;
        this.geoCoder.geocode(
          {
            location: {
              lat: Number(upd_form_data.lat),
              lng: Number(upd_form_data.long),
            },
          },
          (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                pais = results[results.length - 2].address_components[0].short_name;
                music_for_update[0].lat = Number(upd_form_data.lat);
                music_for_update[0].long = Number(upd_form_data.long);
                music_for_update[0].place = upd_form_data.place;
                music_for_update[0].country = {
                  name: this.name_country,
                  coord: {
                    lat: this.lat_country,
                    lng: this.lng_country,
                  },
                };
              }
            }
          },
        );
      }
    }

    if (borrar) {
      let txt = '¿Aceptas la modificación?';
      let btn_1 = 'Cancelar';
      let btn_2 = 'Continuar';
      this.translate.get(txt).subscribe((res) => {
        txt = res;
      });
      this.translate.get(btn_1).subscribe((res) => {
        btn_1 = res;
      });
      this.translate.get(btn_2).subscribe((res) => {
        btn_2 = res;
      });
      swal({
        text: txt,
        icon: 'warning',
        buttons: [btn_1, btn_2],
      }).then((value) => {
        if (value) {
          this.musicService.putMusic(music_for_update[0]).subscribe((res) => {
            this.user.ifExist(this.music.id_user).subscribe((res) => {
              if (res !== null && res !== undefined) {
                const r: any = res;
                let notifi = r.notifications;
                if (notifi !== null && notifi !== []) {
                  notifi.push({
                    id_song: this.music._id,
                    name: this.music.name,
                    artist: this.music.artist,
                    place: this.music.place,
                    date: new Date().toString(),
                    who: this.profile.sub,
                    whonick: this.profile.nickname,
                    active: true,
                  });
                } else {
                  notifi = {
                    id_song: this.music._id,
                    name: this.music.name,
                    artist: this.music.artist,
                    place: this.music.place,
                    date: new Date().toString(),
                    who: this.profile.sub,
                    whonick: this.profile.nickname,
                    active: true,
                  };
                }
                const updcolabuser = [
                  {
                    _id: r._id,
                    sub: r.sub,
                    picture: r.picture,
                    nickname: r.nickname,
                    name: r.name,
                    given_name: r.given_name,
                    favorite: r.favorite,
                    family_name: r.family_name,
                    contributions: r.contributions,
                    date: r.date,
                    notifications: notifi,
                    qualification: r.qualification,
                  },
                ];
                this.user.putUser(updcolabuser[0]).subscribe((res) => {
                  if (
                    this.other_places !== 0
                    && this.other_places !== undefined
                    && this.other_places !== null
                  ) {
                    if (!onlyThisPlace) {
                      this.updateOtherPlaces();
                    }
                  }
                  setTimeout(() => {
                    this.cleanForm();
                    localStorage.removeItem('musicMarker');
                    localStorage.setItem(
                      'musicMarker',
                      JSON.stringify(music_for_update[0]),
                    );
                    this.setMusicSelected();
                    window.scrollTo(0, 0);
                    this.translate
                      .get('Canción modificada correctamente!')
                      .subscribe((res) => {
                        this.translate.get('Genial!').subscribe((title) => {
                          swal(title, res, 'success');
                        });
                      });
                    localStorage.removeItem('markers');
                    this.markerService.init();
                    this.cargarIdioma();
                    this.getOtherPlaces();
                  }, 200);
                  this.changeDetectorRef.detectChanges();
                });
              } else {
                if (
                  this.other_places !== 0
                  && this.other_places !== undefined
                  && this.other_places !== null
                ) {
                  if (!onlyThisPlace) {
                    this.updateOtherPlaces();
                  }
                }
                setTimeout(() => {
                  this.cleanForm();
                  localStorage.removeItem('musicMarker');
                  localStorage.setItem(
                    'musicMarker',
                    JSON.stringify(music_for_update[0]),
                  );
                  this.setMusicSelected();
                  window.scrollTo(0, 0);
                  this.translate
                    .get('Canción modificada correctamente!')
                    .subscribe((res) => {
                      this.translate.get('Genial!').subscribe((title) => {
                        swal(title, res, 'success');
                      });
                    });
                  localStorage.removeItem('markers');
                  this.markerService.init();
                  this.cargarIdioma();
                  this.getOtherPlaces();
                }, 200);
              }
            });
          });
        } else {
          this.translate.get('Operación cancelada!').subscribe((res) => {
            this.translate.get('Ups!').subscribe((title) => {
              swal(title, res, 'error');
            });
          });
        }
      });
    } else {
      this.translate
        .get('Sin cambios en los datos ingresados!')
        .subscribe((res) => {
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'warning');
          });
        });
    }
  }

  updateOtherPlaces() {
    let array_lang_modific = [];
    for (let index = 0; index < this.lang_modific.length; index++) {
      const element = this.lang_modific[index].item_text;
      array_lang_modific.push(element);
    }
    if (this.lang_disqus !== 'es') {
      const new_array = [];
      const array_length = array_lang_modific.length;
      for (let index = 0; index < array_lang_modific.length; index++) {
        for (const language in this.all_language) {
          const element = array_lang_modific[index];
          if (
            element === this.all_language[language].lang_es
            || element === this.all_language[language].lang_en
            || element === this.all_language[language].lang_pt
          ) {
            new_array.push(this.all_language[language].lang_es);
          }
        }
        if (index === array_length - 1) {
          array_lang_modific = new_array;
        }
      }
    }
    const upd_data_other_places = [
      {
        // name: $("#new_name").val(), //POR EL MOMENTO NO SE PUEDE EDITAR
        // artist: $("#new_artist").val(), //POR EL MOMENTO NO SE PUEDE EDITAR
        // lyric: $("#new_lyric").val(),
        lyric: this.text_lyric,
        // justification: this.text_justification,
        // link_spotify: $("#new_spotify").val(), //POR EL MOMENTO NO SE PUEDE EDITAR
        link_youtube: $('#new_youtube').val(),
        link_soundcloud: $('#new_soundcloud').val(),
        language: array_lang_modific,
      },
    ];
    for (const clave in upd_data_other_places[0]) {
      if (
        clave === 'lyric'
        || clave === 'link_youtube'
        || clave === 'link_soundcloud'
      ) {
        if (upd_data_other_places[0][clave].trim() === '') {
          delete upd_data_other_places[0][clave];
        }
      }
    }
    for (let i = 0; i < this.other_places.length; i++) {
      const element = this.other_places[i];
      const for_update = [
        {
          _id: element._id,
          name: element.name,
          artist: element.artist,
          gender: element.gender,
          language: element.language,
          lyric: element.lyric,
          reason: element.reason,
          justification: element.justification,
          look: element.look,
          hear: element.hear,
          favorite: element.favorite,
          lat: element.lat,
          long: element.long,
          place: element.place,
          country: element.country,
          state: element.state,
          info: element.info,
          id_user: element.id_user,
          link_spotify: element.link_spotify,
          link_youtube: element.link_youtube,
          link_soundcloud: element.link_soundcloud,
          date: element.date,
        },
      ];

      for (const clave in upd_data_other_places[0]) {
        for_update[0][clave] = upd_data_other_places[0][clave];
      }
      this.musicService.putMusic(for_update[0]).subscribe((res) => { });
      if (i === this.other_places.length - 1) {
        const us: any = [];
        for (let index = 0; index < this.other_places.length; index++) {
          const element = this.other_places[index];
          us.push(element.id_user);
        }
        let cont = 0;
        this.user.getUsersFromAnAPI(us).subscribe(
          (response) => {
            let notifi: any = [];
            let updcolabuser: any = [];
            if (response !== null && response !== undefined) {
              notifi = response.notifications;
              if (
                response.notifications !== null
                && response.notifications !== []
              ) {
                if (this.other_places[cont]._id !== this.music._id) {
                  notifi.push({
                    id_song: this.other_places[cont]._id,
                    name: this.other_places[cont].name,
                    artist: this.other_places[cont].artist,
                    place: this.other_places[cont].place,
                    date: new Date().toString(),
                    who: this.profile.sub,
                    whonick: this.profile.nickname,
                    active: true,
                  });
                  updcolabuser = [
                    {
                      _id: response._id,
                      sub: response.sub,
                      picture: response.picture,
                      nickname: response.nickname,
                      name: response.name,
                      given_name: response.given_name,
                      favorite: response.favorite,
                      family_name: response.family_name,
                      contributions: response.contributions,
                      date: response.date,
                      notifications: notifi,
                      qualification: response.qualification,
                    },
                  ];
                  this.user.putUser(updcolabuser[0]).subscribe((res) => {
                    this.changeDetectorRef.detectChanges();
                  });
                }
              } else {
                notifi = {
                  id_song: this.other_places[cont]._id,
                  name: this.other_places[cont].name,
                  artist: this.other_places[cont].artist,
                  place: this.other_places[cont].place,
                  date: new Date().toString(),
                  who: this.profile.sub,
                  whonick: this.profile.nickname,
                  active: true,
                };
                updcolabuser = [
                  {
                    _id: response._id,
                    sub: response.sub,
                    picture: response.picture,
                    nickname: response.nickname,
                    name: response.name,
                    given_name: response.given_name,
                    favorite: response.favorite,
                    family_name: response.family_name,
                    contributions: response.contributions,
                    date: response.date,
                    notifications: notifi,
                    qualification: response.qualification,
                  },
                ];
                this.user.putUser(updcolabuser[0]).subscribe((res) => {
                  this.changeDetectorRef.detectChanges();
                });
              }
            }
            if (cont === us.length - 1) {
              this.cleanForm();
              this.setMusicSelected();
              window.scrollTo(0, 0);
              this.translate
                .get('Canción modificada correctamente!')
                .subscribe((res) => {
                  this.translate.get('Genial!').subscribe((title) => {
                    swal(title, res, 'success');
                  });
                });
              localStorage.removeItem('markers');
              this.markerService.init();
              this.cargarIdioma();
            }
            cont++;
          },
          (error) => { },
        );
      }
    }
  }

  openModalYT() {
    $('#modal_youtube').modal({
      opacity: 0.9,
      complete() {
        $('.videoPlayer')[0].contentWindow.postMessage(
          '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
          '*',
        );
      },
    });
    $('#modal_youtube').modal('open');
  }

  openModalSpot() {
    $('#moda_player_window_spot').modal('open');
  }

  openModalSoundCloud() {
    $('#moda_player_window_soundcloud').modal('open');
    if (this.music.link_soundcloud !== undefined) {
      const settings = {
        async: true,
        crossDomain: true,
        url: 'http://soundcloud.com/oembed',
        method: 'POST',
        headers: {},
        data: {
          format: 'json',
          url: this.music.link_soundcloud,
        },
      };
      $.ajax(settings).done((response) => {
        $('#div_embed_soundcloud').html(response.html);
      });
    }
  }

  translateTitles() {
    this.translate.get('Volver').subscribe((res) => {
      this.trans23 = res;
      $('#trans23').attr('title', this.trans23);
    });
    this.translate.get('Cambiar al Idioma Español').subscribe((res) => {
      this.trans03 = res;
      $('#trans03').attr('title', this.trans03);
    });
    this.translate.get('Cambiar al Idioma Inglés').subscribe((res) => {
      this.trans04 = res;
      $('#trans04').attr('title', this.trans04);
    });
    this.translate.get('Cambiar al Idioma Portugués').subscribe((res) => {
      this.trans05 = res;
      $('#trans05').attr('title', this.trans05);
    });
    this.translate.get('Identificarse').subscribe((res) => {
      this.trans07 = res;
      $('#trans07').attr('title', this.trans07);
    });
    this.translate.get('Guardar en Favoritos').subscribe((res) => {
      this.trans25 = res;
      $('#trans25').attr('title', this.trans25);
    });
    this.translate.get('Visitas al Perfil').subscribe((res) => {
      this.trans26 = res;
      $('#trans26').attr('title', this.trans26);
    });
    this.translate.get('Reproducciones en Spotify').subscribe((res) => {
      this.trans27 = res;
      $('#trans27').attr('title', this.trans27);
    });
  }

  setStateNoti(state) {
    this.stateNoti = state;
  }

  cerrarModal() {
    $('.modal').modal('close');
  }

  getInfoUser(user: any, type: string) {
    this.contributions = [];
    this.res = [];
    const markers: any = JSON.parse(localStorage.getItem('markers'));
    if (type === 'id') {
      this.user.infoUser2(user.id_user).subscribe((result) => {
        this.res = result;
        if (result === null) {
          this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
            this.res = {
              type: 'anonymous',
              nickname: res_anon,
              picture:
                'https://pngimage.net/wp-content/uploads/2018/05/anonimo-png.png',
            };
          });
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === '' || element === '-' || element === 'NN') {
              this.contributions.push(markers[index]);
              this.num_contributions = this.contributions.length;
            }
          }
        } else {
          this.res = {
            type: this.res.sub.charAt(0),
            _id: this.res._id,
            sub: this.res.sub,
            nickname: this.res.nickname,
            picture: this.res.picture,
          };
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === this.res.sub) {
              this.contributions.push(markers[index]);
              this.num_contributions = this.contributions.length;
            }
          }
        }
      });
    } else {
      this.num_contributions = user[1];
      const nick = user[0];
      const picture = user[2];
      this.user.infoUser(nick, picture).subscribe((result) => {
        this.res = result;
        if (result === null) {
          this.res = { nickname: user[0], picture: user[2] };
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === '' || element === '-' || element === 'NN') {
              this.contributions.push(markers[index]);
            }
          }
        } else {
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === this.res.sub) {
              this.contributions.push(markers[index]);
            }
          }
        }
      });
    }
  }

  onDeselectAllR(items: any) {
    this.selectedItems_reason = [];
  }

  secondToDeg() {
    const secDay = 86400;
    const deg = 360;
    const currentDate = new Date();
    const h = currentDate.getHours();
    const m = currentDate.getMinutes();
    const s = currentDate.getSeconds();
    const currentSeconds = ((h * 3600) + (m * 60) + s);
    const result = (deg * currentSeconds) / secDay;
    const sectoDegString = (result.toString() + "deg").toString();
    const styles = {
      'mix-blend-mode': 'lighten',
      'filter': 'blur(0.1px) hue-rotate(' + sectoDegString + ') saturate(105%)'
    };
    return styles;
  }
}
