export class Music {
  constructor(_id = '',
    name = '',
    artist = '',
    reason = null,
    justification = [],
    gender = [],
    language = [],
    lyric = '',
    lat = null,
    long = null,
    place = '',
    country = [],
    state = null,
    info = '',
    id_user = '',
    link_spotify = '',
    link_youtube = '',
    link_soundcloud = '',
    date = '') {
    this._id = _id;
    this.name = name;
    this.artist = artist;
    this.gender = gender;
    this.language = language;
    this.lyric = lyric;
    this.reason = reason;
    this.justification = justification;
    this.lat = lat;
    this.long = long;
    this.place = place;
    this.country = country;
    this.state = state;
    this.info = info;
    this.id_user = id_user;
    this.link_spotify = link_spotify;
    this.link_youtube = link_youtube;
    this.link_soundcloud = link_soundcloud;
    this.date = date;
  }

    _id: string;

    name: string;

    artist: string;

    gender: any;

    language: any;

    lyric: string;

    reason: any;

    justification: any;

    lat: number;

    long: number;

    place: string;

    country: any;

    state: boolean;

    info: string;

    id_user: string;

    link_spotify: string;

    link_youtube: string;

    link_soundcloud: string;

    date: string;
}
