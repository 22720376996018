import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reason',
})
export class ReasonPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let text = '';
    if (value === '0' || value === undefined || value === null || value === '') {
      text = '';
    }
    if (value === '1') {
      text = 'Mención en el título';
    }
    if (value === '2') {
      text = 'Mención en la letra';
    }
    if (value === '3') {
      text = 'Referencia a personalidad(es)/personaje(s) locales';
    }
    if (value === '4') {
      text = 'Referencia a acento/dialecto local';
    }
    if (value === '5') {
      text = 'Referencia a comida local';
    }
    if (value === '6') {
      text = 'Referencia a costumbre local';
    }
    if (value === '7') {
      text = 'Referencia a vestimenta local';
    }
    if (value === '8') {
      text = 'Referencia a deporte local';
    }
    if (value === '9') {
      text = 'Referencia a naturaleza local';
    }
    if (value === '10') {
      text = 'Aparición en videoclip';
    }
    if (value === '11') {
      text = 'Himno club local';
    }
    if (value === '12') {
      text = 'Himno político/administrativo (ciudad/estado/región/país)';
    }
    if (value === '13') {
      text = 'Otra razón.';
      // text = 'Otra razón. (Explicar en la caja de justificación).';
    }
    return text;
  }
}
