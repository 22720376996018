interface VarsConfig {
    url_home_dev: string;
    url_home_prod: string;
    url_t_s: string;
    url_n_t_s: string;
    url_api_music: string;
    url_api_user: string;
    url_api_places: string;
    url_datetime_server: string;
    url_is_superuser: string;
}

export const VAR_CONFIG: VarsConfig = {
  url_home_dev: 'http://localhost:4200/',
  url_home_prod: 'https://www.musycgo.com/',

  url_t_s: 'https://api.musycgo.com/api/access_t_s',
  url_n_t_s: 'https://api.musycgo.com/api/new_access_t_s',
  url_api_music: 'https://api.musycgo.com/api/musics',
  url_api_user: 'https://api.musycgo.com/api/users',
  url_api_places: 'https://api.musycgo.com/api/places_musics',
  url_datetime_server: 'https://api.musycgo.com/api/datetime',
  url_is_superuser: 'https://api.musycgo.com/api/rootuser',

/*   url_t_s: 'http://localhost:3000/api/access_t_s',
  url_n_t_s: 'http://localhost:3000/api/new_access_t_s',
  url_api_music: 'http://localhost:3000/api/musics',
  url_api_user: 'http://localhost:3000/api/users',
  url_api_places: 'http://localhost:3000/api/places_musics',
  url_datetime_server: 'http://localhost:3000/api/datetime',
  url_is_superuser: 'http://localhost:3000/api/rootuser', */
};
