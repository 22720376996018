import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  transform(value: number, args?: any): any {
    const to_km = value / 1000;
    return Math.round(to_km * 10) / 10;
  }
}
