import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'repetitionsong',
})
export class RepetitionsongPipe implements PipeTransform {
  transform(value: any, current_lang: string): any {
    let repetition = '';
    if (current_lang === 'es') {
      if (
        value
          .toString()
          .trim()
          .toString() === '1'
      ) {
        // repetition = value.toString().trim().trim()  + " vez";
        return repetition;
      }
      repetition = `(${value.toString().trim()} veces)`;
      return repetition;
    } if (current_lang === 'en') {
      if (value.toString().trim() === '1') {
        // repetition = value.toString().trim() + " time";
        return repetition;
      }
      repetition = `(${value.toString().trim()} times)`;
      return repetition;
    } if (current_lang === 'pt') {
      if (value.toString().trim() === '1') {
        // repetition = value.toString().trim() + " vez";
        return repetition;
      }
      repetition = `(${value.toString().trim()} vezes)`;
      return repetition;
    }
    if (value.toString().trim() === '1') {
      // repetition = value.toString().trim() + " vez";
      return repetition;
    }
    repetition = `(${value.toString().trim()} veces)`;
    return repetition;
  }
}
