import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import * as auth0 from 'auth0-js';
import swal from 'sweetalert';
import { Subject, Observable } from 'rxjs';
import { VAR_CONFIG } from '../../services/vars';
import { AUTH_CONFIG } from './auth0-variables';

declare var gtag: Function;

@Injectable()
export class AuthService {
  private _idToken: string;

  private _accessToken: string;

  private _expiresAt: number;

  readonly URL = VAR_CONFIG.url_is_superuser;

  auth0 = new auth0.WebAuth({
    clientID: AUTH_CONFIG.clientID,
    domain: AUTH_CONFIG.domain,
    responseType: 'token id_token',
    redirectUri: AUTH_CONFIG.callbackURL,
    // redirectUri: 'http://.../popup_response_handler.html',
    scope: 'openid profile',
    _disableDeprecationWarnings: true
  });

  userProfile: any;

  superuser: any;

  private profile = new Subject<any>();

  result: any;

  constructor(public router: Router, private http?: HttpClient) {
    this.getProfile((err, profile) => { });
  }

  public success(message: any) {
    if (message === undefined) {
      const expiresAt = (this.result.expiresIn * 1000) + Date.now();
      this._accessToken = this.result.accessToken;
      this._idToken = this.result.idToken;
      this._expiresAt = expiresAt;
      localStorage.setItem('_idToken', this._idToken);
      localStorage.setItem('_accessToken', this._accessToken);
      localStorage.setItem('_expiresAt', this._expiresAt.toString());
    }
    this.profile.next({ type: 'success', var: message });
  }

  public error(message: any) {
    this.profile.next({ type: 'error', var: message });
  }

  public getVar(): Observable<any> {
    this.getProfile((err, profile) => { });
    return this.profile.asObservable();
  }

  isSuperUser(id: string) {
    return this.http.post(`${this.URL}`, { _id: id })
      .map((res) => res);
  }

  setSuperUser(id: string) {
    this.isSuperUser(id).subscribe((res) => {
      this.superuser = res;
    });
  }

  get accessToken(): string {
    return this._accessToken;
  }

  get idToken(): string {
    return this._idToken;
  }

  public login(): void {
    if (window.location.href !== VAR_CONFIG.url_home_dev && window.location.href !== VAR_CONFIG.url_home_prod) {
      localStorage.setItem('last_url', window.location.href);
    }
    this.auth0.authorize();
  }

  public logout(): void {
    this.result = null;
    this._idToken = '';
    this._accessToken = '';
    this._expiresAt = 0;
    this.superuser = null;
    localStorage.removeItem('_idToken');
    localStorage.removeItem('_accessToken');
    localStorage.removeItem('_expiresAt');
    localStorage.removeItem('last_url');
    sessionStorage.removeItem('_auth');
    this.auth0.logout({ returnTo: window.location.origin });
  }

  private localLogin(authResult): void {
    this.result = authResult;
    const expiresAt = (authResult.expiresIn * 1000) + Date.now();
    this._accessToken = authResult.accessToken;
    this._idToken = authResult.idToken;
    this._expiresAt = expiresAt;
    localStorage.setItem('_idToken', this._idToken);
    localStorage.setItem('_accessToken', this._accessToken);
    localStorage.setItem('_expiresAt', this._expiresAt.toString());
    if (authResult.accessToken !== null) {
      this.auth0.client.userInfo(this._accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          this.setSuperUser(profile.sub);
          this.success(this.userProfile);
          window.location.hash = '';
          gtag('event', this.userProfile.nickname, { 'event_label': 'userID' });
          gtag('set', { 'user_id': this.userProfile.nickname });
        }
      });
    }
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      this.userProfile = authResult ? authResult.idTokenPayload : [];
      if (err) {
        this.getHashData();
        // this.router.navigate(['/']);
        // console.log(err);  //error de token invalido  
        // swal(`Error: ${err.error}. Check the console for further details.`);
      } else if (authResult) {
        sessionStorage.setItem('_auth', JSON.stringify(authResult));
        this.userProfile = authResult.idTokenPayload;
        this.localLogin(authResult);
      } else {
        this.getHashData();
      }
    });
  }

  public getHashData() {
    var hash = window.location.hash.substring(1);
    var params: any = {};
    hash.split('&').map(h => {
      let temp = h.split('=');
      params[temp[0]] = temp[1];
    });
    if (params.access_token) {
      this.result = params;
      const expiresAt = (parseInt(params.expires_in) * 1000) + Date.now();
      this._accessToken = params.access_token;
      this._idToken = params.id_token;
      this._expiresAt = expiresAt;
      localStorage.setItem('_idToken', this._idToken);
      localStorage.setItem('_accessToken', this._accessToken);
      localStorage.setItem('_expiresAt', this._expiresAt.toString());
      this.auth0.client.userInfo(this._accessToken, (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          this.setSuperUser(profile.sub);
          this.success(this.userProfile);
          window.location.hash = '';
          gtag('event', this.userProfile.nickname, { 'event_label': 'userID' });
          gtag('set', { 'user_id': this.userProfile.nickname });
        }
      });
    }
  }

  public getProfile(cb): void {
    if (!localStorage.getItem('_accessToken')) {
      // console.log('Access token must exist to fetch profile');
      // throw new Error('Access token must exist to fetch profile');
    } else {
      this.auth0.client.userInfo(localStorage.getItem('_accessToken'), (err, profile) => {
        if (profile) {
          this.userProfile = profile;
          this.setSuperUser(profile.sub);
          this.success(this.userProfile);
        }
        cb(err, profile);
      });
    }
  }

  public renewTokens(): void {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.localLogin(authResult);
      } else if (err) {
        // swal(`Could not get a new token (${err.error}: ${err.error_description}).`);
        // this.logout();
      }
    });
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    let _accessToken = this._accessToken ? this._accessToken : localStorage.getItem('_accessToken');
    let _expiresAt = this._expiresAt ? this._expiresAt : localStorage.getItem('_expiresAt');
    return _accessToken && Date.now() < _expiresAt;
  }
}
