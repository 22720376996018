import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Http, Headers } from '@angular/http';

@Injectable({ providedIn: 'root' })

export class SpotifyService {
  private searchUrl: string;

  private artistUrl: string;

  private albumsUrl: string;

  private albumUrl: string;

  public token_local: string;

  constructor(private http: Http) {
    // do nothing.
  }

  getInfoTrackById(ID:string) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token_s')}`);
    this.searchUrl = `https://api.spotify.com/v1/tracks/${ID}`;
    return this.http.get(this.searchUrl, { headers })
      .map((res) => res.json());
  }

  searchTrack(str:string, type = 'track') {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token_s')}`);
    this.searchUrl = `https://api.spotify.com/v1/search?q=${str}&offset=0&limit=50&type=${type}&market=CL`;
    return this.http.get(this.searchUrl, { headers })
      .map((res) => res.json());
  }

  searchIdTrack(str:string, type = 'track') {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token_s')}`);
    this.searchUrl = `https://api.spotify.com/v1/search?q=${str}&offset=0&limit=1&type=${type}&market=CL`;
    return this.http.get(this.searchUrl, { headers })
      .map((res) => res.json());
  }

  searchMusic(str:string, type = 'artist') {
    this.token_local = localStorage.getItem('token_s');
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${this.token_local}`);
    this.searchUrl = `https://api.spotify.com/v1/search?q=${str}&offset=0&limit=20&type=${type}&market=CL`;
    return this.http.get(this.searchUrl, { headers })
      .map((res) => res.json());
  }

  getArtist(id:string) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token_s')}`);
    this.artistUrl = `https://api.spotify.com/v1/artists/${id}`;
    return this.http.get(this.artistUrl, { headers })
      .map((res) => res.json());
  }

  getAlbums(artistId:string) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token_s')}`);
    this.albumsUrl = `https://api.spotify.com/v1/artists/${artistId}/albums`;
    return this.http.get(this.albumsUrl, { headers })
      .map((res) => res.json());
  }

  getAlbum(id:string) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${localStorage.getItem('token_s')}`);
    this.albumUrl = `https://api.spotify.com/v1/albums/${id}`;
    return this.http.get(this.albumUrl, { headers })
      .map((res) => res.json());
  }
}
