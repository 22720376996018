import { Pipe, PipeTransform } from '@angular/core';
// import { DATA_COUNTRY } from '../../assets/country';
import { DATA_COUNTRY_V2 } from '../../assets/countriesV2';

@Pipe({
  name: 'getflag',
})
export class GetflagPipe implements PipeTransform {
  transform(short_name: string): any {
    let flag = '';
    DATA_COUNTRY_V2.data.forEach((country) => {
      if (country.cca2 === short_name) {
        flag = "/assets/flags/" + country.flags.local;
        return flag;
      }
    });
    return flag;
  }
}
