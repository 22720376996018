import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user.service';
import { MusicService } from '../../services/music.service';
import { MarkerService } from '../../services/marker.service';
import 'materialize-css';
import { Subscription } from 'rxjs';

const script = require('../../../assets/scripts.js');

declare let $: any;

@Component({
  selector: 'app-userlogged',
  templateUrl: './userlogged.component.html',
  styleUrls: ['./userlogged.component.css'],
  providers: [UserService, MarkerService],
})
export class UserloggedComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  profile: any;

  data_user: any;

  num_collaboration: any;

  num_favorite: any;

  favorite: any;

  collaboration: any;

  trans03: any;

  trans04: any;

  trans05: any;

  trans23: any;

  trans24: any;

  stateNoti: boolean = false;

  dtOptions: DataTables.Settings = {};

  search_out: Array<any>;

  forChangeState: any;

  issuperuser: any;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private musicService: MusicService,
    private markerService: MarkerService,
    public translate: TranslateService,
  ) { }

  esLang() {
    if (this.translate.currentLang === 'es') {
      this.translate.get('Español es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      localStorage.setItem('lang', 'es');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      );
      this.translate.use('es');
      this.translateTitles();
      $('#esL').css('font-weight', 'bold');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada.').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  ptLang() {
    if (this.translate.currentLang === 'pt') {
      this.translate.get('Portugués es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      localStorage.setItem('lang', 'pt');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Portuguese-Brasil.json',
      );
      this.translate.use('pt');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', 'bold');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada.').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  enLang() {
    if (this.translate.currentLang === 'en') {
      this.translate.get('Inglés es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      localStorage.setItem('lang', 'en');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/English.json',
      );
      this.translate.use('en');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', 'bold');
      this.translate.get('Traducción aplicada.').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  openCollapsible() {
    $('.collapsible').collapsible('open');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    $('#loginuser').css('display', 'none');
    $('.collapsible').collapsible();
    $('.collapsible').collapsible('open');
    setTimeout(() => {
      if (localStorage.getItem('lang') === 'es') {
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      } else if (localStorage.getItem('lang') === 'en') {
        this.translate.use('en');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', 'bold');
      } else if (localStorage.getItem('lang') === 'pt') {
        this.translate.use('pt');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', 'bold');
        $('#engL').css('font-weight', '200');
      } else {
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      }
      setTimeout(() => {
        if (this.profile !== undefined) {
          this.saveUser();
          this.loadSuperUser();
        } else {
          $('#loginuser').css('display', 'block');
        }
      }, 1000);
    }, 2000);
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            window.document.title = `MusycGo - ${this.profile.nickname}`;
            this.saveUser();
            this.loadSuperUser();
            break;
          case 'error':
            break;
        }
      }
    });
    this.dtOptions = {
      search: {
        search: '',
      },
      autoWidth: true,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'TODO']],
      pageLength: 20,
      paging: true,
      ordering: true,
      info: true,
      language: { url: localStorage.getItem('lang_dt') || '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json' },
      dom: '<f<t>ip>',
      order: [[0, 'asc']],
      columnDefs: [
        {
          targets: '_all',
          className: 'dt-body-center',
        },
      ],
    };
  }

  loadSuperUser() {
    this.issuperuser = this.auth.superuser;
    if (this.issuperuser) {
      this.searchDB();
    }
  }


  saveUser() {
    let data_new_user = [];
    data_new_user = [
      {
        sub: this.profile.sub,
        given_name: this.profile.given_name,
        family_name: this.profile.family_name,
        nickname: this.profile.nickname,
        name: this.profile.name,
        picture: this.profile.picture,
        contributions: { data: '' },
        notifications: [],
        favorite: [],
        date: new Date().toString(),
        qualification: [],
      },
    ];

    this.userService.ifExist(this.profile.sub).subscribe((res) => {
      const r: any = res;
      if (r === null || r.length === 0) {
        this.userService.postUser(data_new_user[0]).subscribe((res) => { });
      }
      this.data_user = res;
      this.musicService.getByIdUser(this.profile.sub).subscribe((res) => {
        this.collaboration = res;
        this.num_collaboration = Object.keys(res).length;
        let cont = 0;
        const array_fav = [];
        for (let index = 0; index < this.data_user.favorite.length; index++) {
          const element = this.data_user.favorite[index];
          this.musicService.getMusicById(element.id).subscribe((res_) => {
            let resFav = null;
            resFav = res_;
            if (resFav.state) {
              array_fav.push(resFav);
              cont++;
            }
            if (this.data_user.favorite.length === index + 1) {
              this.num_favorite = cont;
              this.favorite = array_fav;
            }
          });
        }
      });
    });
  }

  removeSong(item) {
    let txt = '¿Aceptas la eliminación permanente?';
    let btn_1 = 'Cancelar';
    let btn_2 = 'Eliminar';
    this.translate.get(txt).subscribe((res) => {
      txt = res;
    });
    this.translate.get(btn_1).subscribe((res) => {
      btn_1 = res;
    });
    this.translate.get(btn_2).subscribe((res) => {
      btn_2 = res;
    });
    swal({
      text: txt,
      icon: 'warning',
      buttons: [btn_1, btn_2],
    }).then((value) => {
      if (value) {
        const update_state = [
          {
            _id: item._id,
            name: item.name,
            artist: item.artist,
            gender: item.gender,
            language: item.language,
            lyric: item.lyric,
            reason: item.reason,
            justification: item.justification,
            look: item.look,
            hear: item.hear,
            favorite: item.favorite,
            lat: item.lat,
            long: item.long,
            place: item.place,
            country: item.country,
            state: false,
            info: item.info,
            id_user: item.id_user,
            link_spotify: item.link_spotify,
            link_youtube: item.link_youtube,
            link_soundcloud: item.link_soundcloud,
            date: item.date,
          },
        ];
        this.musicService.putMusic(update_state[0]).subscribe((res) => {
          this.userService.ifExist(this.profile.sub).subscribe((res) => {
            this.data_user = res;
            this.musicService.getByIdUser(this.profile.sub).subscribe((res) => {
              this.collaboration = res;
              this.num_collaboration = Object.keys(res).length;
            });
          });
          this.translate
            .get('Operación realizada correctamente!')
            .subscribe((res) => {
              this.translate.get('Listo!').subscribe((title) => {
                swal(title, res, 'success');
              });
            });
          localStorage.removeItem('markers');
          this.markerService.init();
        });
      } else {
        this.translate.get('Operación cancelada!').subscribe((res) => {
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'error');
          });
        });
      }
    });
  }

  setMusicProfile(music: any) {
    this.musicService.setMusicSelected(music);
  }

  translateTitles() {
    this.translate.get('Volver').subscribe((res) => {
      this.trans23 = res;
      $('#trans23').attr('title', this.trans23);
    });
    this.translate.get('Cambiar al Idioma Español').subscribe((res) => {
      this.trans03 = res;
      $('#trans03').attr('title', this.trans03);
    });
    this.translate.get('Cambiar al Idioma Inglés').subscribe((res) => {
      this.trans04 = res;
      $('#trans04').attr('title', this.trans04);
    });
    this.translate.get('Cambiar al Idioma Portugués').subscribe((res) => {
      this.trans05 = res;
      $('#trans05').attr('title', this.trans05);
    });
    this.translate.get('Cerrar Sesión').subscribe((res) => {
      this.trans24 = res;
      $('#trans24').attr('title', this.trans24);
    });
  }

  setStateNoti(state) {
    this.stateNoti = state;
  }

  loadCollap() {
    $('.collapsible').collapsible();
  }

  searchDB() {
    this.issuperuser = this.auth.superuser;
    this.search_out = [];
    let search: string;
    search = '';
    this.musicService.getMusicsNotVisible()
      .subscribe((res) => {
        for (const name in res) {
          if (res.hasOwnProperty(name)) {
            const element = res[name];
            if (element.name.toLowerCase().indexOf(search) > -1 && element.state === false) {
              this.search_out.push(element);
            }
          }
        }
      });
  }

  infoDataJson(item) {
    this.forChangeState = item;
    const textoFormateado = JSON.stringify(item, undefined, 2);
    $('#divJson').html(`<pre>${textoFormateado}</pre>`);
  }

  closeModal() {
    $('#modal_data').modal('close');
  }

  changeState() {
    let txt = '¿Aceptas la modificación?';
    let btn_1 = 'Cancelar';
    let btn_2 = 'Continuar';
    this.translate.get(txt).subscribe((res) => {
      txt = res;
    });
    this.translate.get(btn_1).subscribe((res) => {
      btn_1 = res;
    });
    this.translate.get(btn_2).subscribe((res) => {
      btn_2 = res;
    });
    swal({
      text: txt,
      icon: 'warning',
      buttons: [btn_1, btn_2],
    }).then((value) => {
      if (value) {
        const for_update = [
          {
            _id: this.forChangeState._id,
            name: this.forChangeState.name,
            artist: this.forChangeState.artist,
            gender: this.forChangeState.gender,
            language: this.forChangeState.language,
            lyric: this.forChangeState.lyric,
            reason: this.forChangeState.reason,
            justification: this.forChangeState.justification,
            look: this.forChangeState.look,
            hear: this.forChangeState.hear,
            favorite: this.forChangeState.favorite,
            lat: this.forChangeState.lat,
            long: this.forChangeState.long,
            place: this.forChangeState.place,
            country: this.forChangeState.country,
            state: true,
            info: this.forChangeState.info,
            id_user: this.forChangeState.id_user,
            link_spotify: this.forChangeState.link_spotify,
            link_youtube: this.forChangeState.link_youtube,
            link_soundcloud: this.forChangeState.link_soundcloud,
            date: this.forChangeState.date,
          },
        ];
        this.musicService.putMusic(for_update[0])
          .subscribe(
            (res) => {
              let txt = '¿Visitar perfil de la canción activada?';
              let btn_1 = 'Cancelar';
              let btn_2 = 'Ir al perfil';
              this.translate.get(txt).subscribe((res) => {
                txt = res;
              });
              this.translate.get(btn_1).subscribe((res) => {
                btn_1 = res;
              });
              this.translate.get(btn_2).subscribe((res) => {
                btn_2 = res;
              });
              swal({
                title: txt,
                text: `www.musycgo.com/place/${this.forChangeState._id}`,
                icon: 'warning',
                buttons: [btn_1, btn_2],
              }).then((value) => {
                $('.modal').modal('close');
                if (value) {
                  window.open(`/place/${this.forChangeState._id}`);
                  this.forChangeState = null;
                }
              });
              this.searchDB();
            },
          );
      } else {
        this.translate.get('Operación cancelada!').subscribe((res) => {
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'error');
          });
        });
      }
    });
  }
}
