import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { MusicService } from '../../../services/music.service';
import { AppMapComponent } from '../../../app-map/app-map.component';
import { SpotifyService } from '../../../services/spotify.service';
import { VAR_CONFIG } from '../../../services/vars';
import 'materialize-css';
import { Subject } from 'rxjs';

declare const google: any;
declare let $: any;

@Component({
  selector: 'app-search-song',
  templateUrl: './search-song.component.html',
  styleUrls: ['./search-song.component.css'],
})
export class SearchSongComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  search_in: string;

  search_out: Array<any>;

  map: any;

  missReason: boolean;

  missJustification: boolean;

  missLyric: boolean;

  missYT: boolean;

  missSC: boolean;

  missLanguage: boolean;

  private _reloadDT: boolean;

  @Input()
  set reloadDT(value: boolean) {
    value = value || false;
    if (value === true) {
      this._reloadDT = value;
      this.searchSongDB();
    }
  }

  selectedSongMissInfo: any = {
    _id: '',
    justification:
    [
      {
        justification: '',
        id_user: '',
        date: '',
      },
    ],
    link_youtube: '',
    link_soundcloud: '',
    lyric: '',
  };

  img_music: any;

  constructor(private http: HttpClient, private musicService: MusicService, private spotifyService: SpotifyService, private appMapComponent: AppMapComponent, public translate: TranslateService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.map = this.appMapComponent.map_;
    this.dtOptions = {
      // "responsive": true,
      search: {
        search: '',
      },
      autoWidth: true,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'TODO']],
      pageLength: 20,
      paging: true,
      ordering: true,
      info: true,
      language: { url: localStorage.getItem('lang_dt') || '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json' },
      // "dom": 'bifrtp',
      dom: '<f<t>ip>',
      order: [[0, 'asc']],
      columnDefs: [
        {
          targets: '_all',
          className: 'dt-body-center',
        },
      ],
    };
    this.searchSongDB();
  }

  searchSongDB() {
    this.search_out = [];
    let search: string;
    search = ''; // muestra todos los resultados
    this.musicService.getMusics()
      .subscribe((res) => {
        for (const name in res) {
          if (res.hasOwnProperty(name)) {
            const element = res[name];
            if (element.name.toLowerCase().indexOf(search) > -1 && element.state === true) {
              this.search_out.push(element);
            }
          }
        }
      });
  }

  selectedSongTable(id: string, la: number, lo: number) {
    this.translate.get('En caso de que NO se muestre el marcador, verificar los filtros del mapa.').subscribe((res) => {
      var toastHTML = "<span style='font-size: 0.9em;'>"+res.toString()+"</span>";
      M.toast({ html: toastHTML, displayLength: 3000, classes: 'rounded' });
    });
    this.appMapComponent.lat = Number(la) + (0.0000000000100 * Math.random());
    this.appMapComponent.lng = Number(lo) + (0.0000000000100 * Math.random());
    this.appMapComponent.zoom = Number(16);
  }

  missingInfo(item: any) {
    this.selectedSongMissInfo = item;

    this.missReason = false;
    this.missJustification = false;
    this.missLyric = false;
    this.missYT = false;
    this.missSC = false;
    this.missLanguage = false;

    if (this.selectedSongMissInfo.reason === null || this.selectedSongMissInfo.reason === undefined || this.selectedSongMissInfo.reason.length === 0) {
      this.missReason = true;
    }
    if (this.selectedSongMissInfo.justification[this.selectedSongMissInfo.justification.length - 1].justification.trim() === '' || this.selectedSongMissInfo.justification.length === 0) {
      this.missJustification = true;
    }
    if (this.selectedSongMissInfo.lyric.trim() === '') {
      this.missLyric = true;
    }
    if (this.selectedSongMissInfo.link_youtube.trim() === '') {
      this.missYT = true;
    }
    if (this.selectedSongMissInfo.link_soundcloud === null || this.selectedSongMissInfo.link_soundcloud === undefined || this.selectedSongMissInfo.link_soundcloud === '') {
      this.missSC = true;
    }
    if (this.selectedSongMissInfo.language === '-' || this.selectedSongMissInfo.language === '' || this.selectedSongMissInfo.language[0] === '-' || this.selectedSongMissInfo.language.length === 0 || this.selectedSongMissInfo.language[0] === '') {
      this.missLanguage = true;
    }

    this.img_music = 'https://www.musycgo.com/icon/marker.png';
    if (this.selectedSongMissInfo.link_spotify !== undefined && this.selectedSongMissInfo.link_spotify !== '') {
      let id_link_spotify = this.selectedSongMissInfo.link_spotify.split('/album/')[1];
      if (this.selectedSongMissInfo.link_spotify.split('/album/').length <= 1) {
        id_link_spotify = this.selectedSongMissInfo.link_spotify.split('/track/')[1];
      }
      if (
        id_link_spotify !== null
        && id_link_spotify !== '-'
        && id_link_spotify !== ''
      ) {
        if (id_link_spotify.indexOf('?') !== -1) {
          id_link_spotify = id_link_spotify.substr(
            0,
            id_link_spotify.indexOf('?'),
          );
        }
        this.spotifyService.getInfoTrackById(id_link_spotify).subscribe(
          (res) => {
            this.img_music = res.album.images[1].url;
            this.cd.detectChanges();
          },
          (error) => {
            this.img_music = 'https://www.musycgo.com/icon/marker.png';

            this.http.get(VAR_CONFIG.url_n_t_s).subscribe(async (data) => {
              const var_body: any = data;
              await localStorage.setItem('token_s', var_body.body.access_token);
            });
          },
        );
      } else {
        this.img_music = 'https://www.musycgo.com/icon/marker.png';
      }
    }
  }

  closeModal() {
    $('#modal_complete_data').modal('close');
  }
}
