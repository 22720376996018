import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site404',
  templateUrl: './site404.component.html',
  styleUrls: ['./site404.component.css'],
})
export class Site404Component implements OnInit {
  constructor() { }

  ngOnInit() {
    window.document.title = 'MusycGo - 404';
  }
}
