import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { UserService } from '../../../services/user.service';

declare let $: any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  @Output() notificationsList = new EventEmitter();

  @Output() stateNotifications = new EventEmitter();

  profile: any;

  currentUser: any;

  notifications: any;

  noView: boolean;

  existChanges: boolean = false;

  invertNum: any[];

  constructor(
    private auth: AuthService,
    public translate: TranslateService,
    private user: UserService,
  ) { }

  onNotificationsList() {
    this.notificationsList.emit(this.notifications);
  }

  checkStatusNoti() {
    this.stateNotifications.emit(this.noView);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            this.getUser();
            break;
          case 'error':
            break;
        }
      }
    });
    $('.collapsible').collapsible();
    $('#modal_notifications').modal();
    $('.modal').modal({ opacity: 0.8 });
  }

  getUser() {
    this.invertNum = [];
    if (this.profile !== undefined) {
      this.user.getUsersFromAnAPI([this.profile.sub]).subscribe((response) => {
        this.currentUser = response;
        this.notifications = response.notifications;
        this.notifications.sort((a, b) => {
          a = new Date(a.date);
          b = new Date(b.date);
          return a > b ? -1 : a < b ? 1 : 0;
        });

        this.noView = false;
        for (let index = 0; index < this.notifications.length; index++) {
          const element = this.notifications[index];
          this.invertNum.push(this.notifications.length - index);
          if (element.active === true) {
            this.noView = true;
          }
        }
        if (this.notifications !== null && this.notifications !== undefined) {
          if (this.notifications.length > 0) {
            this.existChanges = true;
          }
        }
        this.checkStatusNoti();
        this.onNotificationsList();
      });
    }
  }

  allViews() {
    const copyNotifications = this.notifications;
    for (let index = 0; index < this.notifications.length; index++) {
      const element = this.notifications[index];
      if (element.active === true) {
        copyNotifications[index].active = false;
      }
    }
    const upd_notif = [
      {
        _id: this.currentUser._id,
        sub: this.currentUser.sub,
        picture: this.currentUser.picture,
        nickname: this.currentUser.nickname,
        name: this.currentUser.name,
        given_name: this.currentUser.given_name,
        favorite: this.currentUser.favorite,
        family_name: this.currentUser.family_name,
        contributions: this.currentUser.contributions,
        notifications: copyNotifications,
        date: this.currentUser.date,
        qualification: this.currentUser.qualification,
      },
    ];
    this.user.putUser(upd_notif[0]).subscribe((res) => {
      this.getUser();
    });
  }

  closeModal() {
    $('#modal_notifications').modal('close');
    $('.modal').modal('close');
  }

  updateNotifi() {
    this.auth.getProfile((res) => { });
  }

  openItem(item, listIndex) {
    const copyNotifications = this.notifications;
    let continuee = false;
    for (let index = 0; index < this.notifications.length; index++) {
      const element = this.notifications[index];
      if (element.active === true && element.id_song === item.id_song && element.date === item.date) {
        copyNotifications[index].active = false;
        continuee = true;
      }
    }
    if (continuee) {
      const upd_notif = [
        {
          _id: this.currentUser._id,
          sub: this.currentUser.sub,
          picture: this.currentUser.picture,
          nickname: this.currentUser.nickname,
          name: this.currentUser.name,
          given_name: this.currentUser.given_name,
          favorite: this.currentUser.favorite,
          family_name: this.currentUser.family_name,
          contributions: this.currentUser.contributions,
          notifications: copyNotifications,
          date: this.currentUser.date,
          qualification: this.currentUser.qualification,
        },
      ];
      this.user.putUser(upd_notif[0]).subscribe((res) => {
        this.getUser();
        setTimeout(() => {
          $('.listNotifi').collapsible('open', listIndex);
        }, 100);
      });
    }
  }
}
