import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
  ViewChild,
  ElementRef,
  Input,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import 'rxjs/add/operator/map';
import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
import { VAR_CONFIG } from '../../services/vars';
import { SpotifyService } from '../../services/spotify.service';
import { AuthService } from '../../services/auth/auth.service';
import { MusicService } from '../../services/music.service';

declare let $: any;
declare const google: any;

@Component({
  selector: 'app-input-song',
  templateUrl: './input-song.component.html',
  styleUrls: ['./input-song.component.css'],
  providers: [MusicService],
})
export class InputSongComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  profile: any;

  @ViewChild('search') public searchElement: ElementRef;

  @ViewChild('search_f') public searchElement_f: ElementRef;

  @Output() update_markers = new EventEmitter<any>();

  readyform: boolean = true;

  readyformS: boolean = true;

  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  map_input_coord: any;

  map_: any;

  map_s: any;

  @Input() click_lat: number;

  @Input() click_long: number;

  track_selected: string = '';

  lyric_selected: string = '';

  lat_: number = Number(-39.8142204);

  lng_: number = Number(-73.2458878);

  zoom: number = 5;

  click_lat_: number = 0;

  click_long_: number = 0;

  click_lat_s: number = 0;

  click_long_s: number = 0;

  name: string = '';

  artist: string = '';

  gender: any = [];

  lyric: string = '';

  lat: number = null;

  long: number = null;

  place: string = '';

  info: string = '';

  id_user: string = '';

  link_spotify: string = '';

  link_spotify_: string = '';

  link_youtube: string = '';

  link_soundcloud: string = '';

  state: boolean = true;

  date: string = new Date().toString();

  state_lyric: boolean = false;

  state_lyric_2: boolean = false;

  text_lyric: string;

  text_lyric_2: string;

  text_justification: string;

  text_justification_2: string;

  lat_s: number = null;

  long_s: number = null;

  link_spotify_s: string = '';

  myAnimation: any;

  lat_country: number;

  lng_country: number;

  name_country: string;

  cont = 1;

  contS = 1;

  coords = [];

  coordsS = [];

  data_song_by_url: any;

  infowindow_prev_open: any;

  infowindow_s_prev_open: any;

  private geoCoder;

  address: string;

  generos: any = [];

  song_modal: any;

  songEqualModal: any = [];

  songModal: any = [];

  img_music: string;

  song_equal_modal: any = [];

  name_song: string;

  name_artist: string;

  lat_long_update: any;

  dtOptionSong: DataTables.Settings = {};

  dtTriggerSong: Subject<any> = new Subject();

  search_in: string;

  search_out: Array<any>;

  dropdownList_reason = [];

  selectedItems_reasonS = [];

  selectedItems_reasonC = [];

  dropdownSettings = {};

  nameSongModal: string = '';

  artistSongModal: string = '';

  existSong: boolean = false;

  linkSpotValid: boolean = false;

  img_music_valid: string = '';

  name_song_valid: string = '';

  name_artist_valid: string = '';

  img_artist_valid: string = '';

  msj_not_valid: string = '';

  place_coords_s: string = '';

  place_coords: string = '';

  rowFirstPage: number;

  table: any;

  boolSongArtist: boolean = false;

  constructor(
    private zone: NgZone,
    private musicService: MusicService,
    private auth: AuthService,
    private spotifyService: SpotifyService,
    private mapsAPILoader: MapsAPILoader,
    private http: HttpClient,
    public translate: TranslateService,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService,
  ) { }

  searchSongDB() {
    this.search_out = [];
    let search: string;
    search = '';
    this.musicService.getMusics().subscribe((res) => {
      for (const name in res) {
        if (res.hasOwnProperty(name)) {
          const element = res[name];
          if (
            element.name.toLowerCase().indexOf(search) > -1
            && element.state === true
          ) {
            this.search_out.push(element);
          }
        }
      }
    });
  }

  selectedSongTable(item) {
    $('#link_spotify_').removeClass('valid');
    $('#link_spotify_').val('');
    $('#formlink').removeClass('active');
    $('#formlinkheader').removeClass('active');
    $('#formlinkbody').css('display', 'none');
    $('#name').removeClass('valid');
    $('#name').val('');
    $('#artist').removeClass('valid');
    $('#artist').val('');
    $('#formcomplete').removeClass('active');
    $('#formcomheader').removeClass('active');
    $('#formcombody').css('display', 'none');
    $('#lbl_link_spotify_').removeClass('active');
    $('#lbl_name').removeClass('active');
    $('#lbl_artist').removeClass('active');
    this.linkSpotValid = false;
    if (
      item.link_spotify !== null
      && item.link_spotify !== undefined
      && item.link_spotify !== ''
    ) {
      $('#link_spotify_').addClass('valid');
      $('#link_spotify_').val(String(item.link_spotify));
      $('#formlink').addClass('active');
      $('#lbl_link_spotify_').addClass('active');
      $('#formlinkheader').addClass('active');
      $('#formlinkbody').css('display', 'block');
      this.boolSongArtist = true;
    } else {
      $('#name').addClass('valid');
      $('#name').val(String(item.name));
      $('#artist').addClass('valid');
      $('#artist').val(String(item.artist));
      $('#lbl_name').addClass('active');
      $('#lbl_artist').addClass('active');
      $('#formcomplete').addClass('active');
      $('#formcomheader').addClass('active');
      $('#formcombody').css('display', 'block');
    }
    window.scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  matchTitleOrArtist(){
    //this.boolSongArtist = false;
    this.table.search(this.name_song_valid+" "+this.name_artist_valid).draw();
    let rowFirstPageFilter = $("#dt_full_add tbody tr td button").length;
    if(this.rowFirstPage !== rowFirstPageFilter && rowFirstPageFilter !== 0){
      //hay resultados de titulo+artista
      this.boolSongArtist = true;
    } 
    if (this.rowFirstPage !== rowFirstPageFilter && rowFirstPageFilter === 0){
      //no hay resultados de titulo+artista
      this.boolSongArtist = false;
      this.table.search("").draw();
    }
  }

  ngOnInit() {
    this.dtOptionSong = {
      search: {
        search: '',
      },
      autoWidth: true,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'TODO'],
      ],
      pageLength: 20,
      paging: true,
      ordering: true,
      info: true,
      language: {
        url:
          localStorage.getItem('lang_dt')
          || 'https://cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      },
      dom: '<f<t>p>',
      order: [[0, 'asc']],
      columnDefs: [
        {
          targets: '_all',
          className: 'dt-body-center',
        },
      ],
    };
    this.searchSongDB();
    $('#modal_exist_song').modal({
      dismissible: false,
    });
    $('.modal').modal({ opacity: 0.8 });
    this.dtOptions = {
      responsive: true,
      paging: false,
      ordering: false,
      info: false,
      language: {
        url: localStorage.getItem('lang_dt'),
      },
    };
    const that = this;
    $(document).ready(() => {
      $(
        '#link_spotify_, #lat_s, #long_s, #place_coords_s, #name, #artist, #lat, #long, #place_coords, #gender, #link_spotify, #link_youtube, #link_soundcloud',
      ).keydown((e) => {
        if (e.keyCode == 13) {
          e.preventDefault();
          return false;
        }
      });
      $('#buscador_s').focusin(() => {
        $(document).keydown((e) => {
          if (e.which == 13) {
            const firstResult = $('.pac-container .pac-item:first').text();
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: firstResult }, (
              results,
              status,
            ) => {
              if (status == google.maps.GeocoderStatus.OK) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                that.lat_ = lat;
                that.lng_ = lng;
                that.click_lat_s = lat;
                that.click_long_s = lng;
                that.click_lat_ = lat;
                that.click_long_ = lng;
                $('#place_coords_s').val(results[results.length - 1].formatted_address);
                $('#label_place_coords_s').addClass('active');
                $('#place_coords').val(results[results.length - 1].formatted_address);
                $('#label_place_coords').addClass('active');
                $('#lat_s').val(lat);
                $('#long_s').val(lng);
                $('#lbl_lat_s').addClass('active');
                $('#lbl_lng_s').addClass('active');
                that.recenterMap(
                  lat + 0.00000000001 * Math.random(),
                  lng + 0.00000000001 * Math.random(),
                );
                that.infowindow_s_prev_open._closeInfoWindow();
                setTimeout(() => {
                  that.infowindow_s_prev_open._openInfoWindow();
                }, 700);
                that.getAddress(lat, lng);
              }
            });
            e.preventDefault();
            return false;
          }
        });
      });
      $('#buscador_f').focusin(() => {
        $(document).keypress((e) => {
          if (e.which == 13) {
            const firstResult = $('.pac-container .pac-item:first').text();
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: firstResult }, (
              results,
              status,
            ) => {
              if (status == google.maps.GeocoderStatus.OK) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                that.lat_ = lat;
                that.lng_ = lng;
                that.click_lat_s = lat;
                that.click_long_s = lng;
                that.click_lat_ = lat;
                that.click_long_ = lng;
                $('#place_coords_s').val(results[results.length - 1].formatted_address);
                $('#label_place_coords_s').addClass('active');
                $('#place_coords').val(results[results.length - 1].formatted_address);
                $('#label_place_coords').addClass('active');
                $('#lat').val(lat);
                $('#long').val(lng);
                $('#lbl_lat').addClass('active');
                $('#lbl_lng').addClass('active');
                that.recenterMap(
                  lat + 0.00000000001 * Math.random(),
                  lng + 0.00000000001 * Math.random(),
                );
                that.infowindow_prev_open._closeInfoWindow();
                setTimeout(() => {
                  that.infowindow_prev_open._openInfoWindow();
                }, 1000);
                that.getAddress(lat, lng);
              }
            });
            e.preventDefault();
            return false;
          }
        });
      });
      // info link ingresado
      $('#link_spotify_').blur(() => {
        this.table = $('#dt_full_add').DataTable();
        this.table.search("").draw();
        this.rowFirstPage = $("#dt_full_add tbody tr td button").length;
        const link_spotify_f = $('#link_spotify_').val();
        $('#link_spotify_').removeClass('valid invalid');
        that.linkSpotValid = false;
        that.img_music_valid = '';
        that.name_song_valid = '';
        that.name_artist_valid = '';
        that.img_artist_valid = '';
        if (link_spotify_f.trim() !== '') {
          that.linkSpotValid = true;
          that.showSpinner('spinnerSpot');
          let l;
          if (link_spotify_f.indexOf('/album/') === -1) {
            if (link_spotify_f.indexOf('?') === -1) {
              l = link_spotify_f.substr(
                link_spotify_f.indexOf('/track/') + 7,
                link_spotify_f.length,
              );
            } else {
              l = link_spotify_f.substr(0, link_spotify_f.indexOf('?'));
              l = l.substr(l.indexOf('/track/') + 7, l.length);
            }
          } else {
            l = link_spotify_f.substr(
              link_spotify_f.indexOf('/album/') + 7,
              link_spotify_f.length,
            );
            if (l.indexOf('?') !== -1) {
              l = l.substr(0, l.indexOf('?'));
            }
          }
          that.spotifyService.getInfoTrackById(l).subscribe(
            (res) => {
              that.img_music_valid = res.album.images[1].url;
              that.name_song_valid = res.name;
              that.name_artist_valid = res.artists[0].name;
              this.matchTitleOrArtist();
              that.spotifyService.getArtist(res.artists[0].id).subscribe(
                (res) => {
                  that.hideSpinner('spinnerSpot');
                  that.img_artist_valid = res.images[0].url;
                  $('#link_spotify_').addClass('valid');
                  $('#link_spotify_').removeClass('invalid');
                },
                (error) => {
                  that.hideSpinner('spinnerSpot');
                  $('#link_spotify_').addClass('invalid');
                  $('#link_spotify_').removeClass('valid');
                },
              );
            },
            (error) => {
              that.hideSpinner('spinnerSpot');
              $('#link_spotify_').removeClass('valid');
              $('#link_spotify_').addClass('invalid');
              that.img_music_valid = '';
              that.name_song_valid = '';
              that.name_artist_valid = '';
              that.img_artist_valid = '';
              if (error.status === 400) {
                that.msj_not_valid = 'Enlace ingresado es incorrecto, por favor verificar';
              } else {
                that.msj_not_valid = 'Hubo un problema en el acceso a Spotify. Por favor, verifique que el enlace ingresado comienza así: https://open.spotify.com/track/ ...';
              }
              that.http.get(VAR_CONFIG.url_n_t_s).subscribe(async (data) => {
                const var_body: any = data;
                await localStorage.setItem('token_s', var_body.body.access_token);
              });
            },
          );
        }
      });
    });

    // PARA FORMULARIO COMPLETO
    this.mapsAPILoader.load().then(
      () => {
        this.geoCoder = new google.maps.Geocoder();
        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElement_f.nativeElement,
          {
            types: [], // aqui se elige entre las categorias de resultados  ['address'], ['establishment'], ['geocode'] o []
            // componentRestrictions: { country: "CL" } //restringimos a solo chile en las busquedas
          },
        );

        autocomplete.addListener('place_changed', () => {
          this.zone.run(() => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
            } else {
              this.lat_ = place.geometry.location.lat();
              this.lng_ = place.geometry.location.lng();
              this.click_lat_s = place.geometry.location.lat();
              this.click_long_s = place.geometry.location.lng();
              this.click_lat_ = place.geometry.location.lat();
              this.click_long_ = place.geometry.location.lng();
              $('#place_coords_s').val(place.formatted_address);
              $('#label_place_coords_s').addClass('active');
              $('#place_coords').val(place.formatted_address);
              $('#label_place_coords').addClass('active');
              $('#lat').val(place.geometry.location.lat());
              $('#long').val(place.geometry.location.lng());
              $('#lbl_lat').addClass('active');
              $('#lbl_lng').addClass('active');
              this.infowindow_prev_open._closeInfoWindow();
              setTimeout(() => {
                this.infowindow_prev_open._openInfoWindow();
              }, 1000);
              this.getAddress(this.lat_, this.lng_);
            }
          });
        });
      },
    );

    // PARA FORMULARIO CON LINK DE SPOTIFY
    this.mapsAPILoader.load().then(
      () => {
        this.geoCoder = new google.maps.Geocoder();
        const autocomplete = new google.maps.places.Autocomplete(
          this.searchElement.nativeElement,
          {
            types: [], // aqui se elige entre las categorias de resultados  ['address'], ['establishment'], ['geocode'] o []
            // componentRestrictions: { country: "CL" } //restringimos a solo chile en las busquedas
          },
        );

        autocomplete.addListener('place_changed', () => {
          this.zone.run(() => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
            } else {
              this.lat_ = place.geometry.location.lat();
              this.lng_ = place.geometry.location.lng();
              this.click_lat_s = place.geometry.location.lat();
              this.click_long_s = place.geometry.location.lng();
              this.click_lat_ = place.geometry.location.lat();
              this.click_long_ = place.geometry.location.lng();
              $('#place_coords_s').val(place.formatted_address);
              $('#label_place_coords_s').addClass('active');
              $('#place_coords').val(place.formatted_address);
              $('#label_place_coords').addClass('active');
              $('#lat_s').val(place.geometry.location.lat());
              $('#long_s').val(place.geometry.location.lng());
              $('#lbl_lat_s').addClass('active');
              $('#lbl_lng_s').addClass('active');
              this.recenterMap(
                this.lat_ + 0.00000000001 * Math.random(),
                this.lng_ + 0.00000000001 * Math.random(),
              );
              this.infowindow_s_prev_open._closeInfoWindow();
              setTimeout(() => {
                this.infowindow_s_prev_open._openInfoWindow();
              }, 700);
              this.getAddress(this.lat_, this.lng_);
            }
          });
        });
      },
    );
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            break;
          case 'error':
            break;
        }
      }
    });

    let buscar = 'Buscar';
    let noDate = 'No hay datos disponibles';
    let desAll = 'Deseleccionar todos los resultados';
    let selAll = 'Seleccionar todos los resultados';
    let desAll2 = 'Deseleccionar todo';
    let selAll2 = 'Seleccionar todo';
    let sel = 'Seleccione';
    this.translate.get(buscar).subscribe((res) => {
      buscar = res;
      this.translate.get(noDate).subscribe((res) => {
        noDate = res;
        this.translate.get(desAll).subscribe((res) => {
          desAll = res;
          this.translate.get(selAll).subscribe((res) => {
            selAll = res;
            this.translate.get(desAll2).subscribe((res) => {
              desAll2 = res;
              this.translate.get(selAll2).subscribe((res) => {
                selAll2 = res;
                this.translate.get(sel).subscribe((res) => {
                  sel = res;
                  this.dropdownSettings = {
                    singleSelection: false,
                    text: sel,
                    selectAllText: selAll2,
                    unSelectAllText: desAll2,
                    enableSearchFilter: false,
                    enableCheckAll: true,
                    filterSelectAllText: selAll,
                    filterUnSelectAllText: desAll,
                    badgeShowLimit: 1,
                    searchPlaceholderText: buscar,
                    labelKey: 'itemName',
                    primaryKey: 'id',
                    noDataLabel: noDate,
                    maxHeight: 800,
                    classes: 'change-color-dd',
                  };
                });
              });
            });
          });
        });
      });
    });

    this.translate.get('Mención en el título').subscribe((res1) => {
      this.dropdownList_reason.push({ id: '1', itemName: res1 });
      this.translate.get('Mención en la letra').subscribe((res2) => {
        this.dropdownList_reason.push({ id: '2', itemName: res2 });
        this.translate
          .get('Referencia a personalidad(es)/personaje(s) locales')
          .subscribe((res3) => {
            this.dropdownList_reason.push({ id: '3', itemName: res3 });
            this.translate
              .get('Referencia a acento/dialecto local')
              .subscribe((res4) => {
                this.dropdownList_reason.push({ id: '4', itemName: res4 });
                this.translate
                  .get('Referencia a comida local')
                  .subscribe((res5) => {
                    this.dropdownList_reason.push({ id: '5', itemName: res5 });
                    this.translate
                      .get('Referencia a costumbre local')
                      .subscribe((res6) => {
                        this.dropdownList_reason.push({
                          id: '6',
                          itemName: res6,
                        });
                        this.translate
                          .get('Referencia a vestimenta local')
                          .subscribe((res7) => {
                            this.dropdownList_reason.push({
                              id: '7',
                              itemName: res7,
                            });
                            this.translate
                              .get('Referencia a deporte local')
                              .subscribe((res8) => {
                                this.dropdownList_reason.push({
                                  id: '8',
                                  itemName: res8,
                                });
                                this.translate
                                  .get('Referencia a naturaleza local')
                                  .subscribe((res9) => {
                                    this.dropdownList_reason.push({
                                      id: '9',
                                      itemName: res9,
                                    });
                                    this.translate
                                      .get('Aparición en videoclip')
                                      .subscribe((res10) => {
                                        this.dropdownList_reason.push({
                                          id: '10',
                                          itemName: res10,
                                        });
                                        this.translate
                                          .get('Himno club local')
                                          .subscribe((res11) => {
                                            this.dropdownList_reason.push({
                                              id: '11',
                                              itemName: res11,
                                            });
                                            this.translate
                                              .get(
                                                'Himno político/administrativo (ciudad/estado/región/país)',
                                              )
                                              .subscribe((res11) => {
                                                this.dropdownList_reason.push({
                                                  id: '12',
                                                  itemName: res11,
                                                });
                                                this.translate
                                                  .get(
                                                    'Otra razón. (Explicar en la caja de justificación).',
                                                  )
                                                  .subscribe((res11) => {
                                                    this.dropdownList_reason.push(
                                                      {
                                                        id: '13',
                                                        itemName: res11,
                                                      },
                                                    );
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });
    });
  }

  beforeOpenWin(window: any, type: string) {
    if (type === 'c') {
      this.infowindow_prev_open = window;
    }
    if (type === 's') {
      this.infowindow_s_prev_open = window;
    }
  }

  onDeselectAll(items: any) {
    this.selectedItems_reasonS = [];
    this.selectedItems_reasonC = [];
  }

  recenterMap(lat, lng) {
    this.lat_ = Number(lat) + 0.00000000001 * Math.random();
    this.lng_ = Number(lng) + 0.00000000001 * Math.random();
    this.zoom = Number(14);
  }

  getAddress(latitude, longitude) {
    this.lat_country = 0;
    this.lng_country = 0;
    this.name_country = '';
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.address = results[0].formatted_address;
            $('#place_coords_s').val(results[0].formatted_address);
            $('#label_place_coords_s').addClass('active');
            $('#place_coords').val(results[0].formatted_address);
            $('#label_place_coords').addClass('active');
            this.lat_country = results[results.length - 1].geometry.location.lat();
            this.lng_country = results[results.length - 1].geometry.location.lng();
            this.name_country = results[results.length - 2].address_components[0].short_name;
          }
        }
      },
    );
  }

  mapClickedS($event: any, infoWindowS) {
    $('#place_coords_s').val(null);
    $('#label_place_coords_s').removeClass('active');
    this.click_lat_s = $event.coords.lat;
    this.click_long_s = $event.coords.lng;
    this.lat_s = this.click_lat_s;
    this.long_s = this.click_long_s;
    infoWindowS._openInfoWindow();
    this.getAddress(this.click_lat_s, this.click_long_s);
    $('#lbl_lat_s').addClass('active');
    $('#lbl_lng_s').addClass('active');
    this.infowindow_s_prev_open = infoWindowS;
    $('.c-btn').css('border', '');
    this.changeDetectorRef.detectChanges();
  }

  mapClicked_($event: any, infoWindow) {
    $('#place_coords').val(null);
    $('#label_place_coords').removeClass('active');
    this.click_lat_ = $event.coords.lat;
    this.click_long_ = $event.coords.lng;
    this.lat = this.click_lat_;
    this.long = this.click_long_;
    infoWindow._openInfoWindow();
    this.getAddress(this.click_lat_, this.click_long_);
    $('#lbl_lat').addClass('active');
    $('#lbl_lng').addClass('active');
    this.infowindow_prev_open = infoWindow;
    $('.c-btn').css('border', '');
    this.changeDetectorRef.detectChanges();
  }

  setTrackSelected(track) {
    this.track_selected = track;
  }

  setLyricSelected(lyric) {
    this.lyric_selected = lyric;
  }

  ngOnChanges(songForm: NgForm) { }

  mapDoneCoord(event: any) {
    this.myAnimation = 'BOUNCE';
    this.map_input_coord = event;
    this.map_ = event;
    this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
      document.getElementById('buscador_f'),
    );
  }

  mapDoneCoordS(event: any) {
    this.myAnimation = 'BOUNCE';
    this.map_input_coord = event;
    this.map_s = event;
    this.map_s.controls[google.maps.ControlPosition.TOP_LEFT].push(
      document.getElementById('buscador_s'),
    );
  }

  changeCoords(coord) {
    this.lat_ = coord.lat + 0.00000000001 * Math.random();
    this.lng_ = coord.long + 0.00000000001 * Math.random();
    this.click_lat_ = coord.lat;
    this.click_long_ = coord.long;
  }

  changeCoordsSp(coord) {
    this.lat_ = coord.lat + 0.00000000001 * Math.random();
    this.lng_ = coord.long + 0.00000000001 * Math.random();
    this.click_lat_s = coord.lat;
    this.click_long_s = coord.long;
  }

  confirmCoord() {
    let sigue: boolean = true;
    $('#place_coords').removeClass('invalid valid');
    $('.c-btn').css('border', '');
    $('.ui-widget.ui-editor-container.ui-corner-all').css('border', 'none');

    if (this.text_justification_2 !== undefined) {
      if (this.text_justification_2.trim() === '') {
        sigue = false;
        $('.ui-widget.ui-editor-container.ui-corner-all').css(
          'border',
          'solid 2px red',
        );
      }
    } else {
      sigue = false;
      $('.ui-widget.ui-editor-container.ui-corner-all').css(
        'border',
        'solid 2px red',
      );
    }

    if (
      $('#place_coords')
        .val()
        .trim() === ''
    ) {
      $('#place_coords').addClass('invalid');
      sigue = false;
    }

    if (this.selectedItems_reasonC.length === 0) {
      $('.c-btn').css('border', 'red solid 2px');
      sigue = false;
    }

    if (sigue) {
      const listNumReason = [];
      for (let index = 0; index < this.selectedItems_reasonC.length; index++) {
        const element = this.selectedItems_reasonC[index].id;
        listNumReason.push(element);
      }
      this.coords.push({
        n: this.cont,
        place: String($('#place_coords').val()),
        justification: this.text_justification_2,
        lat: this.click_lat_,
        lng: this.click_long_,
        country: {
          name: this.name_country,
          coord: {
            lat: this.lat_country,
            lng: this.lng_country,
          },
        },
        reason: listNumReason,
      });
      this.cont++;
      $('#place_coords').val('');
      $('#place_coords').removeClass('invalid valid');
      this.translate
        .get('Puede seguir ingresando coordenadas, si las hay')
        .subscribe((res) => {
          this.translate.get('Posición Ingresada!').subscribe((title) => {
            swal(title, res, 'info');
          });
        });
      this.text_justification_2 = '';
      this.selectedItems_reasonC = [];
      $('.c-btn').css('border', '');
    } else {
      this.translate.get('Campo requerido').subscribe((res) => {
        this.translate.get('Verificar').subscribe((title) => {
          swal(title, res, 'info');
        });
      });
    }
  }

  confirmCoordS() {
    let sigue: boolean = true;
    $('#place_coords_s').removeClass('invalid valid');
    $('.c-btn').css('border', '');
    $('.ui-widget.ui-editor-container.ui-corner-all').css('border', 'none');

    if (this.text_justification !== undefined) {
      if (this.text_justification.trim() === '') {
        sigue = false;
        $('.ui-widget.ui-editor-container.ui-corner-all').css(
          'border',
          'solid 2px red',
        );
      }
    } else {
      sigue = false;
      $('.ui-widget.ui-editor-container.ui-corner-all').css(
        'border',
        'solid 2px red',
      );
    }

    if (
      $('#place_coords_s')
        .val()
        .trim() === ''
    ) {
      $('#place_coords_s').addClass('invalid');
      sigue = false;
    }

    if (this.selectedItems_reasonS.length === 0) {
      $('.c-btn').css('border', 'red solid 2px');
      sigue = false;
    }

    if (sigue) {
      const listNumReason = [];
      for (let index = 0; index < this.selectedItems_reasonS.length; index++) {
        const element = this.selectedItems_reasonS[index].id;
        listNumReason.push(element);
      }
      this.coordsS.push({
        n: this.contS,
        place: String($('#place_coords_s').val()),
        justification: this.text_justification,
        lat: this.click_lat_s,
        lng: this.click_long_s,
        country: {
          name: this.name_country,
          coord: {
            lat: this.lat_country,
            lng: this.lng_country,
          },
        },
        reason: listNumReason,
      });
      this.contS++;
      $('#place_coords_s').val('');
      $('#place_coords_s').removeClass('invalid valid');
      this.translate
        .get('Puede seguir ingresando coordenadas, si las hay')
        .subscribe((res) => {
          this.translate.get('Posición Ingresada!').subscribe((title) => {
            swal(title, res, 'info');
          });
        });
      this.text_justification = '';
      this.selectedItems_reasonS = [];
      $('.c-btn').css('border', '');
    } else {
      this.translate.get('Campo requerido').subscribe((res) => {
        this.translate.get('Verificar').subscribe((title) => {
          swal(title, res, 'info');
        });
      });
    }
  }

  collapsibleForm() {
    setTimeout(() => {
      $('#place_coords').val(null);
      $('#label_place_coords').removeClass('active');
      $('#buscador_f').val(null);
      $('#place_coords').val(null);
      $('#lat').val(null);
      $('#long').val(null);
      $('#lbl_lat').removeClass('active');
      $('#lbl_lng').removeClass('active');
    }, 100);
  }

  collapsibleSpotify() {
    setTimeout(() => {
      $('#place_coords_s').val(null);
      $('#buscador_s').val(null);
      $('#lat_s').val(null);
      $('#long_s').val(null);
      $('#label_place_coords_s').removeClass('active');
      $('#link_spotify_').removeClass('active');
      $('#lbl_lat_s').removeClass('active');
      $('#lbl_lng_s').removeClass('active');
    }, 100);
  }

  deleteRow(index) {
    this.coords = this.coords.filter((f) => f.n !== index);
  }

  deleteRowS(index) {
    this.coordsS = this.coordsS.filter((f) => f.n !== index);
  }

  removeClassForm() {
    $('#name').removeClass('invalid valid');
    $('#gender').removeClass('invalid valid');
    $('#artist').removeClass('invalid valid');
    $('#lyric').removeClass('invalid valid');
    $('#lat').removeClass('invalid valid');
    $('#long').removeClass('invalid valid');
    $('#date').removeClass('invalid valid');
    $('#link_spotify_').removeClass('invalid valid');
    $('#lat_s').removeClass('invalid valid');
    $('#long_s').removeClass('invalid valid');
  }

  cleanSongEqualModal() {
    this.song_equal_modal = [];
    this.songEqualModal = [];
    this.songModal = [];
    this.hideSpinner('spinner1');
    this.hideSpinner('spinner2');
    this.translate.get('Actualizando Mapa...').subscribe((res) => {
      swal('', res, 'warning', { buttons: [false], timer: 2500 });
    });
    setTimeout(() => {
      this.zone.runOutsideAngular(() => {
        location.reload();
      });
    }, 2500);
  }

  cleanSongEqual() {
    this.song_equal_modal = [];
    this.songEqualModal = [];
    this.songModal = [];
    this.hideSpinner('spinner1');
    this.hideSpinner('spinner2');
    $('#btn_add').removeClass('disabled');
  }

  addSongByLink(form: NgForm) {
    this.showSpinner('spinner1');
    $('#btn_add_link').addClass('disabled');
    this.http.get(VAR_CONFIG.url_t_s).subscribe(async (data) => {
      const var_body: any = data;
      await localStorage.setItem('token_s', var_body.body.access_token);
    });
    this.img_music = '';
    this.readyformS = true;
    this.removeClassForm();
    if (
      $('#link_spotify_')
        .val()
        .trim() === ''
    ) {
      $('#link_spotify_').addClass('invalid');
      this.readyformS = false;
    } else {
      $('#link_spotify_').addClass('valid');
    }
    if (
      $('#lat_s')
        .val()
        .trim() === ''
    ) {
      $('#lat_s').addClass('invalid');
      this.readyformS = false;
    } else {
      $('#lat_s').addClass('valid');
    }
    if (
      $('#long_s')
        .val()
        .trim() === ''
    ) {
      $('#long_s').addClass('invalid');
      this.readyformS = false;
    } else {
      $('#long_s').addClass('valid');
    }
    if (this.coordsS.length === 0) {
      this.readyformS = false;
      this.translate
        .get('No ha ingresado correctamente la información en el mapa')
        .subscribe((res) => {
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'error');
          });
        });
    }
    let a_form = [];
    this.date = new Date().toString();

    if (this.readyformS === true) {
      const link_spotify_f = $('#link_spotify_').val();
      let l;
      if (link_spotify_f.indexOf('/album/') === -1) {
        if (link_spotify_f.indexOf('?') === -1) {
          l = link_spotify_f.substr(
            link_spotify_f.indexOf('/track/') + 7,
            link_spotify_f.length,
          );
        } else {
          l = link_spotify_f.substr(0, link_spotify_f.indexOf('?'));
          l = l.substr(l.indexOf('/track/') + 7, l.length);
        }
      } else {
        l = link_spotify_f.substr(
          link_spotify_f.indexOf('/album/') + 7,
          link_spotify_f.length,
        );
        if (l.indexOf('?') !== -1) {
          l = l.substr(0, l.indexOf('?'));
        }
      }
      this.spotifyService.getInfoTrackById(l).subscribe(
        (res) => {
          this.img_music = res.album.images[1].url;
          this.name_song = res.name;
          this.name_artist = res.artists[0].name;
          let id_user_f = 'NN';
          if (this.profile !== undefined) {
            id_user_f = this.profile.sub;
          }

          this.musicService.getByLinkSpotify(l).subscribe((res) => {
            this.song_modal = res;
            if (this.song_modal.length !== 0) {
              this.song_modal.forEach((places) => {
                this.coordsS.forEach((coords) => {
                  if (places.place === coords.place) {
                    this.song_equal_modal.push(places.place);
                  }
                });
              });
            }

            if (this.song_equal_modal.length === 0) {
              const url = $('#link_spotify_').val();
              const index = url.indexOf('track') + 6;
              const id_song = url.substr(index);
              this.spotifyService
                .getInfoTrackById(id_song)
                .subscribe((result) => {
                  this.data_song_by_url = result;
                  let artists = '';
                  // artistas
                  for (
                    let index = 0;
                    index < this.data_song_by_url.artists.length;
                    index++
                  ) {
                    if (index === 0) {
                      artists = this.data_song_by_url.artists[index].name;
                    } else {
                      artists = `${artists}, ${this.data_song_by_url.artists[index].name}`;
                    }
                  }
                  // generos
                  for (let j = 0; j < result.artists.length; j++) {
                    const element_ = result.artists[j];
                    this.spotifyService
                      .getArtist(element_.id)
                      .subscribe((res_artist) => {
                        // sin genero musical
                        if (res_artist.genres.length === 0) {
                          if ((result.artists.length === j + 1)) {
                            setTimeout(() => {
                              if (this.coordsS.length > 0) {
                                let input_lyric = '';
                                if (this.state_lyric === true) {
                                  input_lyric = this.text_lyric;
                                }
                                this.coordsS.forEach((coord) => {
                                  a_form = [
                                    {
                                      name: this.data_song_by_url.name,
                                      artist: artists,
                                      gender: [],
                                      language: [''],
                                      lyric: input_lyric,
                                      reason: coord.reason,
                                      justification: [
                                        {
                                          justification: coord.justification,
                                          id_user: id_user_f,
                                          date: this.date,
                                        },
                                      ],
                                      look: 0,
                                      hear: 0,
                                      favorite: 0,
                                      lat: coord.lat,
                                      long: coord.lng,
                                      place: coord.place,
                                      country: coord.country,
                                      state: true,
                                      info: '',
                                      id_user: id_user_f,
                                      link_spotify: link_spotify_f,
                                      link_youtube: '',
                                      link_soundcloud: '',
                                      date: this.date,
                                    },
                                  ];
                                  this.musicService
                                    .postMusic(a_form[0])
                                    .subscribe((res) => {
                                      this.coordsS = [];
                                      form.resetForm();
                                      this.removeClassForm();
                                      this.collapsibleSpotify();
                                      localStorage.removeItem('musicMarker');
                                      this.lat_long_update = [
                                        a_form[0].lat,
                                        a_form[0].long,
                                      ];
                                      $('#btn_add_link').removeClass('disabled');
                                      if (this.infowindow_s_prev_open !== null) {
                                        this.infowindow_s_prev_open._closeInfoWindow();
                                      }
                                      $('#reasonUpd').prop('selectedIndex', 0);
                                      $('#reasonUpd').material_select();
                                    });
                                });
                              }
                              this.generos = [];
                              $('#modal_exist_song').modal('open');
                              this.hideSpinner('spinner1');
                            }, 500);
                          }
                        } else {
                          // con genero musical
                          for (let k = 0; k < res_artist.genres.length; k++) {
                            const g = res_artist.genres[k];
                            if (this.generos.find((i) => i.gender === g) === undefined) {
                              this.generos.push({
                                id_artist: element_.id,
                                gender: g,
                              });
                            }
                            if ((result.artists.length === j + 1) && (res_artist.genres.length === k + 1)) {
                              setTimeout(() => {
                                if (this.coordsS.length > 0) {
                                  let input_lyric = '';
                                  if (this.state_lyric === true) {
                                    input_lyric = this.text_lyric;
                                  }
                                  this.coordsS.forEach((coord) => {
                                    a_form = [
                                      {
                                        name: this.data_song_by_url.name,
                                        artist: artists,
                                        gender: this.generos,
                                        language: [''],
                                        lyric: input_lyric,
                                        reason: coord.reason,
                                        justification: [
                                          {
                                            justification: coord.justification,
                                            id_user: id_user_f,
                                            date: this.date,
                                          },
                                        ],
                                        look: 0,
                                        hear: 0,
                                        favorite: 0,
                                        lat: coord.lat,
                                        long: coord.lng,
                                        place: coord.place,
                                        country: coord.country,
                                        state: true,
                                        info: '',
                                        id_user: id_user_f,
                                        link_spotify: link_spotify_f,
                                        link_youtube: '',
                                        link_soundcloud: '',
                                        date: this.date,
                                      },
                                    ];
                                    this.musicService
                                      .postMusic(a_form[0])
                                      .subscribe((res) => {
                                        this.coordsS = [];
                                        form.resetForm();
                                        this.removeClassForm();
                                        this.collapsibleSpotify();
                                        localStorage.removeItem('musicMarker');
                                        this.lat_long_update = [
                                          a_form[0].lat,
                                          a_form[0].long,
                                        ];
                                        $('#btn_add_link').removeClass('disabled');
                                        if (this.infowindow_s_prev_open !== null) {
                                          this.infowindow_s_prev_open._closeInfoWindow();
                                        }
                                        $('#reasonUpd').prop('selectedIndex', 0);
                                        $('#reasonUpd').material_select();
                                      });
                                  });
                                }
                                this.generos = [];
                                $('#modal_exist_song').modal('open');
                                this.hideSpinner('spinner1');
                              }, 500);
                            }
                          }
                        }
                      });
                  }
                });
            } else {
              $('#lat_s').addClass('invalid');
              $('#long_s').addClass('invalid');
              $('#modal_exist_song').modal('open');
              $('#btn_add_link').removeClass('disabled');
            }
          });
        },
        (error) => {
          this.img_music = 'https://www.musycgo.com/icon/marker.png';

          this.http.get(VAR_CONFIG.url_n_t_s).subscribe(async (data) => {
            const var_body: any = data;
            await localStorage.setItem('token_s', var_body.body.access_token);
          });
          if (error.status === 400) {
            this.translate
              .get('Enlace ingresado es incorrecto, por favor verificar')
              .subscribe((res) => {
                this.translate.get('Verificar').subscribe((title) => {
                  swal(title, res, 'error');
                });
              });
            this.hideSpinner('spinner1');
            $('#link_spotify_').addClass('invalid');
          } else {
            this.translate
              .get(
                'Hubo un problema en el acceso a Spotify, por favor vuelva a presionar el botón de guardar o verifique enlace ingresado',
              )
              .subscribe((res) => {
                this.translate.get('Lo sentimos!').subscribe((title) => {
                  swal(title, res, 'error');
                });
              });
            this.hideSpinner('spinner1');
          }
          $('#btn_add_link').removeClass('disabled');
        },
      );
    } else {
      $('#btn_add_link').removeClass('disabled');
      this.hideSpinner('spinner1');
      this.translate
        .get('Hay campos incompletos, operación interrumpida')
        .subscribe((res) => {
          this.translate.get('Verificar').subscribe((title) => {
            swal(title, res, 'error');
          });
        });
    }
  }

  updateMapAfterClickModal() {
    this.update_markers.emit([
      'UPDATE',
      this.lat_long_update[0],
      this.lat_long_update[1],
    ]);
  }

  addSong(form: NgForm) {
    this.showSpinner('spinner2');
    $('#btn_add').addClass('disabled');
    this.readyform = true;
    this.removeClassForm();
    if (
      $('#name')
        .val()
        .trim() === ''
    ) {
      $('#name').addClass('invalid');
      this.readyform = false;
    } else {
      $('#name').addClass('valid');
    }
    if (
      $('#artist')
        .val()
        .trim() === ''
    ) {
      $('#artist').addClass('invalid');
      this.readyform = false;
    } else {
      $('#artist').addClass('valid');
    }
    if (
      $('#lat')
        .val()
        .trim() === ''
    ) {
      $('#lat').addClass('invalid');
      this.readyform = false;
    } else {
      $('#lat').addClass('valid');
    }
    if (
      $('#long')
        .val()
        .trim() === ''
    ) {
      $('#long').addClass('invalid');
      this.readyform = false;
    } else {
      $('#long').addClass('valid');
    }
    if (
      $('#date')
        .val()
        .trim() === ''
    ) {
      $('#date').addClass('invalid');
      this.readyform = false;
    } else {
      $('#date').addClass('valid');
    }
    if (this.coords.length === 0) {
      this.readyform = false;
      this.translate
        .get('No ha ingresado correctamente la información en el mapa')
        .subscribe((res) => {
          this.translate.get('Verificar').subscribe((title) => {
            swal(title, res, 'error');
          });
        });
    }
    let a_form = [];
    this.date = new Date().toString();
    if (this.readyform === true) {
      const name_f = $('#name').val(); // form.value.name;
      const artist_f = $('#artist').val(); // form.value.artist;
      let gender_f = $('#gender').val(); // form.value.gender;
      if (gender_f === [] || gender_f === null || gender_f === '') {
        gender_f = [];
      } else {
        gender_f = [];
        // gender_f = [{ id_artist: 'nn', gender: gender_f }];
      }
      let lyric_f = '';
      if (this.state_lyric_2 === false) {
        lyric_f = '';
      } else {
        lyric_f = this.text_lyric_2;
      }
      const justification_f = '';
      // let lyric_f = form.value.lyric;
      const look = 0;
      const hear = 0;
      const favorite = 0;
      const lat_f = $('#lat').val(); // form.value.lat;
      const long_f = $('#long').val(); // form.value.long;
      // let place_f = form.value.place;
      // let state_f = form.value.state;
      const state_f = true;
      let info_f = $('#info').val(); // form.value.info;
      let id_user_f = $('#id_user').val(); // form.value.id_user;
      if (this.profile !== undefined) {
        id_user_f = this.profile.sub;
      } else {
        id_user_f = 'NN';
      }
      let link_spotify_f = $('#link_spotify').val(); // form.value.link_spotify;
      let link_youtube_f = $('#link_youtube').val(); // form.value.link_youtube;
      let link_soundcloud_f = $('#link_soundcloud').val(); // form.value.link_soundcloud;
      if (info_f === null) {
        info_f = '';
      }
      if (link_spotify_f === null) {
        link_spotify_f = '';
      }
      if (link_youtube_f === null) {
        link_youtube_f = '';
      }
      if (link_soundcloud_f === null) {
        link_soundcloud_f = '';
      }
      this.songEqualModal = [];
      this.songModal = [];
      this.existSong = false;
      this.musicService.getAllByNameArtist(name_f, artist_f).subscribe((res) => {
        const r: any = res;
        if (r !== null && r !== [] && r.length !== 0) {
          this.existSong = true;
          if (link_spotify_f === '') {
            link_spotify_f = r[0].link_spotify;
          }
          if (gender_f === []) {
            gender_f = r[0].gender;
          }
          r.forEach((song) => {
            this.songModal.push(song.place);
          });
        }
      });
      const places = [];
      for (let index = 0; index < this.coords.length; index++) {
        const coo = this.coords[index];
        this.nameSongModal = name_f;
        this.artistSongModal = artist_f;
        this.lat_long_update = [coo.lat, coo.lng];
        places.push({
          name: name_f, artist: artist_f, lat: coo.lat, lng: coo.lng,
        });
        if (this.coords.length === index + 1) {
          this.musicService.getNamArtCoord(places).subscribe((res) => {
            const r: any = res;
            if (r !== null && r !== [] && r.length !== 0) {
              this.songEqualModal.push(r[0].place);
            }
          });
          setTimeout(() => {
            if (this.songEqualModal.length === 0) {
              if (this.coords.length > 0) {
                this.coords.forEach((coord) => {
                  a_form = [
                    {
                      name: name_f,
                      artist: artist_f,
                      gender: gender_f,
                      language: [''],
                      lyric: lyric_f,
                      reason: coord.reason,
                      justification: [
                        {
                          justification: coord.justification,
                          id_user: id_user_f,
                          date: this.date,
                        },
                      ],
                      look,
                      hear,
                      favorite,
                      lat: coord.lat,
                      long: coord.lng,
                      place: coord.place,
                      country: coord.country,
                      state: state_f,
                      info: info_f,
                      id_user: id_user_f,
                      link_spotify: link_spotify_f,
                      link_youtube: link_youtube_f,
                      link_soundcloud: link_soundcloud_f,
                      date: this.date,
                    },
                  ];
                  this.musicService.postMusic(a_form[0]).subscribe((res) => {
                    this.coords = [];
                    form.resetForm();
                    this.removeClassForm();
                    this.collapsibleForm();
                    localStorage.removeItem('musicMarker');
                    $('#btn_add').removeClass('disabled');
                    if (this.infowindow_prev_open !== null) {
                      this.infowindow_prev_open._closeInfoWindow();
                    }
                    $('#reasonUpd').prop('selectedIndex', 0);
                    $('#reasonUpd').material_select();
                  });
                });
              }
              this.generos = [];
              $('#modal_exist_song_2').modal('open');
              this.hideSpinner('spinner2');
            } else {
              $('#modal_exist_song_2').modal('open');
            }
          }, 500);
        }
      }
    } else {
      $('#btn_add').removeClass('disabled');
      this.translate
        .get('Hay campos incompletos, operación interrumpida')
        .subscribe((res) => {
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'error');
          });
          this.hideSpinner('spinner2');
        });
    }
  }

  showSpinner(name: string) {
    this.spinnerService.show(name,
      {
        type: 'ball-grid-beat',
        size: 'medium',
        bdColor: 'rgba(36, 48, 62, 0.95)',
        color: '#ffffff',
        fullScreen: false,
      });
  }

  hideSpinner(name: string) {
    this.spinnerService.hide(name);
  }
}
