import {
  Component, OnInit, ChangeDetectorRef, Inject,
} from '@angular/core';
import { Chart } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { MusicService } from '../../services/music.service';
import { UserService } from '../../services/user.service';
// import { DATA_COUNTRY } from '../../../assets/country';
import { DATA_COUNTRY_V2 } from '../../../assets/countriesV2';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
})
export class ChartComponent implements OnInit {
  graphcountry: any[];

  graphusers: any[];

  graphplaces: any[];

  graphfavorite: any[];

  chartCountry = [];

  chartUsers = [];

  chartPlaces = [];

  chartFav = [];

  contributions: any[];

  res: any;

  num_contributions: any;

  genderRepetition: any = [];

  options: any = {};

  widthScreen: number;

  heightScreen: number;

  constructor(
    private musicService: MusicService,
    private user: UserService,
    public translate: TranslateService,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.loadUsersChart();
    this.loadCountryChart();
    this.loadPlacesMusicChart();
    this.loadFavoriteMusicChart();
    const that = this;
    $(window).resize(() => {
      that.heightScreen = $(window).height();
      that.widthScreen = $(window).width();
      that.heightScreen = Math.round(that.heightScreen - (that.heightScreen * 0.05));
      that.widthScreen = Math.round(that.widthScreen - (that.widthScreen * 0.1));
      if (that.genderRepetition.length !== 0) {
        that.createCloud();
      }
    });
  }

  loadUsersChart() {
    this.chartUsers = new Chart('canvas2', {
      type: 'doughnut',
      data: {
        labels: [''],
        datasets: [
          {
            data: [''],
            backgroundColor: [''],
          },
        ],
      },
    });
    const resultuser = [];
    const colors = [];
    this.graphusers = [];
    this.musicService.groupSongUsers().subscribe((result) => {
      const resgroupsong: any = result;
      const us: any = [];
      for (let index = 0; index < resgroupsong.length; index++) {
        const element = resgroupsong[index];
        us.push(element._id.id_user);
      }
      let cont = 0;
      this.user.getUsersFromAnAPI(us).subscribe((response) => {
        if (response !== null && response !== undefined) {
          colors.push(this.randomColor(cont, resgroupsong.length));
          resultuser.push({
            nickname: response.nickname,
            num: resgroupsong[cont].num,
            picture: response.picture,
            sub: response.sub,
          });
        } else {
          this.translate.get('Usuarios Anónimos').subscribe((res_anon) => {
            colors.push(this.randomColor(cont, resgroupsong.length));
            resultuser.push({
              nickname: res_anon,
              num: resgroupsong[cont].num,
              picture:
                'https://pngimage.net/wp-content/uploads/2018/05/anonimo-png.png',
              sub: 'anonymous|',
            });
          });
        }
        cont++;
        if (cont >= us.length) {
          const resultorder = this.sortJSON(resultuser, 'num', 'desc');
          const nickname = [];
          const num = [];
          const picture = [];
          for (let index = 0; index < resultuser.length; index++) {
            const element = resultorder[index];
            nickname.push(element.nickname);
            num.push(element.num);
            picture.push(element.picture);
            this.graphusers.push([
              element.nickname,
              element.num,
              element.picture,
              element.sub,
            ]);
          }
          this.chartUsers = new Chart('canvas2', {
            type: 'doughnut',
            data: {
              labels: nickname,
              datasets: [
                {
                  data: num,
                  backgroundColor: colors,
                },
              ],
            },
          });
          this.changeDetectorRef.detectChanges();
        }
      });
    });
  }

  sortJSON(data, key, orden) {
    return data.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }
      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  loadCountryChart() {
    const current_lang = localStorage.getItem('lang');
    let res: any;
    const colors = [];
    const graphcountryname = [];
    const fullname = [];
    const graphcountrydata = [];
    this.graphcountry = [];
    this.musicService.groupCountry().subscribe((result) => {
      res = result;
      let name_country = '';
      let flag = '';
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        DATA_COUNTRY_V2.data.forEach((country) => {
          if (country.cca2 === element._id.country[0]) {
            if (current_lang === 'es') name_country = country.translations.spa.common;
            else if (current_lang === 'en') name_country = country.name.common;
            else if (current_lang === 'pt') name_country = country.translations.por.common;
            else name_country = country.translations.spa.common;
            flag = "/assets/flags/" + country.flags.local;
            fullname.push(name_country);
            this.graphcountry.push([name_country, parseInt(element.num), flag]);
          }
        });
        graphcountryname.push(element._id.country[0]);
        graphcountrydata.push(parseInt(element.num));
        colors.push(this.randomColor(index, res.length));
        this.chartCountry = new Chart('canvas', {
          type: 'bar',
          data: {
            labels: fullname,
            datasets: [
              {
                data: graphcountrydata,
                backgroundColor: colors,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                },
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    min: 0,
                  },
                },
              ],
            },
          },
        });
      }
    });
  }

  loadPlacesMusicChart() {
    this.graphplaces = [];
    const graphplacesname = [];
    const graphplacesnum = [];
    const colors = [];

    this.musicService.groupMorePlaces().subscribe((res) => {
      const r: any = res;
      for (let index = 0; index < r.length; index++) {
        const element = r[index];
        if (element.count > 1) {
          graphplacesname.push(
            `${element._id.name.name} | ${element._id.artist.artist}`,
          );
          graphplacesnum.push(element.count);
          colors.push(this.randomColor(index, r.length));
          this.graphplaces.push([
            element._id.name.name,
            element._id.artist.artist,
            element.count,
          ]);
        }
      }
      this.chartPlaces = new Chart('canvas3', {
        type: 'bar',
        data: {
          labels: graphplacesname,
          datasets: [
            {
              data: graphplacesnum,
              backgroundColor: colors,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                ticks: {
                  autoSkip: false,
                  maxRotation: 80,
                  minRotation: 80,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      });
    });
  }

  loadFavoriteMusicChart() {
    this.graphfavorite = [];
    const graphfavnum = [];
    const graphpfavname = [];
    const num = 0;
    const colors = [];
    this.musicService.groupMusicFav().subscribe((res) => {
      const r: any = res;
      for (let index = 0; index < r.length; index++) {
        const element = r[index];
        if (element.favorite[0] !== 0) {
          graphpfavname.push(
            `${element._id.name.name} | ${element._id.artist.artist}`,
          );
          graphfavnum.push(element.favorite[0]);
          colors.push(this.randomColor(index, r.length));
          this.graphfavorite.push([
            element._id.name.name,
            element._id.artist.artist,
            // element.place,
            element.favorite[0],
          ]);
        }
      }
      this.chartFav = new Chart('canvas4', {
        type: 'bar',
        data: {
          labels: graphpfavname,
          datasets: [
            {
              data: graphfavnum,
              backgroundColor: colors,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                ticks: {
                  autoSkip: false,
                  maxRotation: 80,
                  minRotation: 80,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                ticks: {
                  min: 0,
                  precision: 0, /* ,
                    stepSize: 1 */
                },
              },
            ],
          },
        },
      });
    });
  }

  randomColor(i, total) {
    const r = 100 + (i * 155) / total;
    const g = (i * 255) / total;
    const b = (i * 255) / total;
    return `rgb(${r},${g},${b})`;
  }

  cerrarModal() {
    $('.modal').modal('close');
  }

  getInfoUser(user) {
    const markers: any = JSON.parse(localStorage.getItem('markers'));
    this.contributions = [];
    this.res = [];
    this.num_contributions = user[1];
    const nick = user[0];
    const picture = user[2];
    this.user.infoUser(nick, picture).subscribe((result) => {
      this.res = result;
      if (result === null) {
        this.res = {
          // type: user[3].substring(0, user[3].indexOf('|')),
          type: user[3].charAt(0),
          nickname: user[0],
          picture: user[2],
        };
        for (let index = 0; index < markers.length; index++) {
          const element = markers[index].id_user;
          if (element === '' || element === '-' || element === 'NN') {
            this.contributions.push(markers[index]);
          }
          if (markers.length === index + 1) this.changeDetectorRef.detectChanges();
        }
      } else {
        // this.res.type = user[3].substring(0, user[3].indexOf('|'));
        this.res.type = user[3].charAt(0);
        this.res.nickname = user[0];
        this.res.picture = user[2];
        for (let index = 0; index < markers.length; index++) {
          const element = markers[index].id_user;
          if (element === this.res.sub) {
            this.contributions.push(markers[index]);
          }
          if (markers.length === index + 1) this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  createCloud() {
    if (this.genderRepetition.length === 0) {
      this.heightScreen = $(window).height();
      this.widthScreen = $(window).width();
      this.heightScreen = Math.round(this.heightScreen - (this.heightScreen * 0.05));
      this.widthScreen = Math.round(this.widthScreen - (this.widthScreen * 0.1));
    }
    let genders = [];
    this.genderRepetition = [];
    this.musicService.groupGenders().subscribe((res) => {
      const gend: any = res;
      gend.forEach((g) => {
        genders = genders.concat(g._id);
      });
      const unique = (value, index, self) => self.indexOf(value) === index;
      const uniqueGenders = genders.filter(unique);
      for (let i = 0; i < uniqueGenders.length; i++) {
        this.genderRepetition.push({ size: 0, text: uniqueGenders[i] });
      }
      for (let i = 0; i < genders.length; i++) {
        for (let j = 0; j < this.genderRepetition.length; j++) {
          if (this.genderRepetition[j].text === genders[i]) {
            this.genderRepetition[j].size++;
          }
          if (genders.length === i + 1 && this.genderRepetition.length === j + 1) {
            this.options = {
              settings: {
                minFontSize: 8,
                maxFontSize: 80,
              },
              margin: {
                top: 10,
                right: 10,
                bottom: 10,
                left: 10,
              },
              labels: true,
            };
            this.changeDetectorRef.detectChanges();
          }
        }
      }
    });
  }
}
