interface AuthConfig {
    clientID: string;
    domain: string;
    callbackURL: string;
}

export const AUTH_CONFIG: AuthConfig = {
  clientID: '3tdbvXye90fmuLcrLj7zt4NR8CriuZhX',
  domain: 'gucapp.auth0.com',
  callbackURL: 'https://www.musycgo.com/',
  //callbackURL: 'http://localhost:4200/',
};
