interface LanguagesSong {
    all_language: any;
}

export const LANGUAGES: LanguagesSong = {
  all_language: [
    { lang_es: 'Azerí', lang_pt: 'Azeri', lang_en: 'Azeri' },
    { lang_es: 'Afrikaans', lang_pt: 'Africâner', lang_en: 'Afrikaans' },
    { lang_es: 'Albanés', lang_pt: 'Albanês', lang_en: 'Albanian' },
    { lang_es: 'Alemán', lang_pt: 'Alemão', lang_en: 'German' },
    { lang_es: 'Alsaciano', lang_pt: 'Alsaciano', lang_en: 'Alsatian' },
    { lang_es: 'Amárico', lang_pt: 'Amárico', lang_en: 'Amharic' },
    { lang_es: 'Anglosajón', lang_pt: 'Anglo Saxão', lang_en: 'Anglo Saxon' },
    { lang_es: 'Árabe', lang_pt: 'Árabe', lang_en: 'Arab' },
    { lang_es: 'Aragonés', lang_pt: 'Aragonês', lang_en: 'Aragonese' },
    { lang_es: 'Armenio', lang_pt: 'Armênio', lang_en: 'Armenian' },
    { lang_es: 'Asturiano', lang_pt: 'Asturiano', lang_en: 'Asturian' },
    { lang_es: 'Aymara', lang_pt: 'Aymara', lang_en: 'Aymara' },
    { lang_es: 'Bajo sajón', lang_pt: 'Sob saxão', lang_en: 'Under Saxon' },
    { lang_es: 'Bengalí', lang_pt: 'Bengali', lang_en: 'Bengali' },
    { lang_es: 'Bielorruso', lang_pt: 'Bielorrusso', lang_en: 'Belarusian' },
    { lang_es: 'Birmano', lang_pt: 'Birmanês', lang_en: 'Burmese' },
    { lang_es: 'Bosnio', lang_pt: 'Bósnio', lang_en: 'Bosnian' },
    { lang_es: 'Bretón', lang_pt: 'Bretão', lang_en: 'Breton' },
    { lang_es: 'Búlgaro', lang_pt: 'Búlgaro', lang_en: 'Bulgarian' },
    { lang_es: 'Canarés', lang_pt: 'Kannada', lang_en: 'Kannada' },
    { lang_es: 'Catalán', lang_pt: 'Catalão', lang_en: 'Catalan' },
    { lang_es: 'Chamorro', lang_pt: 'Chamorro', lang_en: 'Chamorro' },
    { lang_es: 'Checo', lang_pt: 'Tcheco', lang_en: 'Czech' },
    { lang_es: 'Cheroqui', lang_pt: 'Cherokee', lang_en: 'Cherokee' },
    { lang_es: 'Chino mandarín', lang_pt: 'Chinês mandarim', lang_en: 'Mandarin Chinese' },
    { lang_es: 'Coreano', lang_pt: 'Coreano', lang_en: 'Korean' },
    { lang_es: 'Corso', lang_pt: 'Corso', lang_en: 'Corsican' },
    { lang_es: 'Croata', lang_pt: 'Croata', lang_en: 'Croatian' },
    { lang_es: 'Curdo', lang_pt: 'Curdo', lang_en: 'Curdo' },
    { lang_es: 'Danés', lang_pt: 'Dinamarquês', lang_en: 'Danish' },
    { lang_es: 'Eslovaco', lang_pt: 'Eslovaco', lang_en: 'Slovak' },
    { lang_es: 'Esloveno', lang_pt: 'Esloveno', lang_en: 'Slovenian' },
    { lang_es: 'Español', lang_pt: 'Espanhol', lang_en: 'Spanish' },
    { lang_es: 'Esperanto', lang_pt: 'Esperanto', lang_en: 'Esperanto' },
    { lang_es: 'Estonio', lang_pt: 'Estoniano', lang_en: 'Estonian' },
    { lang_es: 'Euskera', lang_pt: 'Basco', lang_en: 'Basque' },
    { lang_es: 'Feroés', lang_pt: 'Feroês', lang_en: 'Faroese' },
    { lang_es: 'Fiyiano', lang_pt: 'Fijiano', lang_en: 'Fijian' },
    { lang_es: 'Finlandés', lang_pt: 'Finlandês', lang_en: 'Finnish' },
    { lang_es: 'Francés', lang_pt: 'Francês', lang_en: 'French' },
    { lang_es: 'Frisón', lang_pt: 'Friesian', lang_en: 'Friesian' },
    { lang_es: 'Galés', lang_pt: 'Galês', lang_en: 'Welsh' },
    { lang_es: 'Gallego', lang_pt: 'Galego', lang_en: 'Galician' },
    { lang_es: 'Georgiano', lang_pt: 'Georgiano', lang_en: 'Georgian' },
    { lang_es: 'Griego moderno', lang_pt: 'Grego moderno', lang_en: 'Modern greek' },
    { lang_es: 'Guaraní', lang_pt: 'Guarani', lang_en: 'Guarani' },
    { lang_es: 'Gujaratí', lang_pt: 'Gujarati', lang_en: 'Gujaratí' },
    { lang_es: 'Hebreo israelí', lang_pt: 'Hebraico israelense', lang_en: 'Israeli Hebrew' },
    { lang_es: 'Hindi', lang_pt: 'Hindi', lang_en: 'Hindi' },
    { lang_es: 'Holandés', lang_pt: 'Holandês', lang_en: 'Dutch' },
    { lang_es: 'Húngaro', lang_pt: 'Húngaro', lang_en: 'Hungarian' },
    { lang_es: 'Ido', lang_pt: 'Longe', lang_en: 'Gone' },
    { lang_es: 'Igbo', lang_pt: 'Igbo', lang_en: 'Igbo' },
    { lang_es: 'Indonesio', lang_pt: 'Indonésio', lang_en: 'Indonesian' },
    { lang_es: 'Inglés', lang_pt: 'Inglês', lang_en: 'English' },
    { lang_es: 'Interlingua (IALA)', lang_pt: 'Interlíngua (IALA)', lang_en: 'Interlingua (IALA)' },
    { lang_es: 'Irlandés', lang_pt: 'Irlandês', lang_en: 'Irish' },
    { lang_es: 'Islandés', lang_pt: 'Islandês', lang_en: 'Icelandic' },
    { lang_es: 'Italiano', lang_pt: 'Italiano', lang_en: 'Italian' },
    { lang_es: 'Japonés', lang_pt: 'Japonês', lang_en: 'Japanese' },
    { lang_es: 'Javanés', lang_pt: 'Javanês', lang_en: 'Javanese' },
    { lang_es: 'Kazako', lang_pt: 'Kazako', lang_en: 'Kazako' },
    { lang_es: 'Laosiano', lang_pt: 'Laosiano', lang_en: 'Laotian' },
    { lang_es: 'Latín', lang_pt: 'Latim', lang_en: 'Latin' },
    { lang_es: 'Letón', lang_pt: 'Letão', lang_en: 'Latvian' },
    { lang_es: 'Lingala', lang_pt: 'Lingala', lang_en: 'Lingala' },
    { lang_es: 'Lituano', lang_pt: 'Lituano', lang_en: 'Lithuanian' },
    { lang_es: 'Luxemburgués', lang_pt: 'Luxemburguês', lang_en: 'Luxembourgish' },
    { lang_es: 'Macedonio', lang_pt: 'Macedônio', lang_en: 'Macedonian' },
    { lang_es: 'Malabar', lang_pt: 'Malabar', lang_en: 'Malabar' },
    { lang_es: 'Malayo', lang_pt: 'Malaio', lang_en: 'Malay' },
    { lang_es: 'Malgache', lang_pt: 'Malgaxe', lang_en: 'Malagasy' },
    { lang_es: 'Maltés', lang_pt: 'Maltês', lang_en: 'Maltese' },
    { lang_es: 'Manés', lang_pt: 'Manx', lang_en: 'Manx' },
    { lang_es: 'Maorí', lang_pt: 'Maori', lang_en: 'Maori' },
    { lang_es: 'Marati', lang_pt: 'Marati', lang_en: 'Marati' },
    { lang_es: 'Moldavo', lang_pt: 'Moldavo', lang_en: 'Moldavian' },
    { lang_es: 'Mongol', lang_pt: 'Mongol', lang_en: 'Mongolian' },
    { lang_es: 'Náhuatl', lang_pt: 'Nahuatl', lang_en: 'Nahuatl' },
    { lang_es: 'Nauruano', lang_pt: 'Nauruano', lang_en: 'Nauruano' },
    { lang_es: 'Noruego', lang_pt: 'Norueguês', lang_en: 'Norwegian' },
    { lang_es: 'Occitano', lang_pt: 'Occitânico', lang_en: 'Occitan' },
    { lang_es: 'Papiamento', lang_pt: 'Papiamento', lang_en: 'Papiamento' },
    { lang_es: 'Persa moderno', lang_pt: 'Persa moderno', lang_en: 'Modern persian' },
    { lang_es: 'Polaco', lang_pt: 'Polonês', lang_en: 'Polish' },
    { lang_es: 'Portugués', lang_pt: 'Português', lang_en: 'Portuguese' },
    { lang_es: 'Punjabí', lang_pt: 'Punjabi', lang_en: 'Punjabi' },
    { lang_es: 'Quechua', lang_pt: 'Quechua', lang_en: 'Quechua' },
    { lang_es: 'Quirguiz', lang_pt: 'Quirguiz', lang_en: 'Quirguiz' },
    { lang_es: 'Romanche', lang_pt: 'Romanche', lang_en: 'Romanche' },
    { lang_es: 'Rumano', lang_pt: 'Romeno', lang_en: 'Romanian' },
    { lang_es: 'Ruso', lang_pt: 'Russo', lang_en: 'Russian' },
    { lang_es: 'Sardo', lang_pt: 'Sardenha', lang_en: 'Sardinian' },
    { lang_es: 'Serbio', lang_pt: 'Sérvio', lang_en: 'Serbian' },
    { lang_es: 'Sesotho', lang_pt: 'Sesotho', lang_en: 'Sesotho' },
    { lang_es: 'Siciliano', lang_pt: 'Siciliano', lang_en: 'Sicilian' },
    { lang_es: 'Somalí', lang_pt: 'Somali', lang_en: 'Somali' },
    { lang_es: 'Sueco', lang_pt: 'Sueco', lang_en: 'Swedish' },
    { lang_es: 'Swahili', lang_pt: 'Suaíli', lang_en: 'Swahili' },
    { lang_es: 'Tagalo', lang_pt: 'Tagalo', lang_en: 'Tagalog' },
    { lang_es: 'Tailandés', lang_pt: 'Tailandês', lang_en: 'Thai' },
    { lang_es: 'Tamil', lang_pt: 'Tâmil', lang_en: 'Tamil' },
    { lang_es: 'Tártaro', lang_pt: 'Tártaro', lang_en: 'Tartar' },
    { lang_es: 'Tártaro de Crimea', lang_pt: 'Tártaro da Crimeia', lang_en: 'Crimean Tartar' },
    { lang_es: 'Tegulú', lang_pt: 'Tegulu', lang_en: 'Tegulu' },
    { lang_es: 'Tibetano', lang_pt: 'Tibetano', lang_en: 'Tibetan' },
    { lang_es: 'Turco', lang_pt: 'Turco', lang_en: 'Turkish' },
    { lang_es: 'Turcomano', lang_pt: 'Turkoman', lang_en: 'Turkoman' },
    { lang_es: 'Ucraniano', lang_pt: 'Ucraniano', lang_en: 'Ukrainian' },
    { lang_es: 'Urdu', lang_pt: 'Urdu', lang_en: 'Urdu' },
    { lang_es: 'Uzbeko', lang_pt: 'Uzbeque', lang_en: 'Uzbek' },
    { lang_es: 'Valón', lang_pt: 'Valônia', lang_en: 'Walloon' },
    { lang_es: 'Vietnamita', lang_pt: 'Vietnamita', lang_en: 'Vietnamese' },
    { lang_es: 'Volapuk', lang_pt: 'Volapuk', lang_en: 'Volapuk' },
    { lang_es: 'Xhosa', lang_pt: 'Xhosa', lang_en: 'Xhosa' },
    { lang_es: 'Yidish', lang_pt: 'Yidish', lang_en: 'Yiddish' },
    { lang_es: 'Yoruba', lang_pt: 'Ioruba', lang_en: 'Yoruba' },
    { lang_es: 'Zulú', lang_pt: 'Zulu', lang_en: 'Zulu' },
  ],
};
