import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../app/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
})

export class AppComponent implements OnInit {
  constructor(public auth: AuthService, public translate: TranslateService) {
    auth.handleAuthentication();
    this.translate.addLangs(['es', 'en', 'pt']);
    this.translate.setDefaultLang(localStorage.getItem('lang') || 'es');
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.auth.renewTokens();
    }
  }

  ngAfterViewInit() {
    this.auth.getVar().subscribe(() => {});
    setTimeout(() => { this.auth.handleAuthentication(); }, 4000);
  }
}
