import { LatLngBounds, MapsAPILoader, MouseEvent } from '@agm/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { } from 'googlemaps';
import 'materialize-css';
import { Subscription } from 'rxjs';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
// import { DATA_COUNTRY } from '../../assets/country';
import { DATA_COUNTRY_V2 } from '../../assets/countriesV2';
import { LANGUAGES } from '../../assets/languages';
import { STYLE_MAP } from '../../assets/style';
import { AuthService } from '../services/auth/auth.service';
import { GoogleAnalyticsEventsService } from '../services/google-analytics-events-service.service';
import { MarkerService } from '../services/marker.service';
import { MusicService } from '../services/music.service';
import { SpotifyService } from '../services/spotify.service';
import { UserService } from '../services/user.service';
import { VAR_CONFIG } from '../services/vars';

const gmaps = require('../../assets/gmaps.js');

declare let $: any;
declare let sortData: any; // del scripts.js
declare const google: any;

@Component({
  selector: 'app-app-map',
  templateUrl: './app-map.component.html',
  styleUrls: ['./app-map.component.css'],
  providers: [MarkerService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMapComponent implements OnInit, OnDestroy {
  infoWindow: any;

  subscription: Subscription;

  private geoCoder;

  pageId = '/main';

  hide_popup: boolean = false;

  map_: any;

  profile: any;

  @ViewChild('search') public searchElement: ElementRef;

  finish_move_map: boolean = true;

  lat_clicked: number;

  lng_clicked: number;

  lat: number =
    Number(localStorage.getItem('lat')) || Number(-8.41555405594415);

  lng: number =
    Number(localStorage.getItem('lng')) || Number(-52.8753337758655);

  lat_busqueda: number;

  lng_busqueda: number;

  lugar_busqueda: string;

  public activo: String = '';

  mostrar_info: Boolean = true;

  click_lat: number = 0;

  click_long: number = 0;

  title: string = 'Prototipo de Georreferenciación Musical';

  zoom: number = 3;

  r: number;

  radio_my_position: number;

  mi_latitud: number = 0;

  mi_longitud: number = 0;

  link_spotify_: string;

  arreglo_markers = [];

  num_arreglo_markers: number;

  arreglo_markers_ranking = [];

  markers_ranking_spotify = [];

  all_country: any = [];

  all_country_list: any = [];

  neLat: number = 0;

  neLng: number = 0;

  swLat: number = 0;

  swLng: number = 0;

  centro_map_lat: number;

  centro_map_long: number;

  embed_link: string = '';

  show_embed: boolean = false;

  link_selected_song: any;

  embed_link_yt: string = '';

  img_music: string = 'https://www.musycgo.com/icon/marker.png';

  img_artist: string;

  song_selected_more_info: any;

  timeLeft: number;

  interval;

  lang_disqus: string = localStorage.getItem('lang') || 'es';

  trend_look: any;

  trend_hear: any;

  all_gender: any = [];

  dropdownList_gender = [];

  selectedItems_gender = [];

  dropdownSettings = {};

  all_language: any = [];

  dropdownList_lang = [];

  selectedItems_lang = [];

  all_artist: any = [];

  dropdownList_artist = [];

  selectedItems_artist = [];

  trans01: string = '';

  trans02: string = '';

  trans03: string = '';

  trans04: string = '';

  trans05: string = '';

  trans06: string = '';

  trans07: string = '';

  trans08: string = '';

  trans09: string = '';

  trans10: string = '';

  trans11: string = '';

  trans12: string = '';

  trans13: string = '';

  trans14: string = '';

  trans15: string = '';

  trans16: string = '';

  trans17: string = '';

  trans18: string = '';

  trans19: string = '';

  trans20: string = '';

  trans21: string = '';

  trans22: string = '';

  transHist: string = '';

  transContact: string = '';

  // ESTILO DEL MAPA
  styles = STYLE_MAP.style;

  // MARCADORES
  markers: marker[] = [];

  copiaMarkers = [
    {
      n: '',
      a: '',
      l: '',
      in: '',
    },
  ];

  trans28: any;

  trans29: any;

  name_country_clic: any;

  tracks_country: any;

  near_tracks: any[];

  bool_countrytracks: boolean;

  bool_neartracks: boolean;

  bool_btn_lists: boolean;

  nickname_user: any;

  trans30: any;

  full_name_country_clic: string;

  flag_country_clic: string;

  lastmarkerclicked: any;

  all_country_list2: any;

  prev_zoom: any = 3;

  flagReloadDT: boolean = false;

  allselected: boolean = true;

  num_country_markers: any;

  isSnazzyInfoWindowOpened: boolean = false;

  currentuser: any;

  stateNoti: boolean = false;

  contributions: any[];

  res: any;

  num_contributions: any;

  db_language: any = LANGUAGES.all_language;

  array_spanish: any[];

  onlyoneload: boolean = true;

  transInfoMap: string = '';

  notifications: any;

  trans32: string = '';

  trans31: string = '';

  trans33: string = '';

  trans001: string = '';

  showMarkers: boolean = true;

  userMobile: boolean = false;

  infoWindowMarkerArtAndName: any;

  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    private markerService: MarkerService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private user: UserService,
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    private musicService: MusicService,
    private cd: ChangeDetectorRef,
    private googleAnalyticsService: GoogleAnalyticsEventsService,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService,
    private spotifyService: SpotifyService,
  ) {
    this.getMarkerkComponent();
    this.appendGaTrackingCode();
    this.lang_disqus = localStorage.getItem('lang') || 'es';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    window.document.title = 'MusycGo - Home';
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            this.saveUser();
            this.cd.detectChanges();
            if (localStorage.getItem('last_url') !== null) {
              const lasturl = localStorage.getItem('last_url');
              localStorage.removeItem('last_url');
              window.open(lasturl, '_self');
            }
            break;
          case 'error':
            break;
        }
      }
    });
    this.loadScripts();
    $('#update_page_map').css('display', 'none');
    $('#song_now_map').css('display', 'none');
    $('#btn_reset_zoom').css('display', 'none');
    $('#esL, #esLm').css('font-weight', 'bold');
    $('#porL, #porLm').css('font-weight', '200');
    $('#engL, #engLm').css('font-weight', '200');
    $('#marker_justification_2').css('display', 'none');
    $('.dropdown-trigger').dropdown();
    $('.dropdown-button').dropdown({ hover: true, coverTrigger: false, inDuration: 300 });
    let currentlang = localStorage.getItem('lang');
    this.showSearchBar('none');
    this.radio_my_position = 4000;
    this.spinnerService.show('main-spinner',
      {
        type: 'line-scale-party',
        size: 'medium',
        bdColor: 'rgba(36, 48, 62, 0.95)',
        color: '#ffffff',
        fullScreen: false,
      });
    this.http.get(VAR_CONFIG.url_t_s).subscribe(async (data) => {
      const var_body: any = data;
      if (var_body !== null) {
        await localStorage.setItem('token_s', var_body.body.access_token);
      }
    });

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElement.nativeElement,
        {
          types: [], // aqui se elige entre las categorias de resultados  ['address'], ['establishment'], ['geocode'] o []
          // componentRestrictions: { country: "CL" } // restringiriamos a solo chile en las busquedas
        },
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            this.translate
              .get('No se encuentra información para: ', {
                value: place.name,
              })
              .subscribe((res) => {
                this.translate.get('Ups!').subscribe((title) => {
                  swal(title, res, 'error');
                });
              });
            return;
          }
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.lat_busqueda = place.geometry.location.lat();
          this.lng_busqueda = place.geometry.location.lng();
          this.lugar_busqueda = place.name;
          this.recenterMap(
            this.lat + 0.00000000001 * Math.random(),
            this.lng + 0.00000000001 * Math.random(),
          );
          this.zoom = 12;
          this.cd.detectChanges();
        });
      });
    });
    if (currentlang === null || currentlang === undefined) {
      currentlang = localStorage.getItem('lang');
    }
    if (currentlang === 'es') {
      this.lang_disqus = 'es';
      this.translate.use('es');
      this.translateTitles();
      $('#esL, #esLm').css('font-weight', 'bold');
      $('#porL, #porLm').css('font-weight', '200');
      $('#engL, #engLm').css('font-weight', '200');
      localStorage.setItem(
        'lang_dt',
        'https://cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      );
    }
    if (currentlang === 'en') {
      this.lang_disqus = 'en';
      this.translate.use('en');
      this.translateTitles();
      $('#esL, #esLm').css('font-weight', '200');
      $('#porL, #porLm').css('font-weight', '200');
      $('#engL, #engLm').css('font-weight', 'bold');
      localStorage.setItem(
        'lang_dt',
        'https://cdn.datatables.net/plug-ins/1.10.16/i18n/English.json',
      );
    }
    if (currentlang === 'pt') {
      this.lang_disqus = 'pt';
      this.translate.use('pt');
      this.translateTitles();
      $('#esL, #esLm').css('font-weight', '200');
      $('#porL, #porLm').css('font-weight', 'bold');
      $('#engL, #engLm').css('font-weight', '200');
      localStorage.setItem(
        'lang_dt',
        'https://cdn.datatables.net/plug-ins/1.10.16/i18n/Portuguese-Brasil.json',
      );
    }
    if (currentlang === undefined || localStorage.getItem('lang') === null) {
      localStorage.setItem('lang', 'es');
      this.lang_disqus = 'es';
      this.translate.use('es');
      this.translateTitles();
      $('#esL, #esLm').css('font-weight', 'bold');
      $('#porL, #porLm').css('font-weight', '200');
      $('#engL, #engLm').css('font-weight', '200');
      localStorage.setItem(
        'lang_dt',
        'https://cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      );
    }
    this.zoom = 3;
    this.userMobile = this.isMobile()
  }

  isMobile() {
    if (navigator.userAgent.match(/Android/i)) return true
    if (navigator.userAgent.match(/BlackBerry/i)) return true
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) return true
    if (navigator.userAgent.match(/Opera Mini/i)) return true
    if (navigator.userAgent.match(/IEMobile/i)) return true
  }

  handleOnTilesLoaded() {
    if (this.onlyoneload === true) {
      this.loadConfigMultiSelect();
      this.onlyoneload = false;
      this.musicService.getHearMusics().subscribe((res) => {
        this.trend_hear = res;
      });
      this.musicService.getLookMusics().subscribe((res) => {
        this.trend_look = res;
      });
      this.refreshMarkersMap();
    }
  }

  loadConfigMultiSelect() {
    let buscar = 'Buscar';
    let noDate = 'No hay datos disponibles';
    let desAll = 'Deseleccionar todos los resultados';
    let selAll = 'Seleccionar todos los resultados';
    let desAll2 = 'Deseleccionar todo';
    let selAll2 = 'Seleccionar todo';
    let sel = 'Seleccione';
    this.translate.get(buscar).subscribe((res) => {
      buscar = res;
      this.translate.get(noDate).subscribe((res) => {
        noDate = res;
        this.translate.get(desAll).subscribe((res) => {
          desAll = res;
          this.translate.get(selAll).subscribe((res) => {
            selAll = res;
            this.translate.get(desAll2).subscribe((res) => {
              desAll2 = res;
              this.translate.get(selAll2).subscribe((res) => {
                selAll2 = res;
                this.translate.get(sel).subscribe((res) => {
                  sel = res;
                  this.dropdownSettings = {
                    singleSelection: false,
                    text: sel,
                    selectAllText: selAll2,
                    unSelectAllText: desAll2,
                    enableSearchFilter: true,
                    enableCheckAll: true,
                    filterSelectAllText: selAll,
                    filterUnSelectAllText: desAll,
                    badgeShowLimit: 5,
                    searchPlaceholderText: buscar,
                    labelKey: 'itemName',
                    primaryKey: 'id',
                    noDataLabel: noDate,
                    showCheckbox: true,
                    // lazyLoading: true,
                    position: 'top',
                    searchBy: ['itemName'],
                    classes: 'change-color-dd',
                  };
                });
              });
            });
          });
        });
      });
    });
    this.musicService.getMusics().subscribe(async (music) => {
      await this.cargarFiltros(music);
    });
    setTimeout(() => {
      this.spinnerService.hide('main-spinner');
    }, 500);
  }

  loadScripts() {
    $('.button-collapse').sideNav({ edge: 'left' });
    $('.modal').modal({ opacity: 0.8 });
    $('#modal_result_song_autocomplete').modal({
      dismissible: false,
      endingTop: '5%',
    });
    $('#modal_exist_song').modal({
      dismissible: false,
    });
    $('#modal_youtube').modal({});
    $('#buscador_canciones_').keyup(() => {
      $('#modal_result_song_autocomplete').modal('open');
      const table = $('#table_search').DataTable();
      table.search($('#buscador_canciones_').val()).draw();
    });
    $('.collapsible').collapsible();
    $('.modal').scrollTop(0);
    $('.tooltipped').tooltip();
  }

  updateLooks(music, action) {
    let num_hear = music.hear;
    let num_favorite = music.favorite;
    if (action === 'hear') {
      num_hear = music.hear + 1;
    } else if (action === 'favorite_add') {
      if (music.favorite === null) {
        num_favorite = 1;
      } else {
        num_favorite = music.favorite + 1;
      }
    } else if (action === 'favorite_remove') {
      if (music.favorite === null) {
        num_favorite = 0;
      } else {
        num_favorite = music.favorite - 1;
      }
    } else {
      num_hear = music.hear;
    }

    const look_for_update = [
      {
        _id: music._id,
        name: music.name,
        artist: music.artist,
        gender: music.gender,
        language: music.language,
        lyric: music.lyric,
        reason: music.reason,
        justification: music.justification,
        look: music.look,
        hear: num_hear,
        favorite: num_favorite,
        lat: music.lat,
        long: music.long,
        place: music.place,
        country: music.country,
        state: music.state,
        info: music.info,
        id_user: music.id_user,
        link_spotify: music.link_spotify,
        link_youtube: music.link_youtube,
        link_soundcloud: music.link_soundcloud,
        date: music.date,
      },
    ];
    this.musicService.putMusic(look_for_update[0]).subscribe((res) => {
      if (look_for_update[0]._id === this.currentuser._id) {
        localStorage.removeItem('musicMarker');
        localStorage.setItem('musicMarker', JSON.stringify(look_for_update[0]));
        window.scrollTo(0, 0);
        this.showSearchBar('none');
        this.getMarkerkComponent();
        this.markerDentroRectangulo();
        this.rankingSong();
        this.lastmarkerclicked._openInfoWindow();
      }
    });
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', 'UA-144718224-1', 'auto');";
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  actionFavorite(m) {
    $('.favorite_song').attr('disabled', true);
    $('#trans25').css('color', 'red');
    setTimeout(() => {
      $('.favorite_song').attr('disabled', false);
      $('#trans25').css('color', 'white');
    }, 2000);
    this.user.ifExist(this.profile.sub).subscribe((res) => {
      const res_: any = res;
      this.currentuser = res_;
      let other_places: any;
      const other_places_id = [];
      const new_array_fav: any = this.currentuser.favorite;
      this.musicService
        .getAllByNameArtist(m.name, m.artist)
        .subscribe((res) => {
          other_places = res;
          for (let index = 0; index < other_places.length; index++) {
            other_places_id.push(other_places[index]._id);
          }
          let index_find;
          for (let index = 0; index < other_places_id.length; index++) {
            const element = other_places_id[index];
            index_find = new_array_fav.findIndex((x) => x.id === element);
            if (index_find !== -1) {
              // quitar
              $('.favorite_song').css('background', 'black');
              this.updateLooks(other_places[index], 'favorite_remove');
              new_array_fav.splice(index_find, 1);
            } else {
              // agregar
              $('.favorite_song').css('background', 'red');
              this.updateLooks(other_places[index], 'favorite_add');
              new_array_fav.push({
                id: element,
                date_in: new Date().toString(),
              });
            }
            if (other_places_id.length === index + 1) {
              // setTimeout(() => {
              const upd_fav = [
                {
                  _id: this.currentuser._id,
                  sub: this.currentuser.sub,
                  picture: this.currentuser.picture,
                  nickname: this.currentuser.nickname,
                  name: this.currentuser.name,
                  given_name: this.currentuser.given_name,
                  favorite: new_array_fav,
                  family_name: this.currentuser.family_name,
                  contributions: this.currentuser.contributions,
                  notifications: this.currentuser.notifications,
                  date: this.currentuser.date,
                  qualification: this.currentuser.qualification,
                },
              ];
              this.user.putUser(upd_fav[0]).subscribe((res) => {
                this.currentuser = upd_fav[0];
              });
              // }, 1100);
            }
          }
        });
    });
  }

  seeMarker(marker) {
    this.zoom = 16;
    this.lat = marker.lat;
    this.lng = marker.long;
    $('#modal_footer_ranking_song').modal('close');
    $('#modal_footer_song').modal('close');
    $('#modal_trend_activity').modal('close');
  }

  setMusicProfile(music: any) {
    this.musicService.setMusicSelected(music);
  }

  getMarkerkComponent() {
    this.markerService.init();
    this.markers = this.markerService.obtenerMarcadores();
  }

  refreshMarkerComponent() {
    this.markers = this.markerService.recargarMarcadores();
  }

  updateApp() {
    this.ngZone.runOutsideAngular(() => {
      location.reload();
    });
  }

  updateMarker(msj) {
    if (msj[0] === 'UPDATE') {
      this.markers = [];
      this.flagReloadDT = true;
      $('ul.tabs').tabs('select_tab', 'main_map');
      if (this.zoom !== 3) {
        this.markerService.init();
        this.markers = this.markerService.obtenerMarcadores();
        setTimeout(() => {
          this.cargarFiltros(this.markers);
          this.markerDentroRectangulo();
          this.rankingSong();
          this.zoom = 8;
          this.recenterMap(
            msj[1] + 0.00000000001 * Math.random(),
            msj[2] + 0.00000000001 * Math.random(),
          );
          this.flagReloadDT = false;
        }, 700);
      } else {
        this.markerService.init();
        this.markers = this.markerService.obtenerMarcadores();
        setTimeout(() => {
          this.cargarFiltros(this.markers);
          this.recenterMap(
            msj[1] + 0.00000000001 * Math.random(),
            msj[2] + 0.00000000001 * Math.random(),
          );
          this.flagReloadDT = false;
        }, 700);
      }
      $('.collapsible-body').css('display', 'none');
      $('label').removeClass('active');
    }
  }

  cargarFiltros(markers: any) {
    let sg = 'Sin Género';
    this.translate.get(sg).subscribe((res) => {
      sg = res;
    });
    let si = 'Sin Idioma';
    this.translate.get(si).subscribe((res) => {
      si = res;
    });
    if (markers && markers !== undefined) {
      if (markers.length > 0) {
        for (let i = 0; i < markers.length; i += 1) {
          markers[i].gender.forEach((element) => {
            if (
              element.gender !== undefined
              && this.all_gender.find((i) => i.genero === element.gender)
              === undefined
            ) {
              this.all_gender.push({ genero: element.gender });
            }
          });

          if (
            markers[i].language[0] !== null
            && markers[i].language[0] !== '-'
            && this.all_language.find(
              (idiom) => idiom.idioma === markers[i].language[0],
            ) === undefined
          ) {
            this.all_language.push({ idioma: markers[i].language[0] });
          }
          if (
            markers[i].artist !== null
            && markers[i].artist !== '-'
            && this.all_artist.find(
              (element) => element.artist === markers[i].artist,
            ) === undefined
          ) {
            this.all_artist.push({ artist: markers[i].artist });
          }
        }
      }
    }
    let txt = 'Buscar';
    this.translate.get(txt).subscribe((res) => {
      txt = res;
    });

    /* filtro generos */
    let index = 0;
    this.dropdownList_gender.push({ id: 0, itemName: sg });
    this.selectedItems_gender.push({ id: 0, itemName: sg });
    for (const genero in this.all_gender) {
      if (this.all_gender.hasOwnProperty(genero)) {
        this.dropdownList_gender.push({
          id: index + 1,
          itemName: this.all_gender[genero].genero,
        });
        this.selectedItems_gender.push({
          id: index + 1,
          itemName: this.all_gender[genero].genero,
        });
      }
      index++;
    }

    /* filtro idiomas */
    let index_lang = 0;
    this.dropdownList_lang.push({ id: 0, itemName: si });
    this.selectedItems_lang.push({ id: 0, itemName: si });
    for (const language in this.all_language) {
      if (this.all_language.hasOwnProperty(language)) {
        for (let index = 0; index < this.db_language.length; index++) {
          const element = this.db_language[index];
          if (this.lang_disqus === 'pt') {
            if (
              this.all_language[language].idioma === element.lang_es
              || this.all_language[language].idioma === element.lang_en
              || this.all_language[language].idioma === element.lang_pt
            ) {
              this.dropdownList_lang.push({
                id: index_lang + 1,
                itemName: element.lang_pt,
              });
              this.selectedItems_lang.push({
                id: index_lang + 1,
                itemName: element.lang_pt,
              });
              break;
            }
          } else if (this.lang_disqus === 'en') {
            if (
              this.all_language[language].idioma === element.lang_es
              || this.all_language[language].idioma === element.lang_en
              || this.all_language[language].idioma === element.lang_pt
            ) {
              this.dropdownList_lang.push({
                id: index_lang + 1,
                itemName: element.lang_en,
              });
              this.selectedItems_lang.push({
                id: index_lang + 1,
                itemName: element.lang_en,
              });
              break;
            }
          } else if (
            this.all_language[language].idioma === element.lang_es
            || this.all_language[language].idioma === element.lang_en
            || this.all_language[language].idioma === element.lang_pt
          ) {
            this.dropdownList_lang.push({
              id: index_lang + 1,
              itemName: element.lang_es,
            });
            this.selectedItems_lang.push({
              id: index_lang + 1,
              itemName: element.lang_es,
            });
            break;
          }
        }
      }
      index_lang++;
    }

    /* filtro artistas */
    let index_artist = 0;
    for (const artist in this.all_artist) {
      if (this.all_artist.hasOwnProperty(artist)) {
        if (this.all_artist[artist] !== undefined) {
          this.dropdownList_artist.push({
            id: index_artist + 1,
            itemName: this.all_artist[artist].artist,
          });
          this.selectedItems_artist.push({
            id: index_artist + 1,
            itemName: this.all_artist[artist].artist,
          });
        }
      }
      index_artist++;
    }

    this.array_spanish = [];
    this.array_spanish.push({
      id: 0,
      itemName: 'Sin Idioma',
    });
    for (const itemName in this.selectedItems_lang) {
      for (const language in this.db_language) {
        if (
          this.selectedItems_lang[itemName].itemName
          === this.db_language[language].lang_es
          || this.selectedItems_lang[itemName].itemName
          === this.db_language[language].lang_en
          || this.selectedItems_lang[itemName].itemName
          === this.db_language[language].lang_pt
        ) {
          this.array_spanish.push({
            id: parseInt(itemName),
            itemName: this.db_language[language].lang_es,
          });
        }
      }
    }
    /* FIN FILTROS */
    this.loadCountry();
  }

  cerrarModal() {
    $('.modal').modal('close');
    $('.button-collapse').sideNav('hide');
  }

  saveUser() {
    let data_new_user = [];
    data_new_user = [
      {
        sub: this.profile.sub,
        given_name: this.profile.given_name,
        family_name: this.profile.family_name,
        nickname: this.profile.nickname,
        name: this.profile.name,
        picture: this.profile.picture,
        contributions: { data: '' },
        notifications: [],
        favorite: [],
        date: new Date().toString(),
        qualification: [],
      },
    ];

    this.user.ifExist(this.profile.sub).subscribe((res) => {
      const r: any = res;
      if (r === null || r.length === 0) {
        this.user.postUser(data_new_user[0]).subscribe((res) => { });
      }
      this.cd.detectChanges();
    });
  }

  loadCountry() {
    const current_lang = localStorage.getItem('lang');
    let res: any;
    this.all_country = [];
    this.num_arreglo_markers = 0;
    this.musicService.groupCountry().subscribe((result) => {
      res = result;
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        this.musicService
          .getMusicByCountry(String(element._id.country[0]))
          .subscribe((result_song) => {
            let name_country = '';
            const res_song: any = result_song;
            let num_song_country = '';
            let lat_country = 0;
            let lng_country = 0;
            const songs: any = [];
            let flag = '';
            if (res_song !== undefined && res_song !== [] && res_song !== null) {
              for (let i = 0; i < res_song.length; i++) {
                const element = res_song[i];
                if (this.markerFilterByGender(element._id.gender)) {
                  if (this.markerFilterByLanguage(element._id.language[0])) {
                    if (this.markerFilterByArtist(element._id.artist)) {
                      if (
                        element._id.link_spotify.trim() !== ''
                        && element._id.link_spotify !== undefined
                      ) {
                        songs.push(element);
                      }
                    }
                  }
                }
              }
            }
            DATA_COUNTRY_V2.data.forEach((country) => {
              if (songs.length > 0) {
                if (country.cca2 === element._id.country[0]) {
                  if (current_lang === 'es') name_country = country.translations.spa.common;
                  else if (current_lang === 'en') name_country = country.name.common;
                  else if (current_lang === 'pt') name_country = country.translations.por.common;
                  else name_country = country.translations.es.common;
                  num_song_country = String(element.num);
                  lat_country = Number(country.latlng[0]);
                  lng_country = Number(country.latlng[1]);
                  flag = "/assets/flags/" + country.flags.local;
                  this.all_country.push([
                    name_country,
                    num_song_country,
                    lat_country,
                    lng_country,
                    songs,
                    flag,
                    country.cca2,
                  ]);
                  this.num_country_markers = this.all_country.length;
                  this.cd.detectChanges();
                }
              }
            });
            if (res.length === index + 1) {
              //setTimeout(() => {
              if (this.all_country !== []) {
                this.all_country_list = this.all_country;
              }
              const array = [];
              this.all_country_list2 = this.all_country_list;
              let markers = this.markers;
              for (let index = 0; index < this.all_country_list.length; index++) {
                const element = this.all_country_list2[index];
                for (let index2 = 0; index2 < element[4].length; index2++) {
                  const element2 = element[4][index2];
                  let l;
                  if (
                    element2._id.link_spotify !== null
                    && element2._id.link_spotify !== ''
                  ) {
                    if (element2._id.link_spotify.indexOf('/album/') === -1) {
                      if (element2._id.link_spotify.indexOf('?') === -1) {
                        l = element2._id.link_spotify.substr(
                          element2._id.link_spotify.indexOf('/track/') + 7,
                          element2._id.link_spotify.length,
                        );
                      } else {
                        l = element2._id.link_spotify.substr(
                          0,
                          element2._id.link_spotify.indexOf('?'),
                        );
                        l = l.substr(l.indexOf('/track/') + 7, l.length);
                      }
                    } else {
                      l = element2._id.link_spotify.substr(
                        element2._id.link_spotify.indexOf('/album/') + 7,
                        element2._id.link_spotify.length,
                      );
                      if (l.indexOf('?') !== -1) {
                        l = l.substr(0, l.indexOf('?'));
                      }
                    }

                    for (let k = 0; k < markers.length; k++) {
                      const mark = markers[k];
                      if (mark.link_spotify.includes(l)) {
                        array.push(mark);
                        this.all_country_list2[index][4][index2]._id.id = mark._id;
                        k = markers.length;
                      }
                    }
                    /*  //Otra forma de encontrar _id de la cancion (aunque son mas llamados a la API)
                    this.musicService
                    .getOneByLinkSpotify(l)
                    .subscribe((result_song) => {
                      const res_: any = result_song;
                      array.push(res_);
                      this.all_country_list2[index][4][index2]._id.id = res_._id;
                    }); */
                  }
                }
              }
              setTimeout(() => {
                this.num_country_markers = this.all_country.length;
                this.cd.detectChanges();
              }, 1500);
              //}, 1000);
            }
          });
      }
    });
  }

  translateTitles() {
    this.translate
      .get(
        'MusycGo: Aplicación Web para la Georreferenciación Mundial de la Cultura Musical',
      )
      .subscribe((res) => {
        this.trans01 = res;
        $('#trans01').attr('title', this.trans01);
      });
    this.translate.get('Recargar Aplicación').subscribe((res) => {
      this.trans02 = res;
      $('#trans02').attr('title', this.trans02);
    });
    this.translate.get('Cambiar al Idioma Español').subscribe((res) => {
      this.trans03 = res;
      $('#trans03').attr('title', this.trans03);
    });
    this.translate.get('Cambiar al Idioma Inglés').subscribe((res) => {
      this.trans04 = res;
      $('#trans04').attr('title', this.trans04);
    });
    this.translate.get('Cambiar al Idioma Portugués').subscribe((res) => {
      this.trans05 = res;
      $('#trans05').attr('title', this.trans05);
    });
    this.translate.get('Cambiar idioma').subscribe((res) => {
      this.trans06 = res;
      $('#trans06').attr('title', this.trans06);
    });
    this.translate.get('Identificarse').subscribe((res) => {
      this.trans07 = res;
      $('#trans07').attr('title', this.trans07);
    });
    this.translate
      .get(
        'Mapa principal que muestra los lugares donde se hace alusión a canciones',
      )
      .subscribe((res) => {
        this.trans08 = res;
        $('#trans08').attr('title', this.trans08);
      });
    this.translate
      .get('Ingresar una nueva canción o un nuevo lugar al mapa')
      .subscribe((res) => {
        this.trans09 = res;
        $('#trans09').attr('title', this.trans09);
      });
    this.translate
      .get('Buscador global de las canciones registradas en la aplicación')
      .subscribe((res) => {
        this.trans10 = res;
        $('#trans10').attr('title', this.trans10);
      });
    this.translate.get('Estadísticas generales').subscribe((res) => {
      this.trans001 = res;
      $('#trans001').attr('title', this.trans001);
    });
    this.translate.get('Sección de comentarios de MusycGo').subscribe((res) => {
      this.trans11 = res;
      $('#trans11').attr('title', this.trans11);
    });
    this.translate.get('Ir a tu ubicación actual').subscribe((res) => {
      this.trans12 = res;
      $('#relocation').attr('title', this.trans12);
    });
    this.translate
      .get('Todas las canciones en esta porción visible del mapa')
      .subscribe((res) => {
        this.trans13 = res;
        $('#song_now_map').attr('title', this.trans13);
      });
    this.translate
      .get('Listado de las canciones cercanas a tu ubicación actual')
      .subscribe((res) => {
        this.trans14 = res;
        $('#song_ranking_map').attr('title', this.trans14);
      });
    this.translate
      .get('Listado de las canciones más vistas y más reproducidas')
      .subscribe((res) => {
        this.trans15 = res;
        $('#trend_song_map').attr('title', this.trans15);
      });
    this.translate
      .get('Buscador de canciones por ubicación o por título')
      .subscribe((res) => {
        this.trans16 = res;
        $('#btn_search_bar').attr('title', this.trans16);
      });
    this.translate
      .get('Recargar marcadores/canciones en el mapa')
      .subscribe((res) => {
        this.trans17 = res;
        $('#update_page_map').attr('title', this.trans17);
      });
    this.translate
      .get('Configuraciones del mapa (Filtros no han sido modificados)')
      .subscribe((res) => {
        this.trans18 = res;
        $('#param_map').attr('title', this.trans18);
      });
    this.translate.get('Más información').subscribe((res) => {
      this.trans19 = res;
      $('#trans19').attr('title', this.trans19);
      $('#trans19_2').attr('title', this.trans19);
    });
    this.translate.get('Nº canciones visibles').subscribe((res) => {
      this.trans20 = res;
      $('#num_markers').attr('title', this.trans20);
    });
    this.translate.get('Redes Sociales').subscribe((res) => {
      this.trans21 = res;
      $('#trans21').attr('title', this.trans21);
    });
    this.translate.get('Guardar en Favoritos').subscribe((res) => {
      this.trans22 = res;
      $('#trans22').attr('title', this.trans22);
    });
    this.translate.get('Aquí estás').subscribe((res) => {
      this.trans28 = res;
      $('#trans28').attr('title', this.trans28);
    });
    this.translate.get('Ingresar Canción').subscribe((res) => {
      this.trans30 = res;
      $('#trans30').attr('title', this.trans30);
    });
    this.translate.get('Información').subscribe((res) => {
      this.trans31 = res;
      $('#trans31').attr('title', this.trans31);
    });
    this.translate.get('Acerca de').subscribe((res) => {
      this.trans32 = res;
      $('#trans32').attr('title', this.trans32);
    });
    this.translate.get('Cambiar a zoom inicial').subscribe((res) => {
      this.trans33 = res;
      $('#btn_reset_zoom').attr('title', this.trans33);
    });
    this.translate
      .get('Reproducir la canción del último marcador abierto')
      .subscribe((res) => {
        this.trans29 = res;
        $('#icon_ply_1').attr('title', this.trans29);
        $('#icon_ply_2').attr('title', this.trans29);
      });
    this.translate.get('Historial de cambios').subscribe((res) => {
      this.transHist = res;
      $('#transHNotif').attr('title', this.transHist);
    });
    this.translate.get('Contáctanos').subscribe((res) => {
      this.transContact = res;
      $('#transContact').attr('title', this.transContact);
    });
    this.translate
      .get('Información de la simbología del mapa')
      .subscribe((res) => {
        this.transInfoMap = res;
        $('#btn_info_map').attr('title', this.transInfoMap);
      });
  }

  fullTop() {
    $('.modal').scrollTop(0);
  }

  esLang() {
    if (this.translate.currentLang === 'es') {
      this.translate.get('Español es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'es';
      localStorage.setItem('lang', 'es');
      localStorage.setItem(
        'lang_dt',
        'https://cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      );
      this.translate.use('es');
      this.translateTitles();
      $('#esL, #esLm').css('font-weight', 'bold');
      $('#porL, #porLm').css('font-weight', '200');
      $('#engL, #engLm').css('font-weight', '200');
      this.ngZone.runOutsideAngular(() => {
        location.reload();
      });
    }
  }

  ptLang() {
    if (this.translate.currentLang === 'pt') {
      this.translate.get('Portugués es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'pt';
      localStorage.setItem('lang', 'pt');
      localStorage.setItem(
        'lang_dt',
        'https://cdn.datatables.net/plug-ins/1.10.16/i18n/Portuguese-Brasil.json',
      );
      this.translate.use('pt');
      this.translateTitles();
      $('#esL, #esLm').css('font-weight', '200');
      $('#porL, #porLm').css('font-weight', 'bold');
      $('#engL, #engLm').css('font-weight', '200');
      this.ngZone.runOutsideAngular(() => {
        location.reload();
      });
    }
  }

  enLang() {
    if (this.translate.currentLang === 'en') {
      this.translate.get('Inglés es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'en';
      localStorage.setItem('lang', 'en');
      localStorage.setItem(
        'lang_dt',
        'https://cdn.datatables.net/plug-ins/1.10.16/i18n/English.json',
      );
      this.translate.use('en');
      this.translateTitles();
      $('#esL, #esLm').css('font-weight', '200');
      $('#porL, #porLm').css('font-weight', '200');
      $('#engL, #engLm').css('font-weight', 'bold');
      this.ngZone.runOutsideAngular(() => {
        location.reload();
      });
    }
  }

  mapStyle(option) {
    if (option === 'default' && this.zoom === 3) {
      this.styles = STYLE_MAP.style;
    }
    if (option === 'default' && this.zoom > 3) {
      this.styles = STYLE_MAP.style_with_country;
    }
    if (option === 'dark') {
      this.styles = STYLE_MAP.optional_dark_style;
    }
    if (option === 'light') {
      this.styles = STYLE_MAP.optional_light_style;
    }
    if (option === 'google_style') {
      this.styles = STYLE_MAP.google_style;
    }
    let txt = 'Listo';
    this.translate.get(txt).subscribe((res) => {
      txt = res;
    });
    M.toast({ html: txt, classes: 'rounded' });
    this.cerrarModal();
  }

  checkSelectedFilter() {
    if (this.zoom === 3) {
      this.loadCountry();
    }
    this.allselected = false;
    $('#param_map').css('background', 'orange');
    if (
      this.dropdownList_lang.length === this.selectedItems_lang.length
      && this.dropdownList_gender.length === this.selectedItems_gender.length
      && this.dropdownList_artist.length === this.selectedItems_artist.length
    ) {
      this.allselected = true;
      $('#param_map').css('background', '#26a69a');
    }
    this.array_spanish = [];
    this.array_spanish.push({
      id: 0,
      itemName: 'Sin Idioma',
    });
    for (const itemName in this.selectedItems_lang) {
      for (const language in this.db_language) {
        if (
          this.selectedItems_lang[itemName].itemName
          === this.db_language[language].lang_es
          || this.selectedItems_lang[itemName].itemName
          === this.db_language[language].lang_en
          || this.selectedItems_lang[itemName].itemName
          === this.db_language[language].lang_pt
        ) {
          this.array_spanish.push({
            id: parseInt(itemName),
            itemName: this.db_language[language].lang_es,
          });
        }
      }
    }
    this.recenterMap(this.centro_map_lat, this.centro_map_long);
    if (this.allselected) {
      this.translate
        .get('Configuraciones del mapa (Filtros no han sido modificados)')
        .subscribe((res) => {
          this.trans18 = res;
          $('#param_map').attr('title', this.trans18);
        });
    } else {
      this.translate
        .get('Configuraciones del mapa (Filtros modificados)')
        .subscribe((res) => {
          this.trans18 = res;
          $('#param_map').attr('title', this.trans18);
        });
    }
    return this.allselected;
  }

  onItemSelect(item: any) {
    this.refreshMarkersMap();
    this.checkSelectedFilter();
    this.recenterMap(this.centro_map_lat, this.centro_map_long);
  }

  onSelectAll(items: any) {
    this.refreshMarkersMap();
    setTimeout(() => {
      this.checkSelectedFilter();
      this.recenterMap(this.centro_map_lat, this.centro_map_long);
    }, 1000);
  }

  onItemDeSelect(item: any) {
    this.refreshMarkersMap();
    this.checkSelectedFilter();
    this.recenterMap(this.centro_map_lat, this.centro_map_long);
  }

  onDeselectAll(items: any, type: string) {
    if (type === 'artist') {
      this.selectedItems_artist = [];
    } else if (type === 'gender') {
      this.selectedItems_gender = [];
    } else {
      this.selectedItems_lang = [];
    }
    this.refreshMarkersMap();
    this.checkSelectedFilter();
    this.recenterMap(this.centro_map_lat, this.centro_map_long);
  }

  mostrarModalInfoMarker(m) {
    $('#modal_more_info_marker').modal({
      opacity: 0.85,
      complete() {
        if (
          m.link_youtube !== ''
          && m.link_youtube !== null
          && m.link_youtube !== undefined
        ) {
          const frame: any = document.getElementById('videoPlayer1');
          frame.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            '*',
          );
        }
      },
    });
    $('#modal_more_info_marker').modal('open');
    this.song_selected_more_info = m;
    if (this.song_selected_more_info.link_soundcloud !== undefined) {
      const settings = {
        async: true,
        crossDomain: true,
        url: 'http://soundcloud.com/oembed',
        method: 'POST',
        headers: {},
        data: {
          format: 'json',
          url: this.song_selected_more_info.link_soundcloud,
        },
      };
      $.ajax(settings).done((response) => {
        $('#div_embed_soundcloud').html(response.html);
      });
    }
    this.user.ifExist(this.song_selected_more_info.id_user).subscribe((res) => {
      const res_: any = res;
      if (res_ !== null) {
        this.nickname_user = res_.nickname;
      } else {
        this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
          this.nickname_user = res_anon;
        });
      }
      this.cd.detectChanges();
    });
  }

  changeRange() {
    this.radio_my_position = $('#range_distance').val();
    this.rankingSong();
    $('.collapsible-body').css('display', 'none');
    this.translate.get('Filtro Aplicado.').subscribe((res) => {
      swal('', res, 'success', { buttons: [false], timer: 1500 });
    });
  }

  recenterMap(lat, lng) {
    // watchPosition geolocation
    this.lat = Number(lat) + 0.000000000000001 * Math.random();
    this.lng = Number(lng) + 0.000000000000001 * Math.random();
  }

  getPosition() {
    this.translate.get('Buscando...').subscribe((res) => {
      M.toast({ html: res.toString(), displayLength: 700, classes: 'rounded' });
    });
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.translate.get('Obteniendo...').subscribe((res) => {
            M.toast({ html: res.toString(), displayLength: 800, classes: 'rounded' });
          });
          this.zoom = 13;
          this.lat = position.coords.latitude + 0.00000000001 * Math.random();
          this.lng = position.coords.longitude + 0.00000000001 * Math.random();
          this.mi_latitud = this.lat;
          this.mi_longitud = this.lng;
          this.recenterMap(
            position.coords.latitude + 0.00000000001 * Math.random(),
            position.coords.longitude + 0.00000000001 * Math.random(),
          );
          this.cd.detectChanges();
          this.translate.get('Listo').subscribe((res) => {
            M.toast({ html: res.toString(), displayLength: 1000, classes: 'rounded' });
          });
          this.isSnazzyInfoWindowOpened = false;
          setTimeout(() => {
            this.isSnazzyInfoWindowOpened = true;
            this.changeDetectorRef.detectChanges();
          }, 500);
        },
        function (error) {
          this.translate.get('Error de geolocalización.').subscribe((res) => {
            M.toast({ html: res.toString(), displayLength: 2000, classes: 'rounded' });
          });
        },
        { timeout: 20000, enableHighAccuracy: true, maximumAge: 0 },
      );
    } else {
      this.translate
        .get('Este navegador no soporta geolocalización.')
        .subscribe((res) => {
          M.toast({ html: res.toString(), displayLength: 2000, classes: 'rounded' });
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'error');
          });
        });
    }
  }

  eventGooAnalytics(category, x, y, id) {
    this.googleAnalyticsService.emitEvent(
      category,
      `${x}-${y}_id=${id}`,
      id,
      10,
    );
  }

  markerFavorite(id) {
    $('.favorite_song').css('background', 'black');
    if (this.profile) {
      this.user.searchFavorite(this.profile.sub).subscribe((res) => {
        const fav: any = res;
        let cont = 0;
        for (let index = 0; index < fav.length; index++) {
          const element = fav[index].id;
          if (element === id) {
            cont++;
          }
        }
        if (cont !== 0) {
          $('.favorite_song').css('background', 'red');
        } else {
          $('.favorite_song').css('background', 'black');
        }
      });
    }
  }

  hidePopup() {
    this.hide_popup = true;
  }

  openMainSnazzy(infoWindow) {
    if (this.hide_popup) {
      infoWindow._closeInfoWindow();
      this.hide_popup = false;
    }
  }

  openArtAndName(infoWindowMarker) {
    this.infoWindowMarkerArtAndName = infoWindowMarker;
  }

  markerClicked(marcador, index: number, infowindow, gm) {
    this.isSnazzyInfoWindowOpened = !this.isSnazzyInfoWindowOpened;
    if (this.infoWindowMarkerArtAndName !== undefined) {
      this.infoWindowMarkerArtAndName._closeInfoWindow();
    }
    this.lat = marcador.lat;
    this.lng = marcador.long;
    this.link_selected_song = '';
    this.lastmarkerclicked = infowindow;
    this.markerFavorite(marcador._id);
    this.showSearchBar('none');
    this.eventGooAnalytics(
      'Clic Canción',
      marcador.name,
      marcador.artist,
      marcador._id,
    );
    this.activo = marcador.name;
    this.mostrar_info = true;
    if (this.show_embed === true) {
      this.show_embed = false;
      $('#player_window_spot').css('display', 'none');
      $('#player_window_spot').css('width', '0');
    }
    if (window.innerWidth > 960) {
      this.show_embed = true;
      $('#player_window_spot').css('display', 'block');
      $('#player_window_spot').css('width', '315');
    }
    this.img_music = 'https://www.musycgo.com/icon/marker.png';
    this.img_artist = '';
    let id_link_spotify = '';
    this.embed_link = 'https://open.spotify.com/embed/track/undefined';
    if (marcador.link_spotify !== undefined && marcador.link_spotify !== '') {
      this.link_selected_song = marcador;
      id_link_spotify = marcador.link_spotify.split('/album/')[1];
      this.embed_link = `https://open.spotify.com/embed/album/${marcador.link_spotify.split('/album/')[1]
        }`;
      if (marcador.link_spotify.split('/album/').length <= 1) {
        id_link_spotify = marcador.link_spotify.split('/track/')[1];
        this.embed_link = `https://open.spotify.com/embed/track/${marcador.link_spotify.split('/track/')[1]
          }`;
      }
      if (
        id_link_spotify !== null
        && id_link_spotify !== '-'
        && id_link_spotify !== ''
      ) {
        if (id_link_spotify.indexOf('?') !== -1) {
          id_link_spotify = id_link_spotify.substr(
            0,
            id_link_spotify.indexOf('?'),
          );
        }
        this.spotifyService.getInfoTrackById(id_link_spotify).subscribe(
          (res) => {
            this.img_music = res.album.images[1].url;
            this.spotifyService.getArtist(res.artists[0].id).subscribe(
              (data) => {
                if (data.images[0]) {
                  this.img_artist = data.images[0].url;
                }
              },
              (error) => {
                this.img_artist = '';
              },
            );
          },
          (error) => {
            this.img_music = 'https://www.musycgo.com/icon/marker.png';

            this.http.get(VAR_CONFIG.url_n_t_s).subscribe(async (data) => {
              const var_body: any = data;
              await localStorage.setItem('token_s', var_body.body.access_token);
            });
          },
        );
      } else {
        this.img_music = 'https://www.musycgo.com/icon/marker.png';
      }
    }
    if (marcador.link_youtube !== undefined) {
      this.link_selected_song = marcador;
      // String regExp = "/.*(?:youtu.be\\/|v\\/|u/\\w/|embed\\/|watch\\?.*&?v=)";
      let id = '';
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = this.link_selected_song.link_youtube.match(regExp);
      if (match && match[7].length === 11) {
        id = match[7];
      }
      this.embed_link_yt = `https://www.youtube.com/embed/${id}`;
    }
    infowindow._closeInfoWindow();
    if (gm.lastOpen != null) {
      gm.lastOpen._closeInfoWindow();
    }
    setTimeout(() => {
      gm.lastOpen = infowindow;
      infowindow._openInfoWindow();
    }, 100);
  }

  setPlaylist(typelist) {
    this.bool_btn_lists = false;
    if (typelist === 'countrytracks' && this.name_country_clic !== '') {
      this.bool_countrytracks = true;
      this.bool_neartracks = false;
      this.all_country_list2.forEach((element) => {
        if (this.name_country_clic === element[6]) {
          this.tracks_country = element[4];
        }
      });
    }

    if (typelist === 'neartracks') {
      this.bool_countrytracks = false;
      this.bool_neartracks = true;
      this.rankingSongClic(this.lat_clicked, this.lng_clicked);
    }
  }

  mapClicked($event: MouseEvent) {
    this.isSnazzyInfoWindowOpened = !this.isSnazzyInfoWindowOpened;
    //this.isSnazzyInfoWindowOpened = false;
    this.bool_btn_lists = true;
    this.bool_countrytracks = false;
    this.bool_neartracks = false;
    this.tracks_country = [];
    this.near_tracks = [];
    this.click_lat = $event.coords.lat + 0.00000000001 * Math.random();
    this.click_long = $event.coords.lng + 0.00000000001 * Math.random();
    this.lat_clicked = this.click_lat;
    this.lng_clicked = this.click_long;
    this.showSearchBar('none');
    $('#marker_justification_2').css('display', 'none');
    if (this.show_embed) {
      this.show_embed = false;
      $('#player_window_spot').css('display', 'none');
      $('#player_window_spot').css('width', '0');
    }
    const current_lang = localStorage.getItem('lang');
    this.geoCoder.geocode(
      { location: { lat: this.lat_clicked, lng: this.lng_clicked } },
      (results, status) => {
        if (status === 'OK') {
          const nom = results[results.length - 1].address_components[0].short_name;
          const blacklist = [
            'AQ',
            'Antártida',
            'Antarctica',
            'Océano Atlántico',
            'Océano Índico',
            'Oceano Atlântico',
            'Oceano Índico',
            'Atlantic Ocean',
            'Indian Ocean',
            'Weddell Sea',
          ];
          if (blacklist.findIndex((place) => place === nom) !== -1) {
            this.name_country_clic = '';
            this.full_name_country_clic = '';
            this.flag_country_clic = '';
            this.cd.detectChanges();
          } else if (results[0]) {
            this.name_country_clic = nom;
            DATA_COUNTRY_V2.data.forEach((country) => {
              if (country.cca2 === nom) {
                if (current_lang === 'es') this.full_name_country_clic = country.translations.spa.common;
                if (current_lang === 'en') this.full_name_country_clic = country.name.common;
                if (current_lang === 'pt') this.full_name_country_clic = country.translations.por.common;
                this.flag_country_clic = "/assets/flags/" + country.flags.local;
                this.cd.detectChanges();
              }
            });
          }
        } else {
          this.name_country_clic = '';
          this.full_name_country_clic = '';
          this.flag_country_clic = '';
          this.cd.detectChanges();
        }
      },
    );
  }

  mapChanged(latLngBounds: LatLngBounds) {
    this.showSearchBar('none');
    this.neLat = latLngBounds.getNorthEast().lat() + 0.00000000001 * Math.random();
    this.neLng = latLngBounds.getNorthEast().lng() + 0.00000000001 * Math.random();
    this.swLat = latLngBounds.getSouthWest().lat() + 0.00000000001 * Math.random();
    this.swLng = latLngBounds.getSouthWest().lng() + 0.00000000001 * Math.random();
    this.finish_move_map = false;
    this.centro_map_lat = latLngBounds.getCenter().lat();
    this.centro_map_long = latLngBounds.getCenter().lng();
    $('.collapsible-body').css('display', 'none');
    $('li').removeClass('active');
    $('.collapsible-body').css('display', 'none');
    if (this.zoom > 3) {
      this.all_country = [];
    }
  }

  afterChangeMap() {
    this.finish_move_map = true;
    if (this.zoom > 3) {
      this.markerDentroRectangulo();
      this.rankingSong();
    }
    localStorage.setItem('lat', this.lat.toString());
    localStorage.setItem('lng', this.lng.toString());
  }

  refreshMarkersMap() {
    this.showSearchBar('none');
    this.getMarkerkComponent();
    this.markerDentroRectangulo();
    this.rankingSong();
  }

  mapZoomChange($event) {
    this.showSearchBar('none');
    this.zoom = $event;
    if ($event > 3) {
      this.all_country = [];
      if (this.styles === STYLE_MAP.style) {
        this.styles = STYLE_MAP.style_with_country;
      }
    }
    if ($event > 5) {
      $('#btn_reset_zoom').css('display', 'block');
    }
    if ($event <= 5) {
      $('#btn_reset_zoom').css('display', 'none');
    }
    if ($event === 3 && this.prev_zoom !== 3) {
      this.loadCountry();
      this.arreglo_markers = [];
    } else {
      $('#update_page_map').css('display', 'block');
      $('#song_now_map').css('display', 'block');
    }
    if ($event === 3) {
      $('#update_page_map').css('display', 'none');
      $('#song_now_map').css('display', 'none');
      if (this.styles === STYLE_MAP.style_with_country) {
        this.styles = STYLE_MAP.style;
      }
    }
    this.prev_zoom = $event;
  }

  showSearchBar(yes_or_no) {
    $('#search_bar').css('display', yes_or_no);
  }

  mapDone(event: any) {
    $('.button-collapse').sideNav({ edge: 'left' });
    $('.modal').modal();
    $('.collapsible').collapsible();
    $('.modal').scrollTop(0);
    $('.dropdown-trigger').dropdown();
    $('.dropdown-button').dropdown({ hover: true, coverTrigger: false, inDuration: 300 });
    $('.tabs').tabs();
    $('.dropdown-trigger').dropdown('open');
    $('#player_window_spot').css('width', '0');

    this.showSearchBar('none');
    window.scrollTo(0, 0);
    this.map_ = event;
    if (screen.width < 557) {
      this.map_.controls[google.maps.ControlPosition.LEFT_TOP].push(
        document.getElementById('relocation'),
      );
      this.map_.controls[google.maps.ControlPosition.LEFT_TOP].push(
        document.getElementById('song_ranking_map'),
      );
      this.map_.controls[google.maps.ControlPosition.LEFT_TOP].push(
        document.getElementById('song_now_map'),
      );
      this.map_.controls[google.maps.ControlPosition.LEFT_TOP].push(
        document.getElementById('trend_song_map'),
      );
      this.map_.controls[google.maps.ControlPosition.LEFT_TOP].push(
        document.getElementById('btn_search_bar'),
      );
      this.map_.controls[google.maps.ControlPosition.LEFT_TOP].push(
        document.getElementById('btn_reset_zoom'),
      );
    } else {
      this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById('relocation'),
      );
      this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById('song_ranking_map'),
      );
      this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById('song_now_map'),
      );
      this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById('trend_song_map'),
      );
      this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById('btn_search_bar'),
      );
      this.map_.controls[google.maps.ControlPosition.TOP_LEFT].push(
        document.getElementById('btn_reset_zoom'),
      );
    }

    this.map_.controls[google.maps.ControlPosition.TOP_RIGHT].push(
      document.getElementById('update_page_map'),
    );
    this.map_.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
      document.getElementById('param_map'),
    );
    this.map_.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
      document.getElementById('btn_info_map'),
    );
    this.map_.controls[google.maps.ControlPosition.TOP_RIGHT].push(
      document.getElementById('num_markers'),
    );
    this.map_.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
      document.getElementById('embed_spotify'),
    );
    this.map_.controls[google.maps.ControlPosition.LEFT_TOP].push(
      document.getElementById('marker_justification_2'),
    );
  }

  markerDentroRectangulo() {
    this.showMarkers = false;
    this.arreglo_markers = [];
    if (this.markers && this.markers !== undefined) {
      if (this.markers.length > 0) {
        const latlng1 = new google.maps.LatLng(this.swLat, this.neLng);
        const latlng2 = new google.maps.LatLng(this.neLat, this.neLng);
        const latlng3 = new google.maps.LatLng(this.neLat, this.swLng);
        const latlng4 = new google.maps.LatLng(this.swLat, this.swLng);
        const rectangle = new google.maps.Polygon({
          paths: [latlng1, latlng2, latlng3, latlng4],
        });
        const arreglo_coincidencias = [];
        for (let i = 0; i < this.markers.length; i += 1) {
          const point_marker = new google.maps.LatLng(
            this.markers[i].lat,
            this.markers[i].long,
          );
          if (
            google.maps.geometry.poly.containsLocation(point_marker, rectangle)
          ) {
            if (this.markerFilterByGender(this.markers[i].gender)) {
              if (this.markerFilterByLanguage(this.markers[i].language[0])) {
                if (this.markerFilterByArtist(this.markers[i].artist)) {
                  if (this.notifications !== undefined) {
                    const itemChanged = this.notifications.find(
                      (item) => item.id_song === this.markers[i]._id,
                    );
                    if (itemChanged !== undefined) {
                      if (itemChanged.active) {
                        this.markers[i].isChanged = true;
                        arreglo_coincidencias.push(this.markers[i]);
                        this.arreglo_markers = arreglo_coincidencias;
                        this.num_arreglo_markers = arreglo_coincidencias.length;
                      } else {
                        this.markers[i].isChanged = false;
                        arreglo_coincidencias.push(this.markers[i]);
                        this.arreglo_markers = arreglo_coincidencias;
                        this.num_arreglo_markers = arreglo_coincidencias.length;
                      }
                    } else {
                      this.markers[i].isChanged = false;
                      arreglo_coincidencias.push(this.markers[i]);
                      this.arreglo_markers = arreglo_coincidencias;
                      this.num_arreglo_markers = arreglo_coincidencias.length;
                    }
                  } else {
                    this.markers[i].isChanged = false;
                    arreglo_coincidencias.push(this.markers[i]);
                    this.arreglo_markers = arreglo_coincidencias;
                    this.num_arreglo_markers = arreglo_coincidencias.length;
                  }
                }
              }
            }
          }
          if (this.markers.length === i + 1) {
            this.showMarkers = true;
            this.arreglo_markers = arreglo_coincidencias;
            this.num_arreglo_markers = arreglo_coincidencias.length;
            this.cd.detectChanges();
            $('#num_markers').removeClass('zoom_1');
            $('#num_markers').addClass('zoom_2');
            setTimeout(() => {
              $('#num_markers').removeClass('zoom_2');
              $('#num_markers').addClass('zoom_1');
            }, 100);
          }
        }
      }
    }
  }

  txtReason(value: string) {
    let text = '';
    if (
      value === '0'
      || value === undefined
      || value === null
      || value === ''
    ) {
      text = '';
    }
    if (value === '1') {
      this.translate.get('Mención en el título').subscribe((res) => {
        text = res;
      });
    }
    if (value === '2') {
      this.translate.get('Mención en la letra').subscribe((res) => {
        text = res;
      });
    }
    if (value === '3') {
      this.translate
        .get('Referencia a personalidad(es)/personaje(s) locales')
        .subscribe((res) => {
          text = res;
        });
    }
    if (value === '4') {
      this.translate
        .get('Referencia a acento/dialecto local')
        .subscribe((res) => {
          text = res;
        });
    }
    if (value === '5') {
      this.translate.get('Referencia a comida local').subscribe((res) => {
        text = res;
      });
    }
    if (value === '6') {
      this.translate.get('Referencia a costumbre local').subscribe((res) => {
        text = res;
      });
    }
    if (value === '7') {
      this.translate.get('Referencia a vestimenta local').subscribe((res) => {
        text = res;
      });
    }
    if (value === '8') {
      this.translate.get('Referencia a deporte local').subscribe((res) => {
        text = res;
      });
    }
    if (value === '9') {
      this.translate.get('Referencia a naturaleza local').subscribe((res) => {
        text = res;
      });
    }
    if (value === '10') {
      this.translate.get('Aparición en videoclip').subscribe((res) => {
        text = res;
      });
    }
    if (value === '11') {
      this.translate.get('Himno club local').subscribe((res) => {
        text = res;
      });
    }
    if (value === '12') {
      this.translate
        .get('Himno político/administrativo (ciudad/estado/región/país)')
        .subscribe((res) => {
          text = res;
        });
    }
    if (value === '13') {
      this.translate
        .get('Otra razón.')
        .subscribe((res) => {
          text = res;
        });
    }
    return text;
  }

  onMouseOver(infoWindowMarker, $event: MouseEvent, m) {
    if (!this.userMobile) {
      infoWindowMarker._openInfoWindow();
      this.showJustiAndQualifi(m);
    }
  }

  mouseEnter(m: any, type: string) {
    if (!this.userMobile) {
      if (type === 'listnear') {
        this.showJustiAndQualifi(m);
      } else {
        this.musicService.getMusicById(m._id.id).subscribe((mus) => {
          const res: any = mus;
          this.showJustiAndQualifi(res);
        });
      }
    }
  }

  showJustiAndQualifi(m: any) {
    if (
      m.justification[m.justification.length - 1].justification.trim() !== ''
    ) {
      let txt: string = '';
      if (m.reason.length > 0) {
        txt = "<ol style='margin-left:25px; padding-left: 0;'><li value='1'>";
        for (let index = 0; index < m.reason.length; index++) {
          const element = this.txtReason(m.reason[index]);
          if (index === 0) {
            txt = `${txt + element}</li>`;
          } else {
            txt = `${txt}<li>${element}</li>`;
          }
        }
        txt = `${txt}</ol>${m.justification[m.justification.length - 1].justification
          }`;
      } else {
        txt = m.justification[m.justification.length - 1].justification;
      }
      $('#marker_justification_2').css('display', 'block');
      $('#marker_justification').html(txt);
    } else {
      $('#marker_justification_2').css('display', 'block');
      if (m.reason.length > 0) {
        let txt: string = "<ol style='margin-left:25px; padding-left: 0;'><li value='1'>";
        for (let index = 0; index < m.reason.length; index++) {
          const element = this.txtReason(m.reason[index]);
          if (index === 0) {
            txt = `${txt + element}</li>`;
          } else {
            txt = `${txt}<li>${element}</li>`;
          }
        }
        txt += '</ol>';
        $('#marker_justification').html(txt);
      } else {
        let txt = '¡Sin Justificación! ...';
        this.translate.get(txt).subscribe((res) => {
          txt = res;
          $('#marker_justification').html(txt);
        });
      }
    }
  }

  mouseLeave() {
    $('#marker_justification').html('');
    $('#marker_justification_2').css('display', 'none');
  }

  outMouseOver(infoWindowMarker, $event: MouseEvent) {
    if (!this.userMobile) {
      infoWindowMarker._closeInfoWindow();
      $('#marker_justification').html('');
      $('#marker_justification_2').css('display', 'none');
    }
  }

  markerFilterByGender(gender_marker) {
    let sg = 'Sin Género';
    this.translate.get(sg).subscribe((res) => {
      sg = res;
    });
    let cont = 0;
    if (gender_marker.length === 0) {
      for (const itemName in this.selectedItems_gender) {
        if (this.selectedItems_gender[itemName].itemName === sg) {
          return true;
        }
      }
    }
    if (gender_marker[0] === '-' || gender_marker[0] === '') {
      for (const itemName in this.selectedItems_gender) {
        if (this.selectedItems_gender[itemName].itemName === sg) {
          return true;
        }
      }
    }

    for (let index = 0; index < gender_marker.length; index++) {
      for (const itemName in this.selectedItems_gender) {
        cont++;
        if (
          this.selectedItems_gender[itemName].itemName
          === gender_marker[index].gender
        ) {
          return true;
        }
        if (
          cont === this.selectedItems_gender.length
          && index + 1 === gender_marker.length
        ) {
          return false;
        }
      }
    }
  }

  markerFilterByLanguage(language_marker) {
    let si = 'Sin Idioma';
    this.translate.get(si).subscribe((res) => {
      si = res;
    });
    if (
      language_marker === ''
      || language_marker === undefined
      || language_marker === '-'
      || language_marker === null
      || language_marker === ' '
    ) {
      for (const itemName in this.selectedItems_lang) {
        if (
          this.selectedItems_lang[itemName].itemName === si
          || this.selectedItems_lang[itemName].itemName === undefined
        ) {
          return true;
        }
      }
    } else {
      for (const itemName in this.array_spanish) {
        if (this.array_spanish[itemName].itemName === language_marker) {
          return true;
        }
      }
    }
  }

  markerFilterByArtist(artist_marker) {
    if (
      artist_marker === ''
      || artist_marker === undefined
      || artist_marker === '-'
      || artist_marker === null
    ) {
      // por el momento todos tienen artista
    } else {
      for (const itemName in this.selectedItems_artist) {
        if (this.selectedItems_artist[itemName].itemName === artist_marker) {
          return true;
        }
      }
    }
  }

  rankingSongClic(lat_clic, lng_clic) {
    let cont = 0;
    let arreglo_markers_ranking_list = [];
    const new_array = [];
    for (let i = 0; i < this.markers.length; i++) {
      const lat2 = this.markers[i].lat;
      const lon2 = this.markers[i].long;
      const R = 6378.137;
      const dLat = (lat2 * Math.PI) / 180 - (lat_clic * Math.PI) / 180;
      const dLong = (lon2 * Math.PI) / 180 - (lng_clic * Math.PI) / 180;
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos((lat_clic * Math.PI) / 180)
        * Math.cos((lat2 * Math.PI) / 180)
        * Math.sin(dLong / 2)
        * Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      const result = Number(d.toFixed(3)) * 1000;
      if (this.markerFilterByGender(this.markers[i].gender)) {
        if (this.markerFilterByLanguage(this.markers[i].language[0])) {
          if (this.markerFilterByArtist(this.markers[i].artist)) {
            if (
              this.markers[i].link_spotify !== ''
              && this.markers[i].link_spotify !== '-'
              && this.markers[i].link_spotify !== null
              && this.markers[i].link_spotify !== undefined
            ) {
              new_array.push(this.markers[i]); // por el momento solo se agregan los que tienen algun enlace de spotify
              new_array[cont].distance = Number(result);
              cont++;
            }
          }
        }
      }
    }
    const arrayOrdered = sortData(new_array, 'distance', 'asc');
    arreglo_markers_ranking_list = arrayOrdered;

    const final_array = [];
    for (let index = 0; index < arreglo_markers_ranking_list.length; index++) {
      if (
        !final_array.find(
          (element) => element.link_spotify
            === arreglo_markers_ranking_list[index].link_spotify,
        )
      ) {
        final_array.push(arreglo_markers_ranking_list[index]); // por el momento solo se agregan los que tienen algun enlace de spotify
        if (final_array.length === 5) {
          break;
        }
      }
    }
    this.near_tracks = final_array;
  }

  rankingSong() {
    let cont = 0;
    const r = this.radio_my_position;
    this.arreglo_markers_ranking = [];
    this.markers_ranking_spotify = [];
    const new_array = [];
    // let la_ = Number(-39.825447958715564);
    // let lo_ = Number(-73.23840309574393);
    let la_ = this.centro_map_lat;
    let lo_ = this.centro_map_long;
    if (this.mi_latitud !== 0) {
      la_ = this.mi_latitud;
      lo_ = this.mi_longitud;
    }
    if (this.markers !== null && this.markers !== undefined) {
      for (let i = 0; i < this.markers.length; i++) {
        const lat2 = this.markers[i].lat;
        const lon2 = this.markers[i].long;
        const R = 6378.137;
        const dLat = (lat2 * Math.PI) / 180 - (la_ * Math.PI) / 180;
        const dLong = (lon2 * Math.PI) / 180 - (lo_ * Math.PI) / 180;
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
          + Math.cos((la_ * Math.PI) / 180)
          * Math.cos((lat2 * Math.PI) / 180)
          * Math.sin(dLong / 2)
          * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        const result = Number(d.toFixed(3)) * 1000;
        if (result <= r) {
          if (this.markerFilterByGender(this.markers[i].gender)) {
            if (this.markerFilterByLanguage(this.markers[i].language[0])) {
              if (this.markerFilterByArtist(this.markers[i].artist)) {
                new_array.push(this.markers[i]);
                new_array[cont].distance = Number(result);
                cont++;
              }
            }
          }
        }
      }
    }
    const arrayOrdered = sortData(new_array, 'distance', 'asc');
    this.arreglo_markers_ranking = arrayOrdered;

    for (let i = 0; i < this.arreglo_markers_ranking.length; i++) {
      const element = this.arreglo_markers_ranking[i];
      if (
        element.link_spotify !== ''
        && element.link_spotify !== '-'
        && element.link_spotify !== null
        && element.link_spotify !== undefined
      ) {
        this.markers_ranking_spotify.push(element.link_spotify);
      }
    }
    const unique = (value, index, self) => self.indexOf(value) === index;
    this.markers_ranking_spotify = this.markers_ranking_spotify.filter(unique);
  }

  copyInput() {
    const table = $('#table_search').DataTable();
    $('#buscador_canciones_').val(table.search());
  }

  openModalNearSongs() {
    $('#modal_footer_ranking_song').modal('open');
  }

  showEmbedSpotify() {
    if (this.show_embed) {
      this.show_embed = false;
      $('#player_window_spot').css('display', 'none');
      $('#player_window_spot').css('width', '0');
    } else {
      this.show_embed = true;
      $('#player_window_spot').css('display', 'block');
      $('#player_window_spot').css('width', '315');
    }
  }

  openModalYT() {
    $('#modal_youtube').modal({
      opacity: 0.85,
      complete() {
        const frame: any = document.getElementById('videoPlayer2');
        frame.contentWindow.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          '*',
        );
      },
    });
    $('#modal_youtube').modal('open');
  }

  msgAuth() {
    if (this.profile !== undefined && this.profile !== null) {
      this.translate
        .get('Tu próximo aporte será registrado con tu nombre de usuario')
        .subscribe((res) => {
          const toastHTML = `<span>${res}</span>`;
          M.toast({ html: toastHTML, classes: 'rounded' });
        });
    } else {
      this.translate
        .get('Inicia sesión para registrar la canción con tu nombre')
        .subscribe((res) => {
          const toastHTML = `<span>${res}</span>`;
          M.toast({ html: toastHTML, classes: 'rounded' });
        });
    }
  }

  setStateNoti(state) {
    this.stateNoti = state;
  }

  setNotifications(notifications) {
    this.notifications = notifications;
  }

  getInfoUser(user: any, type: string) {
    $('.modal').modal('close');
    $('#nick_user2').modal('open');
    this.contributions = [];
    this.res = [];
    const markers: any = JSON.parse(localStorage.getItem('markers'));
    if (type === 'id') {
      this.user.infoUser2(user.id_user).subscribe((result) => {
        this.res = result;
        if (result === null) {
          this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
            this.res = {
              type: 'anonymous',
              nickname: res_anon,
              picture:
                'https://pngimage.net/wp-content/uploads/2018/05/anonimo-png.png',
            };
          });
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === '' || element === '-' || element === 'NN') {
              this.contributions.push(markers[index]);
              this.num_contributions = this.contributions.length;
            }
            if (markers.length === index + 1) this.cd.detectChanges();
          }
        } else {
          this.res = {
            type: this.res.sub.charAt(0),
            _id: this.res._id,
            sub: this.res.sub,
            nickname: this.res.nickname,
            picture: this.res.picture,
          };
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === this.res.sub) {
              this.contributions.push(markers[index]);
              this.num_contributions = this.contributions.length;
            }
            if (markers.length === index + 1) this.cd.detectChanges();
          }
        }
      });
    } else {
      this.num_contributions = user[1];
      const nick = user[0];
      const picture = user[2];
      this.user.infoUser(nick, picture).subscribe((result) => {
        this.res = result;
        if (result === null) {
          this.res = { nickname: user[0], picture: user[2] };
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === '' || element === '-' || element === 'NN') {
              this.contributions.push(markers[index]);
            }
            if (markers.length === index + 1) this.cd.detectChanges();
          }
        } else {
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === this.res.sub) {
              this.contributions.push(markers[index]);
            }
            if (markers.length === index + 1) this.cd.detectChanges();
          }
        }
      });
    }
  }

  resetZoom() {
    this.zoom = 3;
  }
}

// tipo de marcador
interface marker {
  country: any;
  date: any;
  link_youtube: any;
  link_soundcloud: any;
  id_user: any;
  state: any;
  hear: any;
  look: any;
  favorite: any;
  justification: any;
  _id: string;
  name: string;
  place: string;
  lat: number;
  long: number;
  gender: any;
  artist: string;
  lyric: string;
  info: string;
  reason: any;
  link_spotify: string;
  language: any;
  isChanged: boolean;
}
