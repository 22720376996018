import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MusicService } from '../../services/music.service';
import { AuthService } from '../../services/auth/auth.service';
import { SpotifyService } from '../../services/spotify.service';
import { UserService } from '../../services/user.service';
import { VAR_CONFIG } from '../../services/vars';
import { LANGUAGES } from '../../../assets/languages';
import { MarkerService } from '../../services/marker.service';

declare let $: any;
declare const google: any;

@Component({
  selector: 'app-profile-music-master',
  templateUrl: './profile-music-master.component.html',
  styleUrls: ['./profile-music-master.component.css'],
  providers: [MarkerService],
})
export class ProfileMusicMasterComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  state_form: boolean = true;

  music: any = { _id: '' };

  text_lyric: string;

  text_justification: string;

  other_places: any;

  id_link_spotify: string;

  img_music: string;

  pageId: string;

  @ViewChild('search_m') public searchElement_m: ElementRef;

  lat: number = Number(-39.8142204);

  lng: number = Number(-73.2458878);

  zoom: number = 13;

  private geoCoder;

  address: string;

  click_lat: number = 0;

  click_long: number = 0;

  map_: any;

  id: string;

  profile: any;

  lat_country: number;

  lng_country: number;

  name_country: string;

  embed_link_yt: string = '';

  embed_link: string = '';

  show_embed: boolean = false;

  lang_actual: any = [];

  lang_modific: any = [];

  trans23: any;

  trans03: any;

  trans04: any;

  trans05: any;

  trans07: any;

  trans25: any;

  trans26: any;

  trans27: any;

  nickname_user: any;

  prevJustification: any;

  img_artist: any;

  stateNoti: boolean = false;

  currentuser: any;

  contributions: any[];

  res: any;

  num_contributions: any;

  dateCurrentJusti: any;

  dropdownList_lang = [];

  selectedItems_lang = [];

  dropdownSettings_lang = {};

  lang_disqus: string = localStorage.getItem('lang') || 'es';

  all_language: any = LANGUAGES.all_language;

  constructor(
    private musicService: MusicService,
    private auth: AuthService,
    private spotifyService: SpotifyService,
    private http: HttpClient,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private user: UserService,
    private markerService: MarkerService,
  ) {
    this.lang_disqus = localStorage.getItem('lang') || 'es';
  }

  esLang() {
    if (this.translate.currentLang === 'es') {
      this.translate.get('Español es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'es';
      localStorage.setItem('lang', 'es');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      );
      this.translate.use('es');
      this.translateTitles();
      $('#esL').css('font-weight', 'bold');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  ptLang() {
    if (this.translate.currentLang === 'pt') {
      this.translate.get('Portugués es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'pt';
      localStorage.setItem('lang', 'pt');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Portuguese-Brasil.json',
      );
      this.translate.use('pt');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', 'bold');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  enLang() {
    if (this.translate.currentLang === 'en') {
      this.translate.get('Inglés es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      this.lang_disqus = 'en';
      localStorage.setItem('lang', 'en');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/English.json',
      );
      this.translate.use('en');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', 'bold');
      this.translate.get('Traducción aplicada').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  saveUser() {
    let data_new_user = [];
    data_new_user = [
      {
        sub: this.profile.sub,
        given_name: this.profile.given_name,
        family_name: this.profile.family_name,
        nickname: this.profile.nickname,
        name: this.profile.name,
        picture: this.profile.picture,
        contributions: { data: '' },
        date: new Date().toString(),
        notifications: [],
        favorite: [],
        qualification: [],
      },
    ];

    this.user.ifExist(this.profile.sub).subscribe((res) => {
      const r: any = res;
      if (r === null || r.length === 0) {
        this.user.postUser(data_new_user[0]).subscribe((res) => {
          $('.favorite_song').css('background', 'black');
        });
      }
      if (this.profile) {
        this.user.searchFavorite(this.profile.sub).subscribe((res) => {
          this.translateTitles();
          const fav: any = res;
          let cont = 0;
          for (let index = 0; index < fav.length; index++) {
            const element = fav[index].id;
            if (element === this.music._id) {
              cont++;
            }
          }
          if (cont !== 0) {
            $('.favorite_song').css('background', 'red');
          } else {
            $('.favorite_song').css('background', 'black');
          }
        });
      }
    });
  }

  loadJquery() {
    $('.button-collapse').sideNav({ edge: 'left' });
    $('.modal').modal({ opacity: 0.8 });
    $('#modal_youtube').modal({
      opacity: 0.9,
    });
    $('#moda_player_window_spot').modal({
      opacity: 0.9,
    });
    $('#moda_player_window_soundcloud').modal({
      opacity: 0.9,
    });
    $('.collapsible').collapsible();
    $('.modal').scrollTop(0);
    $('.tooltipped').tooltip();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.http.get(VAR_CONFIG.url_t_s).subscribe(async (data) => {
      const var_body: any = data;
      await localStorage.setItem('token_s', var_body.body.access_token);
    });
    localStorage.removeItem('musicMarker');
    setTimeout(() => {
      this.loadJquery();
      if (localStorage.getItem('lang') === 'es') {
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      }
      if (localStorage.getItem('lang') === 'en') {
        this.translate.use('en');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', 'bold');
      }
      if (localStorage.getItem('lang') === 'pt') {
        this.translate.use('pt');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', 'bold');
        $('#engL').css('font-weight', '200');
      }
      if (
        localStorage.getItem('lang') === undefined
        || localStorage.getItem('lang') === null
      ) {
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      }
      if (this.profile) {
        this.user.searchFavorite(this.profile.sub).subscribe((res) => {
          this.translateTitles();
          const fav: any = res;
          let cont = 0;
          for (let index = 0; index < fav.length; index++) {
            const element = fav[index].id;
            if (element === this.music._id) {
              cont++;
            }
          }
          if (cont !== 0) {
            $('.favorite_song').css('background', 'red');
          } else {
            $('.favorite_song').css('background', 'black');
          }
        });
      }
    }, 1500);

    this.route.params
      .map((params) => params.id)
      .subscribe((id) => {
        this.pageId = `/${id}`;
        this.musicService.getMusicById(id).subscribe((m) => {
          if (m !== 'err' && m !== null) {
            let isValid: any = { state: false };
            isValid = m;
            if (!isValid.state) {
              window.open('/not_found/master', '_self');
            } else {
              this.music = m;
              window.document.title = `MusycGo - ${this.music.name} - ${this.music.artist}`;
              this.cargarIdioma();
              localStorage.setItem('musicMarker', JSON.stringify(m));
              if (this.music.link_spotify !== null) {
                this.getIdLinkSpotify();
                let id_link_spotify = this.music.link_spotify.split('/album/')[1];
                this.embed_link = `https://open.spotify.com/embed/album/${
                  this.music.link_spotify.split('/album/')[1]
                }`;
                if (this.music.link_spotify.split('/album/').length <= 1) {
                  id_link_spotify = this.music.link_spotify.split('/track/')[1];
                  this.embed_link = `https://open.spotify.com/embed/track/${
                    this.music.link_spotify.split('/track/')[1]
                  }`;
                }
              }
              if (this.music.link_youtube !== undefined) {
                // String regExp = "/.*(?:youtu.be\\/|v\\/|u/\\w/|embed\\/|watch\\?.*&?v=)";
                let id = '';
                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                const match = this.music.link_youtube.match(regExp);
                if (match && match[7].length === 11) {
                  id = match[7];
                }
                this.embed_link_yt = `https://www.youtube.com/embed/${id}`;
              }

              this.click_lat = this.music.lat;
              this.click_long = this.music.long;
              this.lat = this.music.lat;
              this.lng = this.music.long;
              $('#input_new_place').val('Lugar actualmente');
              this.setMusicSelected();

              this.musicService
                .getAllByNameArtist(this.music.name, this.music.artist)
                .subscribe((res) => {
                  this.other_places = res;
                  setTimeout(() => {
                    for (let index = 0; index < this.other_places.length; index++) {
                      const element = this.other_places[index];
                      if (
                        element.justification[
                          element.justification.length - 1
                        ].justification.trim() === ''
                      ) {
                        $(`#div_content_just_${index}`).html('Sin justificación');
                      } else {
                        $(`#div_content_just_${index}`).html(
                          element.justification[element.justification.length - 1]
                            .justification,
                        );
                      }
                      this.user.ifExist(element.justification[element.justification.length - 1].id_user).subscribe((res) => {
                        const res_: any = res;
                        if (res_ !== null) {
                          $(`#div_content_by_${index}`).html(res_.nickname);
                        } else {
                          this.translate
                            .get('Usuario Anónimo')
                            .subscribe((res_anon) => {
                              $(`#div_content_by_${index}`).html(res_anon);
                            });
                        }
                      });
                    }
                  }, 500);
                });
            }
          } else {
            window.open('/not_found/master', '_self');
          }
        });
      });
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            this.saveUser();
            break;
          case 'error':
            break;
        }
      }
    });
  }

  setMusicSelected() {
    if (JSON.parse(localStorage.getItem('musicMarker')) !== null) {
      this.music = JSON.parse(localStorage.getItem('musicMarker'));
      if (this.music.link_spotify !== null) {
        this.getIdLinkSpotify();
        let id_link_spotify = this.music.link_spotify.split('/album/')[1];
        this.embed_link = `https://open.spotify.com/embed/album/${
          this.music.link_spotify.split('/album/')[1]
        }`;
        if (this.music.link_spotify.split('/album/').length <= 1) {
          id_link_spotify = this.music.link_spotify.split('/track/')[1];
          this.embed_link = `https://open.spotify.com/embed/track/${
            this.music.link_spotify.split('/track/')[1]
          }`;
        }
      }
      if (this.music.link_youtube !== undefined) {
        // String regExp = "/.*(?:youtu.be\\/|v\\/|u/\\w/|embed\\/|watch\\?.*&?v=)";
        let id = '';
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        const match = this.music.link_youtube.match(regExp);
        if (match && match[7].length === 11) {
          id = match[7];
        }
        this.embed_link_yt = `https://www.youtube.com/embed/${id}`;
      }
      this.click_lat = this.music.lat;
      this.click_long = this.music.long;
      this.lat = this.music.lat;
      this.lng = this.music.long;
      if (
        this.music.justification !== ''
        && this.music.justification[this.music.justification.length - 1]
          .justification !== ''
      ) {
        $('#justification_document').html(
          this.music.justification[this.music.justification.length - 1]
            .justification,
        );
      }
      this.text_lyric = this.music.lyric;
      this.text_justification = this.music.justification[
        this.music.justification.length - 1
      ].justification;
      $('#input_new_place').val('Lugar actualmente');
      setTimeout(() => {
        $('#lyric_document').html(this.music.lyric);
        this.prevJustification = [];
        if (this.music.justification.length > 0) {
          this.prevJustification = this.music.justification;
          for (
            let index = 0;
            index < this.prevJustification.length - 1;
            index++
          ) {
            const element = this.prevJustification[index];
            $(`#justification_document_${index}`).html(element.justification);
            this.user
              .ifExist(this.prevJustification[index].id_user)
              .subscribe((res) => {
                const res_: any = res;
                if (res_ !== null) {
                  $(`#user_just_${index}`).html(res_.nickname);
                } else {
                  this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
                    $(`#user_just_${index}`).html(res_anon);
                  });
                }
              });
          }
        }
        this.user.ifExist(this.music.id_user.trim()).subscribe((res) => {
          const res_: any = res;
          if (res_ !== null) {
            this.nickname_user = res_.nickname;
          } else {
            this.translate.get('Usuario Anónimo').subscribe((res_anon) => {
              this.nickname_user = res_anon;
            });
          }
        });
      }, 1000);
    } else {
      window.open('/', '_self');
    }
  }

  setMusicProfile(music: any) {
    this.musicService.setMusicSelected(music);
  }

  getIdLinkSpotify() {
    this.img_music = 'https://www.musycgo.com/icon/marker.png';
    $('.avatar').css('border', 'none');
    this.id_link_spotify = this.music.link_spotify.split('/album/')[1];
    if (this.music.link_spotify.split('/album/').length <= 1) {
      this.id_link_spotify = this.music.link_spotify.split('/track/')[1];
    }
    if (this.id_link_spotify !== null) {
      this.spotifyService
        .getInfoTrackById(this.id_link_spotify)
        .subscribe((res) => {
          this.img_music = res.album.images[0].url;
          this.img_artist = this.img_music;
          $('.avatar').css('border', 'solid 2px white');
          this.spotifyService.getArtist(res.artists[0].id).subscribe(
            (res2) => {
              if (res2.images.length > 0) {
                this.img_artist = res2.images[0].url;
              }
            },
            (error) => {
              this.img_music = 'https://www.musycgo.com/icon/marker.png';
              $('.avatar').css('border', 'none');
              this.http.get(VAR_CONFIG.url_n_t_s).subscribe(async (data) => {
                const var_body: any = data;
                await localStorage.setItem(
                  'token_s',
                  var_body.body.access_token,
                );
              });
            },
          );
        });
    }
  }

  openModalYT() {
    $('#modal_youtube').modal({
      opacity: 0.9,
      complete() {
        $('.videoPlayer')[0].contentWindow.postMessage(
          '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
          '*',
        );
      },
    });
    $('#modal_youtube').modal('open');
  }

  openModalSpot() {
    $('#moda_player_window_spot').modal('open');
  }

  openModalSoundCloud() {
    $('#moda_player_window_soundcloud').modal('open');
    if (this.music.link_soundcloud !== undefined) {
      const settings = {
        async: true,
        crossDomain: true,
        url: 'http://soundcloud.com/oembed',
        method: 'POST',
        headers: {},
        data: {
          format: 'json',
          url: this.music.link_soundcloud,
        },
      };
      $.ajax(settings).done((response) => {
        $('#div_embed_soundcloud').html(response.html);
      });
    }
  }

  activecCollap() {
    $('.collapsible').collapsible();
  }

  translateTitles() {
    this.translate.get('Volver').subscribe((res) => {
      this.trans23 = res;
      $('#trans23').attr('title', this.trans23);
    });
    this.translate.get('Cambiar al Idioma Español').subscribe((res) => {
      this.trans03 = res;
      $('#trans03').attr('title', this.trans03);
    });
    this.translate.get('Cambiar al Idioma Inglés').subscribe((res) => {
      this.trans04 = res;
      $('#trans04').attr('title', this.trans04);
    });
    this.translate.get('Cambiar al Idioma Portugués').subscribe((res) => {
      this.trans05 = res;
      $('#trans05').attr('title', this.trans05);
    });
    this.translate.get('Identificarse').subscribe((res) => {
      this.trans07 = res;
      $('#trans07').attr('title', this.trans07);
    });
    this.translate.get('Guardar en Favoritos').subscribe((res) => {
      this.trans25 = res;
      $('#trans25').attr('title', this.trans25);
    });
    this.translate.get('Visitas al Perfil').subscribe((res) => {
      this.trans26 = res;
      $('#trans26').attr('title', this.trans26);
    });
    this.translate.get('Reproducciones en Spotify').subscribe((res) => {
      this.trans27 = res;
      $('#trans27').attr('title', this.trans27);
    });
  }

  setStateNoti(state) {
    this.stateNoti = state;
  }

  actionFavorite() {
    if (this.profile === undefined) {
      let btn_1 = 'Cancelar';
      let btn_2 = 'Iniciar Sesión';
      this.translate.get(btn_1).subscribe((res) => {
        btn_1 = res;
      });
      this.translate.get(btn_2).subscribe((res) => {
        btn_2 = res;
      });
      this.translate
        .get('Debe iniciar sesión para guardar en favoritos')
        .subscribe((res) => {
          swal({
            text: res,
            icon: 'warning',
            buttons: [btn_1, btn_2],
          }).then((value) => {
            if (value) {
              localStorage.setItem('last_url', window.location.href);
              this.auth.auth0.authorize();
            }
          });
        });
    } else {
      $('.favorite_song').attr('disabled', true);
      $('#trans25').css('color', 'red');
      setTimeout(() => {
        $('.favorite_song').attr('disabled', false);
        $('#trans25').css('color', 'white');
      }, 2500);
      this.user.ifExist(this.profile.sub).subscribe((res) => {
        const res_: any = res;
        this.currentuser = res_;
        let other_places: any;
        const other_places_id = [];
        const new_array_fav: any = this.currentuser.favorite;
        this.musicService
          .getAllByNameArtist(this.music.name, this.music.artist)
          .subscribe((res) => {
            other_places = res;
            for (let index = 0; index < other_places.length; index++) {
              other_places_id.push(other_places[index]._id);
            }
            let index_find;
            for (let index = 0; index < other_places_id.length; index++) {
              const element = other_places_id[index];
              index_find = new_array_fav.findIndex((x) => x.id === element);
              if (index_find !== -1) {
                // quitar
                $('.favorite_song').css('background', 'black');
                this.updateLooks(other_places[index], 'favorite_remove');
                new_array_fav.splice(index_find, 1);
              } else {
                // agregar
                $('.favorite_song').css('background', 'red');
                this.updateLooks(other_places[index], 'favorite_add');
                new_array_fav.push({
                  id: element,
                  date_in: new Date().toString(),
                });
              }
              if (other_places_id.length === index + 1) {
                // setTimeout(() => {
                const upd_fav = [
                  {
                    _id: this.currentuser._id,
                    sub: this.currentuser.sub,
                    picture: this.currentuser.picture,
                    nickname: this.currentuser.nickname,
                    name: this.currentuser.name,
                    given_name: this.currentuser.given_name,
                    favorite: new_array_fav,
                    family_name: this.currentuser.family_name,
                    contributions: this.currentuser.contributions,
                    date: this.currentuser.date,
                    notifications: this.currentuser.notifications,
                    qualification: this.currentuser.qualification,
                  },
                ];
                this.user.putUser(upd_fav[0]).subscribe((res) => {
                  this.currentuser = upd_fav[0];
                });
              // }, 1000);
              }
            }
          });
      });
    }
  }

  updateLooks(music, action) {
    let num_favorite = music.favorite;
    if (action === 'favorite_add') {
      if (music.favorite === null) {
        num_favorite = 1;
      } else {
        num_favorite = music.favorite + 1;
      }
    }
    if (action === 'favorite_remove') {
      if (music.favorite === null) {
        num_favorite = 0;
      } else {
        num_favorite = music.favorite - 1;
      }
    }
    const look_for_update = [
      {
        _id: music._id,
        name: music.name,
        artist: music.artist,
        gender: music.gender,
        language: music.language,
        lyric: music.lyric,
        reason: music.reason,
        justification: music.justification,
        look: music.look,
        hear: music.hear,
        favorite: num_favorite,
        lat: music.lat,
        long: music.long,
        place: music.place,
        country: music.country,
        state: music.state,
        info: music.info,
        id_user: music.id_user,
        link_spotify: music.link_spotify,
        link_youtube: music.link_youtube,
        link_soundcloud: music.link_soundcloud,
        date: music.date,
      },
    ];
    this.musicService.putMusic(look_for_update[0]).subscribe((res) => {
      if (look_for_update[0]._id === this.music._id) {
        localStorage.removeItem('musicMarker');
        localStorage.setItem('musicMarker', JSON.stringify(look_for_update[0]));
        this.setMusicSelected();
        window.scrollTo(0, 0);
        localStorage.removeItem('markers');
        this.markerService.init();
      }
    });
  }

  cerrarModal() {
    $('.modal').modal('close');
  }

  getInfoUser(user: any, type: string) {
    this.contributions = [];
    this.res = [];
    const markers: any = JSON.parse(localStorage.getItem('markers'));
    if (type === 'id') {
      this.user.infoUser2(user.id_user).subscribe((result) => {
        this.res = result;
        if (result === null) {
          this.translate
            .get('Usuario Anónimo')
            .subscribe((res_anon) => {
              this.res = { type: 'anonymous', nickname: res_anon, picture: 'https://pngimage.net/wp-content/uploads/2018/05/anonimo-png.png' };
            });
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === '' || element === '-' || element === 'NN') {
              this.contributions.push(markers[index]);
              this.num_contributions = this.contributions.length;
            }
          }
        } else {
          this.res = {
            type: this.res.sub.charAt(0),
            _id: this.res._id,
            sub: this.res.sub,
            nickname: this.res.nickname,
            picture: this.res.picture,
          };
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === this.res.sub) {
              this.contributions.push(markers[index]);
              this.num_contributions = this.contributions.length;
            }
          }
        }
      });
    } else {
      this.num_contributions = user[1];
      const nick = user[0];
      const picture = user[2];
      this.user.infoUser(nick, picture).subscribe((result) => {
        this.res = result;
        if (result === null) {
          this.res = { nickname: user[0], picture: user[2] };
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === '' || element === '-' || element === 'NN') {
              this.contributions.push(markers[index]);
            }
          }
        } else {
          for (let index = 0; index < markers.length; index++) {
            const element = markers[index].id_user;
            if (element === this.res.sub) {
              this.contributions.push(markers[index]);
            }
          }
        }
      });
    }
  }

  cargarIdioma() {
    this.dropdownList_lang = [];
    this.lang_actual = [];
    this.lang_modific = [];
    this.selectedItems_lang = [];

    let index_lang = 0;
    for (const language in this.all_language) {
      if (this.lang_disqus === 'es') {
        if (this.all_language[language].lang_es !== undefined) {
          this.dropdownList_lang.push({
            item_id: index_lang,
            item_text: this.all_language[language].lang_es,
          });
          for (let index = 0; index < this.music.language.length; index++) {
            const element = this.music.language[index];
            if (
              element === this.all_language[language].lang_es
              || element === this.all_language[language].lang_en
              || element === this.all_language[language].lang_pt
            ) {
              this.selectedItems_lang.push({
                item_id: index_lang,
                item_text: this.all_language[language].lang_es,
              });
              this.lang_actual.push({
                item_id: index_lang,
                item_text: element,
              });
              this.lang_modific.push({
                item_id: index_lang,
                item_text: element,
              });
            }
          }
        }
      }
      if (this.lang_disqus === 'en') {
        if (this.all_language[language].lang_en !== undefined) {
          this.dropdownList_lang.push({
            item_id: index_lang,
            item_text: this.all_language[language].lang_en,
          });
          for (let index = 0; index < this.music.language.length; index++) {
            const element = this.music.language[index];
            if (
              element === this.all_language[language].lang_es
              || element === this.all_language[language].lang_en
              || element === this.all_language[language].lang_pt
            ) {
              this.selectedItems_lang.push({
                item_id: index_lang,
                item_text: this.all_language[language].lang_en,
              });
              this.lang_actual.push({
                item_id: index_lang,
                item_text: element,
              });
              this.lang_modific.push({
                item_id: index_lang,
                item_text: element,
              });
            }
          }
        }
      }
      if (this.lang_disqus === 'pt') {
        if (this.all_language[language].lang_pt !== undefined) {
          this.dropdownList_lang.push({
            item_id: index_lang,
            item_text: this.all_language[language].lang_pt,
          });
          for (let index = 0; index < this.music.language.length; index++) {
            const element = this.music.language[index];
            if (
              element === this.all_language[language].lang_es
              || element === this.all_language[language].lang_en
              || element === this.all_language[language].lang_pt
            ) {
              this.selectedItems_lang.push({
                item_id: index_lang,
                item_text: this.all_language[language].lang_pt,
              });
              this.lang_actual.push({
                item_id: index_lang,
                item_text: element,
              });
              this.lang_modific.push({
                item_id: index_lang,
                item_text: element,
              });
            }
          }
        }
      }
      index_lang++;
    }
    let txt = 'Buscar';
    this.translate.get(txt).subscribe((res) => {
      txt = res;
    });
    this.dropdownSettings_lang = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: false,
      clearSearchFilter: true,
      maxHeight: 300,
      limitSelection: 5,
      defaultOpen: true,
      searchPlaceholderText: txt,
    };
  }

  cleanForm() {
    $('#new_lyric').val(null);
    $('#new_youtube').val(null);
    $('#new_soundcloud').val(null);
    $('#new_lang').val(null);

    $('#new_lyric').removeClass('valid invalide');
    $('#new_lang').removeClass('valid invalide');
    $('#new_youtube').removeClass('valid invalide');
    $('#new_soundcloud').removeClass('valid invalide');

    $('label').removeClass('active');
    $('.collapsible-body').css('display', 'none');
    $('#bodyupdform').css('display', 'none');
    $('li').removeClass('active');
  }

  updateSong(music) {
    let onlyThisPlace = true;
    let borrar = false;
    const array_lang_actual = [];
    let array_lang_modific = [];
    let array_lang_merge = [];
    let array_lang_unique = [];
    for (let index = 0; index < this.lang_actual.length; index++) {
      const element = this.lang_actual[index].item_text;
      array_lang_actual.push(element);
    }
    for (let index = 0; index < this.lang_modific.length; index++) {
      const element = this.lang_modific[index].item_text;
      array_lang_modific.push(element);
    }

    if (this.lang_disqus !== 'es') {
      const new_array = [];
      const array_length = array_lang_modific.length;
      for (let index = 0; index < array_lang_modific.length; index++) {
        for (const language in this.all_language) {
          const element = array_lang_modific[index];
          if (
            element === this.all_language[language].lang_es
            || element === this.all_language[language].lang_en
            || element === this.all_language[language].lang_pt
          ) {
            new_array.push(this.all_language[language].lang_es);
          }
        }
        if (index === array_length - 1) {
          array_lang_modific = new_array;
        }
      }
    }
    const upd_form_data = {
      lyric: this.text_lyric,
      language: array_lang_modific,
      youtube: $('#new_youtube').val(),
      soundcloud: $('#new_soundcloud').val(),
    };

    const music_for_update = [
      {
        _id: music._id,
        name: music.name,
        artist: music.artist,
        gender: music.gender,
        language: music.language,
        lyric: music.lyric,
        reason: music.reason,
        justification: music.justification,
        look: music.look,
        hear: music.hear,
        favorite: music.favorite,
        lat: music.lat,
        long: music.long,
        place: music.place,
        country: music.country,
        state: music.state,
        info: music.info,
        id_user: music.id_user,
        link_spotify: music.link_spotify,
        link_youtube: music.link_youtube,
        link_soundcloud: music.link_soundcloud,
        date: music.date,
      },
    ];

    const unique = (value, index, self) => self.indexOf(value) === index;
    array_lang_merge = array_lang_actual.concat(array_lang_modific);
    array_lang_unique = array_lang_merge.filter(unique);
    if (
      array_lang_actual.length !== array_lang_unique.length
      || array_lang_modific.length !== array_lang_unique.length
    ) {
      borrar = true;
      onlyThisPlace = false;
      music_for_update[0].language = upd_form_data.language;
    } else {
      delete upd_form_data.language;
    }
    if (upd_form_data.lyric) {
      if ($.trim(String(upd_form_data.lyric)) === this.music.lyric.trim()) {
        delete upd_form_data.lyric;
      } else {
        borrar = true;
        onlyThisPlace = false;
        music_for_update[0].lyric = upd_form_data.lyric;
      }
    }
    if (upd_form_data.youtube) {
      if ($.trim(String(upd_form_data.youtube)) === '') {
        delete upd_form_data.youtube;
      } else {
        borrar = true;
        onlyThisPlace = false;
        music_for_update[0].link_youtube = upd_form_data.youtube;
      }
    }

    if (upd_form_data.soundcloud) {
      if ($.trim(String(upd_form_data.soundcloud)) === '') {
        delete upd_form_data.soundcloud;
      } else {
        borrar = true;
        onlyThisPlace = false;
        music_for_update[0].link_soundcloud = upd_form_data.soundcloud;
      }
    }

    if (borrar) {
      let txt = '¿Aceptas la modificación?';
      let btn_1 = 'Cancelar';
      let btn_2 = 'Continuar';
      this.translate.get(txt).subscribe((res) => {
        txt = res;
      });
      this.translate.get(btn_1).subscribe((res) => {
        btn_1 = res;
      });
      this.translate.get(btn_2).subscribe((res) => {
        btn_2 = res;
      });
      swal({
        text: txt,
        icon: 'warning',
        buttons: [btn_1, btn_2],
      }).then((value) => {
        if (value) {
          this.updateOtherPlaces();
          localStorage.removeItem('musicMarker');
          localStorage.setItem(
            'musicMarker',
            JSON.stringify(music_for_update[0]),
          );
        } else {
          this.translate.get('Operación cancelada!').subscribe((res) => {
            this.translate.get('Ups!').subscribe((title) => {
              swal(title, res, 'error');
            });
          });
        }
      });
    } else {
      this.translate
        .get('Sin cambios en los datos ingresados!')
        .subscribe((res) => {
          this.translate.get('Ups!').subscribe((title) => {
            swal(title, res, 'warning');
          });
        });
    }
  }

  updateOtherPlaces() {
    let array_lang_modific = [];
    for (let index = 0; index < this.lang_modific.length; index++) {
      const element = this.lang_modific[index].item_text;
      array_lang_modific.push(element);
    }
    if (this.lang_disqus !== 'es') {
      const new_array = [];
      const array_length = array_lang_modific.length;
      for (let index = 0; index < array_lang_modific.length; index++) {
        for (const language in this.all_language) {
          const element = array_lang_modific[index];
          if (
            element === this.all_language[language].lang_es
            || element === this.all_language[language].lang_en
            || element === this.all_language[language].lang_pt
          ) {
            new_array.push(this.all_language[language].lang_es);
          }
        }
        if (index === array_length - 1) {
          array_lang_modific = new_array;
        }
      }
    }
    const upd_data_other_places = [
      {
        lyric: this.text_lyric,
        link_youtube: $('#new_youtube').val(),
        link_soundcloud: $('#new_soundcloud').val(),
        language: array_lang_modific,
      },
    ];
    for (const clave in upd_data_other_places[0]) {
      if (
        clave === 'lyric'
        || clave === 'link_youtube'
        || clave === 'link_soundcloud'
      ) {
        if (upd_data_other_places[0][clave].trim() === '') {
          delete upd_data_other_places[0][clave];
        }
      }
    }
    for (let i = 0; i < this.other_places.length; i++) {
      const element = this.other_places[i];
      const for_update = [
        {
          _id: element._id,
          name: element.name,
          artist: element.artist,
          gender: element.gender,
          language: element.language,
          lyric: element.lyric,
          reason: element.reason,
          justification: element.justification,
          look: element.look,
          hear: element.hear,
          favorite: element.favorite,
          lat: element.lat,
          long: element.long,
          place: element.place,
          country: element.country,
          state: element.state,
          info: element.info,
          id_user: element.id_user,
          link_spotify: element.link_spotify,
          link_youtube: element.link_youtube,
          link_soundcloud: element.link_soundcloud,
          date: element.date,
        },
      ];

      for (const clave in upd_data_other_places[0]) {
        for_update[0][clave] = upd_data_other_places[0][clave];
      }
      this.musicService.putMusic(for_update[0]).subscribe((res) => { });
      if (i === this.other_places.length - 1) {
        const us: any = [];
        for (let index = 0; index < this.other_places.length; index++) {
          const element = this.other_places[index];
          us.push(element.id_user);
        }
        let cont = 0;
        this.user.getUsersFromAnAPI(us).subscribe(
          (response) => {
            let notifi: any = [];
            let updcolabuser: any = [];
            if (response !== null && response !== undefined) {
              notifi = response.notifications;
              if (response.notifications !== null && response.notifications !== []) {
                notifi.push({
                  id_song: this.other_places[cont]._id,
                  name: this.other_places[cont].name,
                  artist: this.other_places[cont].artist,
                  place: this.other_places[cont].place,
                  date: new Date().toString(),
                  who: this.profile.sub,
                  whonick: this.profile.nickname,
                  active: true,
                });
                updcolabuser = [
                  {
                    _id: response._id,
                    sub: response.sub,
                    picture: response.picture,
                    nickname: response.nickname,
                    name: response.name,
                    given_name: response.given_name,
                    favorite: response.favorite,
                    family_name: response.family_name,
                    contributions: response.contributions,
                    date: response.date,
                    notifications: notifi,
                    qualification: response.qualification,
                  },
                ];
                this.user.putUser(updcolabuser[0]).subscribe((res) => {
                });
              } else {
                notifi = {
                  id_song: this.other_places[cont]._id,
                  name: this.other_places[cont].name,
                  artist: this.other_places[cont].artist,
                  place: this.other_places[cont].place,
                  date: new Date().toString(),
                  who: this.profile.sub,
                  whonick: this.profile.nickname,
                  active: true,
                };
                updcolabuser = [
                  {
                    _id: response._id,
                    sub: response.sub,
                    picture: response.picture,
                    nickname: response.nickname,
                    name: response.name,
                    given_name: response.given_name,
                    favorite: response.favorite,
                    family_name: response.family_name,
                    contributions: response.contributions,
                    date: response.date,
                    notifications: notifi,
                    qualification: response.qualification,
                  },
                ];
                this.user.putUser(updcolabuser[0]).subscribe((res) => { });
              }
            }
            if (cont === us.length - 1) {
              this.cleanForm();
              this.setMusicSelected();
              window.scrollTo(0, 0);
              this.translate
                .get('Canción modificada correctamente!')
                .subscribe((res) => {
                  this.translate.get('Genial!').subscribe((title) => {
                    swal(title, res, 'success');
                  });
                });
              localStorage.removeItem('markers');
              this.markerService.init();
              this.cargarIdioma();
            }
            cont++;
          },
          (error) => { },
        );
      }
    }
  }

  onItemSelect(item: any) {
    this.lang_modific = [];
    if (this.selectedItems_lang.length > 0) {
      for (let index = 0; index < this.selectedItems_lang.length; index++) {
        const element = this.selectedItems_lang[index];
        this.lang_modific.push({
          item_id: element.item_id,
          item_text: element.item_text,
        });
      }
    }
  }

  onItemDeSelect(item: any) {
    this.lang_modific = [];
    if (this.selectedItems_lang.length > 0) {
      for (let index = 0; index < this.selectedItems_lang.length; index++) {
        const element = this.selectedItems_lang[index];
        this.lang_modific.push({
          item_id: element.item_id,
          item_text: element.item_text,
        });
      }
    }
  }
}
