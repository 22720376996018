import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterializeModule } from 'angular2-materialize';
import { DataTablesModule } from 'angular-datatables';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { DisqusModule } from 'ngx-disqus';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { EditorModule } from 'primeng/primeng';
import { AgmMarkerSpiderModule } from 'agm-oms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgWordCloudModule, AgWordCloudData } from 'angular4-word-cloud';
import { AppComponent } from './app.component';
import { SpotifyService } from './services/spotify.service';
import { AppMapComponent } from './app-map/app-map.component';
import { UserService } from './services/user.service';
import { InputSongComponent } from './components/input-song/input-song.component';
import { AppRoutingModule } from './app.routing';
import { AuthService } from './services/auth/auth.service';
import { LoginAuthComponent } from './components/login-auth/login-auth.component';
import { SafePipe } from './pipe/safe.pipe';
import { SearchSongComponent } from './components/searcher/search-song/search-song.component';
import { DateTimePipe } from './pipe/datetime.pipe';
import { LinkConverted } from './pipe/linkconverted.pipe';
import { UserloggedComponent } from './profiles/user-logged/userlogged.component';
import { Site404Component } from './components/site404/site404.component';
import { MinimalSearcherComponent } from './components/searcher/minimal-searcher/minimal-searcher.component';
import { ProfilemusicComponent } from './profiles/profile-music/profilemusic.component';
import { DistancePipe } from './pipe/distance.pipe';
import { FullnamecountryPipe } from './pipe/fullnamecountry.pipe';
import { RepetitionsongPipe } from './pipe/repetitionsong.pipe';
import { ProfileMusicMasterComponent } from './profiles/profile-music-master/profile-music-master.component';
import { ChartComponent } from './components/chart/chart.component';
import { ReasonPipe } from './pipe/reason.pipe';
import { GetflagPipe } from './pipe/getflag.pipe';
import { NotificationsComponent } from './components/notifications/notifications/notifications.component';
import { UserComponent } from './profiles/user/user.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

enableProdMode();

@NgModule({
  imports: [
    AngularMultiSelectModule,
    MaterializeModule,
    EditorModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    HttpModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AgmJsMarkerClustererModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDgRYk23dltT8wRcPAdfBQ7fXmGjHStJsg',
      libraries: ['places'],
      language: localStorage.getItem('lang') || 'es', // 'pt-br' 'en' 'es'
      apiVersion: 'quarterly',
    }),
    AgmSnazzyInfoWindowModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    DataTablesModule,
    JwSocialButtonsModule,
    DisqusModule.forRoot('www-musycgo-com'),
    // MusycGo-com
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AgmMarkerSpiderModule,
    NgxSpinnerModule,
    AgWordCloudModule.forRoot(),
  ],
  providers: [SpotifyService,
    UserService,
    AuthService,
    [{ provide: LOCALE_ID, useValue: localStorage.getItem('lang') || 'es' }],
  ],
  declarations: [AppComponent,
    AppMapComponent,
    InputSongComponent,
    LoginAuthComponent,
    SafePipe,
    SearchSongComponent,
    DateTimePipe,
    LinkConverted,
    UserloggedComponent,
    Site404Component,
    MinimalSearcherComponent,
    ProfilemusicComponent,
    DistancePipe,
    FullnamecountryPipe,
    RepetitionsongPipe,
    ProfileMusicMasterComponent,
    ChartComponent,
    ReasonPipe,
    GetflagPipe,
    NotificationsComponent,
    UserComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
