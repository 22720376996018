import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user.service';
import { MusicService } from '../../services/music.service';
import { MarkerService } from '../../services/marker.service';
import 'materialize-css';
import { Subscription } from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [UserService, MarkerService],
})
export class UserComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  profile: any;

  collaboration: any;

  trans03: any;

  trans04: any;

  trans05: any;

  trans23: any;

  pageId: string;

  user: Object;

  contributions: any[];

  res: any;

  num_contributions: number;

  stateNoti: boolean = false;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private musicService: MusicService,
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  esLang() {
    if (this.translate.currentLang === 'es') {
      this.translate.get('Español es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      localStorage.setItem('lang', 'es');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
      );
      this.translate.use('es');
      this.translateTitles();
      $('#esL').css('font-weight', 'bold');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada.').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  ptLang() {
    if (this.translate.currentLang === 'pt') {
      this.translate.get('Portugués es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      localStorage.setItem('lang', 'pt');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/Portuguese-Brasil.json',
      );
      this.translate.use('pt');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', 'bold');
      $('#engL').css('font-weight', '200');
      this.translate.get('Traducción aplicada.').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  enLang() {
    if (this.translate.currentLang === 'en') {
      this.translate.get('Inglés es el idioma actual.').subscribe((res) => {
        swal('', res, 'warning', { buttons: [false], timer: 1500 });
      });
    } else {
      localStorage.setItem('lang', 'en');
      localStorage.setItem(
        'lang_dt',
        '//cdn.datatables.net/plug-ins/1.10.16/i18n/English.json',
      );
      this.translate.use('en');
      this.translateTitles();
      $('#esL').css('font-weight', '200');
      $('#porL').css('font-weight', '200');
      $('#engL').css('font-weight', 'bold');
      this.translate.get('Traducción aplicada.').subscribe((res) => {
        swal('', res, 'success', { buttons: [false], timer: 1500 });
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    $('#loginuser').css('display', 'none');
    $('.collapsible').collapsible();
    $('.collapsible').collapsible('open');
    setTimeout(() => {
      if (localStorage.getItem('lang') === 'es') {
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      } else if (localStorage.getItem('lang') === 'en') {
        this.translate.use('en');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', 'bold');
      } else if (localStorage.getItem('lang') === 'pt') {
        this.translate.use('pt');
        this.translateTitles();
        $('#esL').css('font-weight', '200');
        $('#porL').css('font-weight', 'bold');
        $('#engL').css('font-weight', '200');
      } else {
        this.translate.use('es');
        this.translateTitles();
        $('#esL').css('font-weight', 'bold');
        $('#porL').css('font-weight', '200');
        $('#engL').css('font-weight', '200');
      }
      setTimeout(() => {
        if (this.profile !== undefined) {
          this.saveUser();
        }
      }, 1000);
    }, 2000);
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            this.saveUser();
            break;
          case 'error':
            break;
        }
      }
    });

    this.route.params
      .subscribe((response) => {
        this.pageId = `/${response.nick}`;
        this.getInfoUser(response, 'nick');
      });
  }

  getInfoUser(user: any, type: string) {
    this.contributions = [];
    this.res = [];
    const markers: any = JSON.parse(localStorage.getItem('markers'));
    if (type === 'id') {
      this.userService.infoUserById(user).subscribe((result) => {
        if (result !== 'err') {
          this.res = result;
          if (result === null) {
            window.open('/not_found/user', '_self');
          } else {
            window.document.title = `MusycGo - ${this.res.nickname}`;
            this.res = {
              // type: this.res.sub.substring(0, this.res.sub.indexOf('|')),
              type: this.res.sub.charAt(0),
              _id: this.res._id,
              sub: this.res.sub,
              nickname: this.res.nickname,
              picture: this.res.picture,
            };
            for (let index = 0; index < markers.length; index++) {
              const element = markers[index].id_user;
              if (element === this.res.sub) {
                this.contributions.push(markers[index]);
                this.num_contributions = this.contributions.length;
              }
            }
          }
        } else {
          window.open('/not_found/user', '_self');
        }
      });
    }
    if (type === 'nick') {
      this.userService.infoUserByNick(user.nick, user.type).subscribe((result) => {
        if (result !== 'err') {
          this.res = result;
          if (result === null) {
            window.open('/not_found/user', '_self');
          } else {
            window.document.title = `MusycGo - ${this.res.nickname}`;
            this.res = {
              type: user.type,
              _id: this.res._id,
              sub: this.res.sub,
              nickname: this.res.nickname,
              picture: this.res.picture,
            };
            for (let index = 0; index < markers.length; index++) {
              const element = markers[index].id_user;
              if (element === this.res.sub) {
                this.contributions.push(markers[index]);
                this.num_contributions = this.contributions.length;
              }
            }
          }
        } else {
          window.open('/not_found/user', '_self');
        }
      });
    }
  }

  loadJquery() {
    $('.modal').modal({ opacity: 0.8 });
    $('.collapsible').collapsible();
    $('.modal').scrollTop(0);
    $('.tooltipped').tooltip();
  }

  saveUser() {
    let data_new_user = [];
    data_new_user = [
      {
        sub: this.profile.sub,
        given_name: this.profile.given_name,
        family_name: this.profile.family_name,
        nickname: this.profile.nickname,
        name: this.profile.name,
        picture: this.profile.picture,
        contributions: { data: '' },
        notifications: [],
        favorite: [],
        date: new Date().toString(),
        qualification: [],
      },
    ];

    this.userService.ifExist(this.profile.sub).subscribe((res) => {
      const r: any = res;
      if (r === null || r.length === 0) {
        this.userService.postUser(data_new_user[0]).subscribe((res) => {});
      }
    });
  }

  setMusicProfile(music: any) {
    this.musicService.setMusicSelected(music);
  }

  translateTitles() {
    this.translate.get('Volver').subscribe((res) => {
      this.trans23 = res;
      $('#trans23').attr('title', this.trans23);
    });
    this.translate.get('Cambiar al Idioma Español').subscribe((res) => {
      this.trans03 = res;
      $('#trans03').attr('title', this.trans03);
    });
    this.translate.get('Cambiar al Idioma Inglés').subscribe((res) => {
      this.trans04 = res;
      $('#trans04').attr('title', this.trans04);
    });
    this.translate.get('Cambiar al Idioma Portugués').subscribe((res) => {
      this.trans05 = res;
      $('#trans05').attr('title', this.trans05);
    });
  }

  setStateNoti(state) {
    this.stateNoti = state;
  }
}
