import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import 'materialize-css';
import { Subject } from 'rxjs';
import { AppMapComponent } from '../../../app-map/app-map.component';
import { MusicService } from '../../../services/music.service';

declare const google: any;

@Component({
  selector: 'app-minimal-searcher',
  templateUrl: './minimal-searcher.component.html',
  styleUrls: ['./minimal-searcher.component.css'],
})
export class MinimalSearcherComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  dtTrigger: Subject<any> = new Subject();

  search_in: string;

  search_out: Array<any>;

  map: any;

  private _reloadDT: boolean;

  @Input()
  set reloadDT(value: boolean) {
    value = value || false;
    if (value === true) {
      this._reloadDT = value;
      this.searchSongDB();
    }
  }

  constructor(private musicService: MusicService, private appMapComponent: AppMapComponent, public translate: TranslateService) { }

  ngOnInit() {
    this.map = this.appMapComponent.map_;
    this.dtOptions = {
      // "responsive": true,
      search: {
        search: '',
      },
      pagingType: 'full_numbers',
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'TODO']],
      pageLength: 15,
      paging: true,
      ordering: true,
      info: true,
      language: { url: localStorage.getItem('lang_dt') || '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json' },
      // "dom": 'Bifrtp',
      dom: '<f<t>rip>',
      order: [[0, 'asc']],
      scrollY: '300px',
      scrollCollapse: true,
      columnDefs: [
        {
          targets: '_all',
          className: 'dt-body-center',
        },
      ],
    };
    $('input').focus();
    this.searchSongDB();
  }

  searchSongDB() {
    this.search_out = [];
    let search: string;
    search = '';
    this.musicService.getMusics()
      .subscribe((res) => {
        for (const name in res) {
          if (res.hasOwnProperty(name)) {
            const element = res[name];
            if (element.name.toLowerCase().indexOf(search) > -1 && element.state === true) {
              this.search_out.push(element);
            }
          }
        }
      });
  }

  selectedSongTable(id: string, la: number, lo: number, na: string) {
    this.translate.get('En caso de que NO se muestre el marcador, verificar los filtros del mapa.').subscribe((res) => {
      var toastHTML = "<span style='font-size: 0.9em;'>"+res.toString()+"</span>";
      M.toast({ html: toastHTML, displayLength: 3000, classes: 'rounded' });
    });
    this.appMapComponent.lat = Number(la) + (0.0000000000100 * Math.random());
    this.appMapComponent.lng = Number(lo) + (0.0000000000100 * Math.random());
    this.appMapComponent.zoom = Number(16);
    const table = $('#table_search').DataTable();
    $('#buscador_canciones_').val(na);
    $('#modal_result_song_autocomplete').modal('close');
  }
}
