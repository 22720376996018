import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppMapComponent } from './app-map/app-map.component';
import { UserloggedComponent } from './profiles/user-logged/userlogged.component';
import { Site404Component } from './components/site404/site404.component';
import { ProfilemusicComponent } from './profiles/profile-music/profilemusic.component';
import { ProfileMusicMasterComponent } from './profiles/profile-music-master/profile-music-master.component';
import { UserComponent } from './profiles/user/user.component';

const appRoutes: Routes = [
  {
    path: '',
    component: AppMapComponent,
  },
  {
    path: 'user',
    component: UserloggedComponent,
  },
  {
    path: 'user/:type/:nick',
    component: UserComponent,
  },
  {
    path: 'place/:id',
    component: ProfilemusicComponent,
  },
  {
    path: 'master/:id',
    component: ProfileMusicMasterComponent,
  },
  {
    path: '**',
    component: Site404Component,
  },
];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(appRoutes);
