import { Injectable } from '@angular/core'; // decorador de angular
import { MusicService } from '../services/music.service';
import 'rxjs/Rx';

@Injectable()
export class MarkerService {
  constructor(private musicService: MusicService) {
    // do nothing.
  }

  obtenerMarcadores() {
    const markers = JSON.parse(localStorage.getItem('markers'));
    return markers;
  }

  recargarMarcadores() {
    this.init();
    const markers = JSON.parse(localStorage.getItem('markers'));
    return markers;
  }

  init() {
    this.musicService.getMusics().subscribe(async (music) => {
      await localStorage.setItem('markers', JSON.stringify(music));
    });
  }
}
