import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { concatMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { Music } from '../models/music';
import { VAR_CONFIG } from '../services/vars';

@Injectable({
  providedIn: 'root',
})
export class MusicService {
  selectedMusic: Music;

  musics: Music[];

  music: any;

  readonly URL_API = VAR_CONFIG.url_api_music;

  constructor(private http?: HttpClient) {
    this.selectedMusic = new Music();
  }

  setMusicSelected(m: any) {
    if (m !== undefined && m !== null) {
      if (JSON.parse(localStorage.getItem('musicMarker')) !== JSON.stringify(m)) {
        this.music = m;
        localStorage.setItem('musicMarker', JSON.stringify(m));
      }
    }
  }

  getMusics() {
    return this.http.get(this.URL_API);
  }

  getMusicsNotVisible() {
    return this.http.get(`${this.URL_API}/get/by/state/false`);
  }

  getLookMusics() {
    return this.http.get(`${this.URL_API}/looks`);
  }

  getHearMusics() {
    return this.http.get(`${this.URL_API}/hears`);
  }

  getByIdUser(id: string) {
    return this.http.post(`${this.URL_API}/get/by/iduser`, { id_user: id })
      .map((res) => res);
  }

  groupPlaces() {
    return this.http.get(`${this.URL_API}/group/place`);
  }

  groupMorePlaces() {
    return this.http.get(`${this.URL_API}/group/more/place`);
  }

  groupCountry() {
    return this.http.get(`${this.URL_API}/group/country`);
  }

  groupSongUsers() {
    return this.http.get(`${this.URL_API}/group/musicuser`);
  }

  groupNumFav() {
    return this.http.get(`${this.URL_API}/group/numfav`);
  }

  groupMusicFav() {
    return this.http.get(`${this.URL_API}/group/musicfav`);
  }

  groupArtists() {
    return this.http.get(`${this.URL_API}/group/artist`);
  }

  groupGenders() {
    return this.http.get(`${this.URL_API}/group/gender`);
  }

  getByNameArtistDistCoord(name: string, artist: string, lat: number, long: number) {
    return this.http.post(`${this.URL_API}/get/by/nameartist/noeqcoord`, {
      lat, long, name, artist,
    })
      .map((res) => res);
  }

  getAllByNameArtist(name: string, artist: string) {
    return this.http.post(`${this.URL_API}/get/by/all/nameartist`, {
      name, artist,
    })
      .map((res) => res);
  }

  getByLinkSpotify(link: string) {
    return this.http.post(`${this.URL_API}/get/by/linkspotify`, { link_spotify: link })
      .map((res) => res);
  }

  getByNamArtCoord(name: string, artist: string, lat: number, lng: number) {
    return this.http.post(`${this.URL_API}/get/by/nameartist/eqcoord`, {
      name, artist, lat, lng,
    })
      .map((res) => res);
  }

  getOneByLinkSpotify(link: string) {
    return this.http.post(`${this.URL_API}/get/by/one/linkspotify`, { link_spotify: link })
      .map((res) => res);
  }

  getMusicById(id: string) {
    return this.http.get(`${this.URL_API}/get/by/id/` + `${id}`)
      .map((res) => res);
  }

  getMusicByCountry(country: string) {
    return this.http.get(`${this.URL_API}/get/by/country/` + `${country}`)
      .map((res) => res);
  }

  postMusic(music: Music) {
    return this.http.post(this.URL_API, music);
  }

  putMusic(music: Music) {
    return this.http.put(`${this.URL_API}/${music._id}`, music);
  }

  getLyric() {
    return this.http.get(this.URL_API);
  }

  getNamArtCoord(places: any[]): any {
    return from(places).pipe(
      concatMap((place) => <Observable<any>> this.http.post(`${this.URL_API}/get/by/nameartist/coord`, { place })),
    );
  }
}
