import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-login-auth',
  templateUrl: './login-auth.component.html',
  styleUrls: ['./login-auth.component.css'],
})
export class LoginAuthComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  profile: any;

  constructor(private auth: AuthService) { }

  cerrarModal() {
    $('.modal').modal('close');
    $('#modal_login_auth').modal('close');
  }

  ngOnInit() {
    this.update();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  update() {
    this.subscription = this.auth.getVar().subscribe((message) => {
      if (message.var) {
        switch (message.type) {
          case 'success':
            this.profile = message.var;
            break;
          case 'error':
            break;
        }
      }
    });
  }
}
