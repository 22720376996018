import { Pipe, PipeTransform } from '@angular/core';
// import { DATA_COUNTRY } from '../../assets/country';
import { DATA_COUNTRY_V2 } from '../../assets/countriesV2';

@Pipe({
  name: 'fullnamecountry',
})
export class FullnamecountryPipe implements PipeTransform {
  transform(short_name: string, current_lang: string): any {
    let full_name = '';
    DATA_COUNTRY_V2.data.forEach((country) => {
      if (country.cca2 === short_name) {
        if (current_lang === 'es') {
          full_name = country.translations.spa.common;
          return full_name;
        } if (current_lang === 'en') {
          full_name = country.name.common;
          return full_name;
        } if (current_lang === 'pt') {
          full_name = country.translations.por.common;
          return full_name;
        }
        full_name = country.translations.spa.common;
        return full_name;
      }
    });
    return full_name;
  }
}
